define("accio-alert/pods/main/onboarding/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.modelFor('main.onboarding').objectAt(parseInt(params.index));
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model === undefined) {
        this.transitionTo('main.onboarding.index', 0);
      }
    },
    setupController: function setupController(_controller, _model) {
      this._super.apply(this, arguments);

      var allTasks = this.modelFor('main.onboarding');
      var params = this.paramsFor(this.routeName);
      this.controller.set('allTasks', allTasks);

      if (params.index !== undefined) {
        this.controller.set('currentIndex', parseInt(params.index));
      }
    }
  });

  _exports.default = _default;
});