define("accio-alert/pods/components/pdsp-campaign-selection-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['m-t-5', 'm-b-5'],
    tagName: 'li',
    isChecked: true,
    didInsertElement: function didInsertElement() {
      this.onChanged(this.get('model'), this.get('isChecked'));
    },
    actions: {
      changed: function changed() {
        this.toggleProperty('isChecked');
        this.onChanged(this.get('model'), this.get('isChecked'));
      }
    }
  });

  _exports.default = _default;
});