define("accio-alert/pods/components/onboarding/contact-method-add-secondary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentAccount: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    isEmailUsernameType: Ember.computed.equal('currentUser.user.usernameType', 'email'),
    isSmsUsernameType: Ember.computed.not('isEmailUsernameType'),
    contactMethodNotVerified: Ember.computed.equal('contactMethod.state', 'unverified'),
    contactMethod: Ember.computed('model', function () {
      return this.get('store').findRecord('contact-method', this.get('model.metadata.contact_method_id'));
    }),
    primaryContactVerifiedVerb: Ember.computed('currentUser.user.usernameType', function () {
      var usernameType = this.get('currentUser.user.usernameType');
      return usernameType === 'email' ? 'email' : 'SMS';
    }),
    actions: {
      reloadUser: function reloadUser() {
        this.get('currentUser').reload().then(this.actions.next.bind(this));
      },
      toggleSwitchToPhone: function toggleSwitchToPhone() {
        this.toggleProperty('switchToPhone');
      },
      onSwitchedToPhone: function onSwitchedToPhone() {
        this.toggleProperty('switchToPhone');
        this.get('model').reload();
        location.reload();
      },
      next: function next() {
        this.transitionToRoute('main.onboarding.step2');
      }
    }
  });

  _exports.default = _default;
});