define("accio-alert/models/notification-message", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    apiName: (0, _model.attr)(),
    description: (0, _model.attr)(),
    buttonClass: (0, _model.attr)(),
    delayMinutes: (0, _model.attr)(),
    enabledForMobileAlerting: (0, _model.attr)('boolean'),
    messageTemplate: (0, _model.belongsTo)('message-template'),
    notification: (0, _model.belongsTo)('notification'),
    fields: (0, _model.hasMany)('message-field'),
    matcher: Ember.computed('fields', function () {
      return this.get('fields').then(function (fields) {
        var filtered = fields.filter(function (f) {
          return f.fieldType === 'matcher';
        });
        return filtered.length >= 0 ? filtered[0] : null;
      });
    })
  });

  _exports.default = _default;
});