define("accio-alert/pods/main/subscriptions/form/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.computed(function () {
      return this.get('store').findAll('notification');
    }),
    actions: {
      setNotification: function setNotification(notification) {
        this.get('model').set('notification', notification);
      },
      save: function save() {
        var _this = this;

        this.get('model').save().then(function () {
          _this.transitionToRoute('main');
        });
      }
    }
  });

  _exports.default = _default;
});