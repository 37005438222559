define("accio-alert/pods/components/pdsp-my-locations/pdsp-location/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    canDelete: true,
    state: Ember.computed.oneWay('model.verification.state'),
    isRFI: Ember.computed.equal('state', 'requested_more_info'),
    canEdit: Ember.computed('state', function () {
      var state = this.get('state');
      return state === 'draft';
    }),
    formattedVerificationState: Ember.computed('state', function () {
      var state = this.get('state');
      return Ember.isEmpty(state) ? '' : this.get('state').replace('_', ' ');
    }),
    badgeClass: Ember.computed('state', function () {
      var state = this.get('state');

      switch (state) {
        case 'approved':
          return 'approved';

        case 'denied':
          return 'danger';

        default:
          return '';
      }
    }),
    canViewVerification: Ember.computed('state', function () {
      return this.get('state') !== 'draft';
    }),
    actions: {
      view: function view() {
        var model = this.get('model');

        if (this.get('canEdit')) {
          this.get('routing').transitionTo('main.vehicles.edit.home', model);
        } else if (this.get('canViewVerification')) {
          this.get('routing').transitionTo('main.verifications.show', model.verification);
        }
      },
      delete: function _delete() {
        var _this = this;

        if (!confirm("Are you sure you want to delete '" + this.get('model.nickname') + "'?")) {
          return;
        }

        this.get('model').destroyRecord().then(function () {
          _this.get('notifications').success('Place Successfully Deleted.');
        });
      }
    }
  });

  _exports.default = _default;
});