define("accio-alert/pods/main/onboarding/route", ["exports", "accio-alert/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    currentUser: Ember.inject.service(),
    model: function model() {
      return this.get('currentUser.user.onboardingTasks');
    }
  });

  _exports.default = _default;
});