define("accio-alert/pods/main/verifications/show/route", ["exports", "accio-alert/mixins/track-verification-events", "accio-alert/mixins/reset-scroll"], function (_exports, _trackVerificationEvents, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, _trackVerificationEvents.default, {
    model: function model(params) {
      return this.store.findRecord('verification', params.verification_id);
    }
  });

  _exports.default = _default;
});