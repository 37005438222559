define("accio-alert/pods/components/pdsp-comment-file-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    classNames: ['attachment-image'],
    classNameBindings: ['isValid::not-valid'],
    required: false,
    uploading: false,
    notRequired: Ember.computed.equal('required', false),
    fileLocationNotEmpty: Ember.computed.notEmpty('comment.fileLocation'),
    requiredAndFilled: Ember.computed.and('required', 'fileLocationNotEmpty'),
    isValid: Ember.computed.or('notRequired', 'requiredAndFilled'),
    dropZoneName: Ember.computed('comment', function () {
      return "dropzone-comment-" + this.get('comment.id');
    }),
    buttonCaption: Ember.computed(function () {
      if (this.nativeApp.isNativeApp) {
        return 'Take Picture';
      } else {
        return 'Upload an Image';
      }
    }),
    actions: {
      uploadImage: function uploadImage(queue, file) {
        var _this = this;

        // let headers = this.get('ajax.headers');
        var comment = this.get('comment'); // let url = `${config.apiURL}/${config.apiNamespace}/verification-comments/${this.get('comment.id')}/upload`;

        this.set('uploading', true);
        file.readAsDataURL().then(function (data) {
          comment.setProperties({
            uploadedFile: data,
            uploadedFileName: file.blob.name,
            uploadedFileType: file.blob.type
          });
          return comment.save();
        }).then(function (c) {
          c.clearUploadedFile();

          _this.set('uploading', false);
        }).catch(function (e) {
          _this.set('uploading', false);

          console.error(e);
        });
      },
      clearPhoto: function clearPhoto() {
        this.set('comment.fileLocation', null);
        this.get('comment').save();
      }
    }
  });

  _exports.default = _default;
});