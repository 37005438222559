define("accio-alert/models/notification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    apiName: (0, _model.attr)(),
    description: (0, _model.attr)(),
    delayMinutes: (0, _model.attr)(),
    campaign: (0, _model.belongsTo)('campaign'),
    messages: (0, _model.hasMany)('notification-message')
  });

  _exports.default = _default;
});