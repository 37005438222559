define("accio-alert/services/native-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isNativeApp: window.hasOwnProperty('ReactNativeWebView'),
    postMessage: function postMessage(message) {
      if (!this.isNativeApp) {
        return;
      }

      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    },
    transitionToNativeRoute: function transitionToNativeRoute(route) {
      this.postMessage({
        action: 'navigate',
        route: route
      });
    },
    popNavigation: function popNavigation() {
      this.postMessage({
        action: 'navigation.pop'
      });
    }
  });

  _exports.default = _default;
});