define("accio-alert/pods/components/pdsp-my-alerts/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['card-box', 'my-alerts'],
    alerts: Ember.computed(function () {
      return this.get('store').query('message', {
        isArchived: false
      });
    })
  });

  _exports.default = _default;
});