define("accio-alert/mixins/data-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    resetController: function resetController() {
      var model = this.get('controller.model');

      if (!model.get('isDeleted')) {
        model.rollbackAttributes();
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.get('controller.model');

        if (model.get('hasDirtyAttributes') && !this.willTransitionConfirm(transition)) {
          transition.abort();
        }
      }
    },
    willTransitionConfirm: function willTransitionConfirm() {
      return true;
    }
  });

  _exports.default = _default;
});