define("accio-alert/pods/components/pdsp-contact-method-flag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wyNdrEG5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showFlag\"]]],null,{\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/images/red_flag.svg\"],[10,\"style\",\"width: 35px\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-contact-method-flag/template.hbs"
    }
  });

  _exports.default = _default;
});