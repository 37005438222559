define("accio-alert/models/issue-queue", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    issues: (0, _model.hasMany)('issue')
  });

  _exports.default = _default;
});