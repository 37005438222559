define("accio-alert/models/scheduled-alert", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    campaign: (0, _emberCpValidations.validator)('presence', true),
    notification: (0, _emberCpValidations.validator)('presence', true),
    notificationMessage: (0, _emberCpValidations.validator)('presence', true),
    scheduledAt: (0, _emberCpValidations.validator)('presence', true),
    matcherConfig: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    scheduledAt: (0, _model.attr)('date'),
    matcherConfig: (0, _model.attr)(),
    variables: (0, _model.attr)(),
    deliveryStats: (0, _model.attr)(),
    status: (0, _model.attr)('string'),
    insertedAt: (0, _model.attr)('date'),
    campaign: (0, _model.belongsTo)('campaign'),
    notification: (0, _model.belongsTo)('notification'),
    notificationMessage: (0, _model.belongsTo)('notificationMessage'),
    createdBy: (0, _model.belongsTo)('user')
  });

  _exports.default = _default;
});