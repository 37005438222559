define("accio-alert/services/user-idle", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var timeTilIdleLogoutInMilleseconds = 30 * 60 * 1000; // 30 minutes

  var notificationToLogoutDurationInMilleseconds = 2 * 60 * 1000; // 2 minutes

  var timeTilNotificationInMilleseconds = timeTilIdleLogoutInMilleseconds - notificationToLogoutDurationInMilleseconds;

  var _default = _userIdle.default.extend({
    LOGOUT_TIMEOUT: notificationToLogoutDurationInMilleseconds,
    IDLE_TIMEOUT: timeTilNotificationInMilleseconds
  });

  _exports.default = _default;
});