define("accio-alert/pods/enforcement/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.query('notification', {
        enabled_for_mobile_alerting: true
      });
    }
  });

  _exports.default = _default;
});