define("accio-alert/pods/components/pdsp-service-annotations/date-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HsDroD5b",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"startOnly\"]]],null,{\"statements\":[[1,[28,\"moment-format\",[[24,[\"model\",\"startAt\"]],\"ll\"],null],false],[0,\" onwards\\n\\n\"],[7,\"p\",true],[10,\"class\",\"text-danger\"],[8],[0,\"No End Date\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"endOnly\"]]],null,{\"statements\":[[0,\"until \"],[1,[28,\"moment-format\",[[24,[\"model\",\"endAt\"]],\"ll\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"bothExist\"]]],null,{\"statements\":[[1,[28,\"moment-format\",[[24,[\"model\",\"startAt\"]],\"ll\"],null],false],[0,\"\\n to\\n\"],[1,[28,\"moment-format\",[[24,[\"model\",\"endAt\"]],\"ll\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"neitherExist\"]]],null,{\"statements\":[[0,\"No Dates Specified\\n\"],[7,\"p\",true],[10,\"class\",\"text-danger\"],[8],[0,\"No Start or End Date\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-service-annotations/date-display/template.hbs"
    }
  });

  _exports.default = _default;
});