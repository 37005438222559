define("accio-alert/pods/components/pdsp-campaign-selection-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5OyoQSA9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"style\",\"font-size: 120%\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"disabled\",\"checked\"],[\"checkbox\",true,[24,[\"isChecked\"]]]]],false],[0,\" \\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"text-muted\"],[8],[1,[24,[\"model\",\"name\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"style\",\"font-size: 120%\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"\\n\\t\\t\"],[7,\"input\",true],[11,\"checked\",[22,\"isChecked\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"changed\"],null]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\\t\\t\\t \\n\\t\\t\\t\"],[1,[24,[\"model\",\"name\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-campaign-selection-item/template.hbs"
    }
  });

  _exports.default = _default;
});