define("accio-alert/components/pdsp-select-features-map", ["exports", "common/components/pdsp-select-features-map"], function (_exports, _pdspSelectFeaturesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pdspSelectFeaturesMap.default;
    }
  });
});