define("accio-alert/pods/components/pdsp-service-annotations/actions-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3wXFKZ6V",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"model\",\"features\",\"length\"]],false],[0,\" block(s)\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-service-annotations/actions-display/template.hbs"
    }
  });

  _exports.default = _default;
});