define("accio-alert/models/campaign", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    apiName: (0, _model.attr)(),
    statusNotice: (0, _model.attr)(),
    requiredElements: (0, _model.attr)(),
    autoSubscribe: (0, _model.attr)(),
    metadata: (0, _model.attr)('raw'),
    notifications: (0, _model.hasMany)('notification'),
    frequentlyAskedQuestions: (0, _model.hasMany)('frequently-asked-question'),
    scheduledAlerts: (0, _model.hasMany)('scheduled-alert'),
    seasons: (0, _model.hasMany)('season'),
    activeSeason: (0, _model.belongsTo)('season'),
    futureAlerts: Ember.computed('scheduledAlerts.[]', function () {
      var now = new Date();
      return this.scheduledAlerts.filter(function (sa) {
        return sa.scheduledAt >= now;
      });
    }),
    pastAlerts: Ember.computed('scheduledAlerts.[]', function () {
      var now = new Date();
      return this.scheduledAlerts.filter(function (sa) {
        return sa.scheduledAt < now;
      });
    }),
    isLocationCampaign: Ember.computed('requiredElements', function () {
      var elems = this.get('requiredElements');
      return elems === undefined ? false : elems.indexOf('location') > -1;
    }),
    isVehicleCampaign: Ember.computed('requiredElements', function () {
      var elems = this.get('requiredElements');
      return elems === undefined ? false : elems.indexOf('vehicle') > -1;
    }),
    allowsFeatureSelection: Ember.computed('metadata', function () {
      var metadata = this.get('metadata');
      return metadata && metadata.user_feature_selection && metadata.user_feature_selection.enabled;
    })
  });

  _exports.default = _default;
});