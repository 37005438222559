define("accio-alert/router", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('logout');
    this.route('home');
    this.route('token_auth'); // /m/s tinyURL versions of mobile schedules

    this.mount('m');
    this.route('mobile', function () {
      this.route('messages', {
        path: 'messages/:message_id'
      });
      this.route('notification_messages', {
        path: 'nm/:notification_message_id'
      });
      this.route('subscriptions');
      this.route('subscription', {
        path: 'subscription/:subscription_id'
      });
      this.route('contact_methods', {
        path: 'contact-methods'
      });
      this.route('locations');
      this.route('location_new');
      this.route('select_features', {
        path: 'select-features/:subscription_location_id'
      });
      this.route('pipeline');
      this.route('profile');
      this.route('patient');
      this.route('schedules');
      this.route('tasks');
      this.route('update_password');
      this.route('conversation.show', {
        path: 'c/:conversation_id'
      });
      this.route('issues', function () {
        this.route('show', {
          path: ':issue_id'
        });
      });
      this.route('faq', function () {
        this.route('index', {
          path: ''
        });
        this.route('campaign', {
          path: ':campaign_id'
        });
      });
      this.route('vehicle-edit', {
        path: 'vehicle-edit/:vehicle_id'
      });
      this.route('vehicle-verify', {
        path: 'vehicle-verify/:vehicle_id'
      });
      this.route('vehicle-new');
    });
    this.route('public', {
      path: '/'
    }, function () {
      this.route('portal');
      this.route('login');
      this.route('front.smchd');
      this.route('no_login');
      this.route('link_expired');
      this.route('register');
      this.route('verify');
      this.route('patient_lookup', {
        path: 'patient-lookup'
      });
      this.route('code', function () {
        this.route('qr', {
          path: 'qr/:code'
        });
      });
      this.route('widgets', {
        path: 'w'
      }, function () {
        this.route('leaf');
      });
      this.route('digests.show', {
        path: 'digests/:digest_id'
      });
      this.route('faq', function () {
        this.route('index', {
          path: ''
        });
        this.route('campaign', {
          path: ':campaign_id'
        });
      });
      this.route('invitation', {
        path: 'invitation/:reset_token'
      });
      this.route('reset_password', function () {
        this.route('step1');
        this.route('step2', {
          path: 'step2/:reset_token'
        });
      });
      this.route('workflows', {
        path: 'wf'
      }, function () {
        this.route('initialize', {
          path: 'i/:api_name'
        });
      });
      this.route('come_back_later');
    });
    this.mount('admin');
    this.route('enforcement', function () {
      this.route('message', {
        path: ':topic/:message_type'
      });
    });
    this.route('main', function () {
      this.route('workflows', {
        path: 'wf'
      }, function () {
        this.route('initialize', {
          path: 'i/:api_name'
        });
        this.route('jump', {
          path: 'j/:workflow_id/:api_name'
        });
        this.route('execute', {
          path: 'e/:workflow_id'
        });
      });
      this.route('onboarding', function () {
        this.route('first');
        this.route('index', {
          path: ':index'
        });
        this.route('step1');
        this.route('step2');
        this.route('step3');
        this.route('step4_vehicle');
        this.route('step4_location');
      });
      this.route('update_password');
      this.route('update_user_info');
      this.route('switch_registration_to_phone');
      this.route('verifications', function () {
        this.route('show', {
          path: ':verification_id'
        });
      });
      this.route('subscriptions', function () {
        this.route('new');
        this.route('location', {
          path: 'location/:subscription_location_id'
        });
        this.route('edit', {
          path: ':subscription_id'
        });
      });
      this.route('locations', function () {
        this.route('new', function () {
          this.route('home');
          this.route('intro');
        });
        this.route('edit', {
          path: ':location_id/edit'
        }, function () {
          this.route('home');
          this.route('select_programs', {
            path: 'select-programs'
          });
          this.route('select_features', {
            path: 'select-features/:subscription_location_id'
          });
          this.route('show_schedule', {
            path: 'show-schedule'
          });
          this.route('finish');
        });
      });
      this.route('vehicles', function () {
        this.route('new', function () {
          this.route('onboarding');
          this.route('home');
          this.route('intro');
        });
        this.route('edit', {
          path: ':vehicle_id/edit'
        }, function () {
          this.route('home');
          this.route('select_programs', {
            path: 'select-programs'
          });
          this.route('verify');
          this.route('finish');
        });
      });
      this.route('contact_methods', function () {
        this.route('new');
        this.route('edit', {
          path: ':contact_method_id'
        });
      });
      this.route('patient');
      this.route('anonymous');
      this.route('issues', function () {
        this.route('show', {
          path: ':issue_id'
        });
      });
      this.route('not_found');
    });
  });
  var _default = Router;
  _exports.default = _default;
});