define("accio-alert/pods/mobile/conversation/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('conversation', params.conversation_id);
    }
  });

  _exports.default = _default;
});