define("accio-alert/pods/main/vehicles/new/onboarding/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FeSka/bZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"log\",[[24,[\"nativeApp\",\"isNativeApp\"]]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row m-b-20\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-4 col-xs-2\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-cars\"],[10,\"style\",\"font-size: 24pt\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-xs-10\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"m-t-0 m-b-0\"],[8],[7,\"strong\",true],[10,\"class\",\"text-custom\"],[8],[0,\"Personalize\"],[9],[0,\" for your vehicle\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[10,\"class\",\"text-center m-t-10\"],[10,\"style\",\"font-size: 110%\"],[8],[0,\"\\n    \"],[1,[24,[\"currentAccount\",\"account\",\"accountDisplayName\"]],false],[0,\" requires that you upload a copy of your vehicle registration. If you don’t have it with you, click SKIP below to add your vehicle later.\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"hr\",true],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\",\"onboarded\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"cancel\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"skip\"]],[8],[0,\"skip\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      or\\n      \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default btn-md\",\"main.vehicles.new.home\"]],{\"statements\":[[0,\"Continue\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/vehicles/new/onboarding/template.hbs"
    }
  });

  _exports.default = _default;
});