define("accio-alert/pods/components/pdsp-verify-contact-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MLlmpct9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"contactMethod\",\"verified\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"text-success\"],[8],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-tick\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n\\t\"],[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"type\",\"pattern\",\"class\"],[[24,[\"verificationCode\"]],\"Enter Code\",\"number\",\"\\\\d*\",\"form-control input-lg\"]]],false],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-lg\"],[12,\"id\",\"Verify\"],[3,\"action\",[[23,0,[]],\"verify\"]],[8],[0,\"Verify\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showResendCodeLink\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"m-l-5\"],[3,\"action\",[[23,0,[]],\"resendCode\"]],[8],[0,\"Resend Code\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[7,\"div\",true],[8],[1,[22,\"verificationMessage\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-verify-contact-method/template.hbs"
    }
  });

  _exports.default = _default;
});