define("accio-alert/pods/components/pdsp-chart-onboarding-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['widget', 'row'],
    updateRows: function updateRows() {// const metricID = this.get('widget.config.metricID');
      // const chart = this.get('_chart');
      //
      // this.get('store').findRecord('metric', metricID)
      //   .then((metric) => {
      //     return metric.get('measurements');
      //   }).then((measurements) => {
      //
      //     const rows = [
      //       ['dates', 'predicted (cumulative)', 'actual (cumulative)']
      //     ];
      //
      //     let predictedTotal = 0;
      //     let actualTotal = 0;
      //     measurements.sortBy('incrementDate').forEach((m) => {
      //       predictedTotal += m.get('predictedValue') || 0;
      //       actualTotal += m.get('actualValue') || 0;
      //
      //       let arr = [m.get('incrementDate'), predictedTotal, actualTotal];
      //       rows.push(arr);
      //     });
      //
      //     chart.load({
      //       rows: rows
      //     });
      // });
    },
    didRender: function didRender() {
      var chart;

      if (!Ember.isEmpty(this.get('_chart'))) {
        chart = this.get('_chart');
      } else {
        chart = c3.generate({
          bindto: this.element,
          data: {
            columns: [['data1', 30], ['data2', 50], ['data3', 80]],
            types: {
              data1: 'bar',
              data2: 'bar',
              data3: 'bar'
            },
            colors: {
              data1: '#dbdfe2',
              data2: '#34d3eb',
              data3: '#7e57c2'
            },
            names: {
              data1: 'New Account Created',
              data2: 'Step 1 Complete',
              data3: 'Onboarding Complete'
            }
          },
          axis: {
            rotated: true,
            x: {
              type: 'categorized'
            }
          },
          tooltip: {
            format: {
              title: function title(d) {
                var format = d3.time.format('%a, %b %e');
                return format(d);
              },
              value: function value(_value) {
                var format = d3.format('$,');
                return format(_value);
              }
            }
          }
        });
      }

      this.set('_chart', chart); // run.scheduleOnce('afterRender', this, this.updateRows);
    }
  });

  _exports.default = _default;
});