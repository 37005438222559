define("accio-alert/helpers/date-format", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormat = dateFormat;
  _exports.default = void 0;

  /**
    Return the formatted date string in the given format.
    @method dateFormat
    @static
    @for date-fns/format
    @param {Date|String|Number} date the original date
    @param {String} format the string of tokens
    @return {String} the formatted date string
    @public
  */
  function dateFormat(_ref) {
    var date = _ref[0],
        format = _ref[1];
    return (0, _dateFns.format)(new Date(date), format);
  }

  var _default = Ember.Helper.helper(dateFormat);

  _exports.default = _default;
});