define("accio-alert/app", ["exports", "accio-alert/resolver", "ember-load-initializers", "accio-alert/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      admin: {
        dependencies: {
          externalRoutes: {
            login: 'public.login',
            logout: 'logout',
            mainWorkflowsExecute: 'main.workflows.execute',
            mainUpdatePassword: 'main.update_password',
            publicFAQ: 'public.faq'
          },
          services: ['ajax', 'assetMap', 'cookies', 'currentAccount', 'currentUser', 'issue', 'notifications', 'realtime', 'session', 'store', 'datadog']
        }
      },
      m: {
        dependencies: {
          externalRoutes: {
            mainWorkflowsInitialize: 'main.workflows.initialize',
            mobileIssuesShow: 'mobile.issues.show',
            mobileSchedules: 'mobile.schedules'
          },
          services: ['ajax', 'assetMap', 'currentAccount', 'currentUser', 'notifications', 'realtime', 'session', 'store', 'datadog']
        }
      }
    }
  }); // if (
  //   // config.environment !== 'development' &&
  //   config.environment !== 'mirage' &&
  //   config.environment !== 'test' &&
  //   config.APP.isProductionLikeBuildOverride !== false
  // ) {
  // }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});