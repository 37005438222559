define("accio-alert/pods/components/pdsp-holiday-greenery/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: ['card-box', 'my-schedule'],
    alerts: Ember.computed(function () {
      return this.get('store').query('message', {
        isArchived: false
      });
    }),
    locations: Ember.computed(function () {
      return this.get('store').query('location', {
        filters: {
          campaign_id: this.campaignId
        }
      });
    }),
    isPhase1: Ember.computed(function () {
      return new Date() < moment('2020-01-11').toDate();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (Ember.isEmpty(this.get('selectedLocation'))) {
        this.get('locations').then(function (locations) {
          return Ember.run.once(_this, _this.actions.updateSelectedLocation, locations.get('firstObject'));
        });
      }
    },
    actions: {
      updateSelectedLocation: function updateSelectedLocation(newLocation) {
        this.set('selectedLocation', newLocation);

        if (newLocation) {
          Ember.run.once(this, this._setScheduledEvents);
        }
      }
    },
    _setScheduledEvents: function _setScheduledEvents() {
      var _this2 = this;

      var selectedLocation = this.get('selectedLocation');

      if (Ember.isEmpty(selectedLocation)) {
        return;
      }

      var url = this.get('ajax.namespacedUrl') + "/locations/" + selectedLocation.get('id') + "/service-schedules";
      this.get('ajax').request(url, {
        data: {
          start_date: moment('2019-11-01T00:00:00Z').toISOString(),
          end_date: moment('2020-01-31T00:00:00Z').toISOString(),
          campaign_id: this.get('campaignId')
        }
      }).then(function (data) {
        return _this2.set('scheduledEvents', data);
      });
    }
  });

  _exports.default = _default;
});