define("accio-alert/pods/public/invitation/controller", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var resetToken = this.get('model');
        var newPassword = this.get('newPassword');
        var confirmPassword = this.get('confirmPassword');

        if (newPassword !== confirmPassword) {
          this.set('errorMessage', 'Please make sure that your password and confirmation password match.');
          return;
        }

        var url = _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/users/reset-password";
        return this.get('ajax').post(url, {
          contentType: 'application/json',
          data: {
            resetToken: resetToken,
            password: newPassword,
            confirmPassword: confirmPassword
          }
        }).then(function (data) {
          _this.get('notifications').success('Success! We have automatically logged you in.');

          _this.get('session').authenticate('authenticator:jwt', {
            username: data.identification,
            password: newPassword,
            secondFactor: data.secondFactor
          });
        });
      }
    }
  });

  _exports.default = _default;
});