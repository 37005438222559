define("accio-alert/pods/enforcement/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1zIrRZUI",
    "block": "{\"symbols\":[\"notification\",\"message\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-6 col-lg-offset-3 col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1 text-center\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"ul\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h4\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"ul\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"messages\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[1,[28,\"pdsp-enforcement-button\",null,[[\"model\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/enforcement/index/template.hbs"
    }
  });

  _exports.default = _default;
});