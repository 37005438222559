define("accio-alert/pods/components/pdsp-my-subscriptions/subscription-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'subscription-row'],
    actions: {
      delete: function _delete() {
        this.onDelete();
      }
    }
  });

  _exports.default = _default;
});