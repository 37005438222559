define("accio-alert/pods/components/pdsp-public-discrete-schedule/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: ['card-box', 'my-schedule'],
    alerts: Ember.computed(function () {
      return this.get('store').query('message', {
        isArchived: false
      });
    }),
    actions: {
      resetAddress: function resetAddress() {
        this.get('model').setProperties({
          street: null,
          formattedAddress: null,
          city: null,
          province: null,
          postalCode: null
        });
      },
      done: function done() {
        return false;
      },
      placeChanged: function placeChanged(address) {
        var location = address.geometry.location;
        var streetNumber = this.getShortNameForType(address, 'street_number');
        var street = this.getShortNameForType(address, 'route');
        this.set('selectedCoordinates', {
          latitude: location.lat(),
          longitude: location.lng(),
          street: streetNumber + " " + street
        });
        Ember.run.once(this, this._setScheduledEvents);
      }
    },
    _setScheduledEvents: function _setScheduledEvents() {
      var _this = this;

      var selectedCoordinates = this.get('selectedCoordinates');

      if (Ember.isEmpty(selectedCoordinates)) {
        return;
      }

      var url = this.get('ajax.namespacedUrl') + "/locations/by_coord/" + selectedCoordinates.longitude + "," + selectedCoordinates.latitude + "/service-schedules";
      this.get('ajax').request(url, {
        data: {
          start_date: moment('2022-11-01T00:00:00Z').toISOString(),
          end_date: moment('2023-01-31T00:00:00Z').toISOString(),
          campaign_id: this.get('campaignId')
        }
      }).then(function (data) {
        return _this.set('scheduledEvents', data);
      });
    },
    getShortNameForType: function getShortNameForType(address, type) {
      return address.address_components.filter(function (item) {
        return item.types.indexOf(type) > -1;
      })[0].short_name;
    }
  });

  _exports.default = _default;
});