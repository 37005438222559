define("accio-alert/pods/components/pdsp-my-contact-methods/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ddLGbAu1",
    "block": "{\"symbols\":[\"contactMethod\"],\"statements\":[[7,\"h3\",true],[10,\"class\",\"header-title m-t-0 m-b-5\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"nativeApp\",\"isNativeApp\"]]],null,{\"statements\":[[0,\"\\t\\tWays to Contact Me\\n\\n\"],[4,\"unless\",[[24,[\"hasReachedContactMethodLimit\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default btn-sm pull-right\",\"main.contact_methods.new\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-plus-sign\"],[8],[9],[0,\" Add\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\\n\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"You may add up to \"],[1,[22,\"maxEmailsPerCustomer\"],false],[0,\" email addresses and \"],[1,[22,\"maxSmsPerCustomer\"],false],[0,\" phone numbers.  You must verify an email or phone number before receiving alerts. To permanently delete a contact method, click the X.\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[7,\"strong\",true],[10,\"class\",\"text-danger\"],[8],[0,\"SMS messages will be sent to you from \"],[1,[24,[\"currentUser\",\"user\",\"smsDeliveryPhoneNumber\"]],false],[9],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"pdsp-my-contact-methods/pdsp-contact-method\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\\t\"],[7,\"hr\",true],[10,\"class\",\"m-t-10 m-b-10\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasReachedContactMethodLimit\"]]],null,{\"statements\":[[7,\"p\",true],[10,\"class\",\"text-muted\"],[8],[7,\"em\",true],[8],[0,\"Note: You've added the maximum number of contact methods. (\"],[1,[22,\"maxEmailsPerCustomer\"],false],[0,\" emails and \"],[1,[22,\"maxSmsPerCustomer\"],false],[0,\" SMS) and can't add any more.\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-contact-methods/template.hbs"
    }
  });

  _exports.default = _default;
});