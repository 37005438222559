define("accio-alert/models/message", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    subject: (0, _model.attr)(),
    plaintextBody: (0, _model.attr)(),
    htmlBody: (0, _model.attr)(),
    sendAfter: (0, _model.attr)('date'),
    isArchived: (0, _model.attr)()
  });

  _exports.default = _default;
});