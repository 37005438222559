define("accio-alert/pods/main/vehicles/edit/verify/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yihiG++R",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[8],[0,\"Provide Supporting Documents\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"Upload the front and back of your Vehicle Registration as proof you are the rightful owner of this vehicle. If the back is blank, upload the front twice.\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"id\",\"camera-permission-message\"],[8],[0,\"\\n\\t\\tYou don't have camera permissions so you can't upload photos.\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row m-t-20\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"orderedComments\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-6\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"pdsp-comment-file-upload\",null,[[\"comment\"],[[23,1,[]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12 col-xs-12 text-center\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"log\",[[24,[\"nativeApp\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"cancel\"],[9],[0,\"\\n\\t\\t\\tor\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-md\"],[12,\"disabled\",[22,\"registrationImagesNotUploaded\"]],[3,\"action\",[[23,0,[]],\"submit\"]],[8],[0,\"Continue\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/vehicles/edit/verify/template.hbs"
    }
  });

  _exports.default = _default;
});