define("accio-alert/pods/application/adapter", ["exports", "@ember-data/adapter/json-api", "accio-alert/config/environment", "ember-simple-auth-token/mixins/token-authorizer"], function (_exports, _jsonApi, _environment, _tokenAuthorizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_tokenAuthorizer.default, {
    session: Ember.inject.service(),
    host: _environment.default.apiURL,
    namespace: Ember.computed('session.isAuthenticated', function () {
      var roles = [];

      if (this.get('session.isAuthenticated') === true) {
        roles = this.get('session.data.authenticated.user.roles');
      } // if (this.get('currentUser.user')) {
      // 	roles = this.get('currentUser.user.roles');
      // }


      return roles.includes('public_user') || roles.includes('patient') || roles.includes('anonymous') || roles.includes('no_login') ? _environment.default.apiNamespace : _environment.default.adminApiNamespace;
    })
  });

  _exports.default = _default;
});