define("accio-alert/pods/components/pdsp-subscription-locations/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { A } from '@ember/array';
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'ul',
    classNames: 'list-unstyled',
    locations: Ember.computed(function () {
      return this.get('store').findAll('location');
    }),
    actions: {}
  });

  _exports.default = _default;
});