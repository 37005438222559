define("accio-alert/pods/mobile/issues/show/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    canComment: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.deliveryStatuses = ['Result Delivered', 'Attempted Delivery by Phone', 'Unable to Deliver: No Phone Number to Call', 'Unable to Deliver: Wrong Number', 'Unable to Deliver: Bad Number', 'Unable to Deliver: Could not Reach via Phone'];
      this.deliverySuccessStatuses = ['Result Delivered'];
      this.resultOptions = ['Positive', 'Negative'];
      this.showDeliveryOptions = false;
    },
    actions: {
      updateDeliveryStatus: function updateDeliveryStatus(newStatus) {
        this.set('model.metadata.delivery_status', newStatus);

        if (this.deliverySuccessStatuses.includes(newStatus)) {
          this.set('showDeliveryOptions', true);
          this.set('model.metadata.date_result_delivered_by_phone', moment().format('YYYY-MM-DD'));
          this.set('model.metadata.delivery_method', 'PHONE');
        } else {
          this.set('showDeliveryOptions', false);
          this.set('model.metadata.delivery_method', undefined);
          this.set('model.metadata.date_result_delivered_by_phone', undefined);
          this.set('model.metadata.result_delivered', undefined);
        }
      },
      updateDeliveryDate: function updateDeliveryDate(date) {
        this.set('model.metadata.date_result_delivered_by_phone', moment(date).format('YYYY-MM-DD'));
      },
      updateResult: function updateResult(result) {
        this.set('model.metadata.result_delivered', result);
      },
      cancel: function cancel() {
        this.model.rollback();
        this.transitionToRoute('admin.issues');
      },
      save: function save() {
        var _this = this;

        if (!confirm("Are you sure you want to change the delivery status to " + this.model.metadata.delivery_status + "?")) {
          return;
        }

        if (this.get('model.assignee') === undefined) {
          this.set('model.assignee', this.get('currentUser.user'));
        }

        this.model.save().then(function () {
          _this.get('notifications').success('Updated Delivery Status');

          _this.router.transitionTo('admin.issues');
        });
      }
    }
  });

  _exports.default = _default;
});