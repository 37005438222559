define("accio-alert/pods/main/update-user-info/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true) // businessName: validator('presence', {
    // 	presence: true,
    // 	disabled: equal('model.isBusinessUser', false)
    // })

  });

  var _default = Ember.Controller.extend(Validations, {
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    didValidate: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.salutations = ['Dr.', 'Mr.', 'Mrs.', 'Ms.'];
    },
    isEmailUsernameType: Ember.computed.equal('model.usernameType', 'email'),
    salutation: Ember.computed.alias('model.salutation'),
    firstName: Ember.computed.alias('model.firstName'),
    lastName: Ember.computed.alias('model.lastName'),
    businessName: Ember.computed.alias('model.businessName'),
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          return validations.get('isValid') ? Ember.RSVP.resolve() : Ember.RSVP.reject();
        }).then(function () {
          return model.save();
        }).then(function () {
          _this.get('notifications').success('Updated profile information.');

          if (_this.get('nativeApp.isNativeApp')) {
            _this.transitionToRoute('mobile.profile');
          } else {
            _this.transitionToRoute('main.index');
          }
        });
      }
    }
  });

  _exports.default = _default;
});