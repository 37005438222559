define("accio-alert/pods/public/reset-password/step2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B8B9iqXO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"Please enter your new password.\"],[9],[0,\"\\n\"],[7,\"form\",false],[12,\"class\",\"form-horizontal m-t-20\"],[3,\"action\",[[23,0,[]],\"resetPassword\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\t\"],[1,[28,\"pdsp-validated-input\",null,[[\"type\",\"model\",\"valuePath\",\"placeholder\",\"autocomplete\",\"didValidate\"],[\"password\",[23,0,[]],\"password\",\"Password\",\"new-password\",[24,[\"didValidate\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"pdsp-validated-input\",null,[[\"type\",\"model\",\"valuePath\",\"placeholder\",\"autocomplete\",\"didValidate\"],[\"password\",[23,0,[]],\"confirmPassword\",\"Confirm Password\",\"new-password\",[24,[\"didValidate\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\",true],[10,\"class\",\"text-danger\"],[8],[1,[22,\"errorMessage\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group text-center m-t-20\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-info btn-block text-uppercase\"],[10,\"type\",\"submit\"],[8],[0,\"Reset Password\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/reset-password/step2/template.hbs"
    }
  });

  _exports.default = _default;
});