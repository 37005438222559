define("accio-alert/components/pdsp-health/my-tests", ["exports", "common/components/pdsp-health/my-tests"], function (_exports, _myTests) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _myTests.default;
    }
  });
});