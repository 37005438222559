define("accio-alert/pods/public/come-back-later/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M4PW0SUA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center m-t-20\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"We've sent you an email with instructions for when you return. See you soon!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/come-back-later/template.hbs"
    }
  });

  _exports.default = _default;
});