define("accio-alert/pods/main/contact-methods/edit/route", ["exports", "accio-alert/mixins/data-route"], function (_exports, _dataRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataRoute.default, {
    templateName: 'main/contact-methods/form',
    controllerName: 'main/contact-methods/form',
    model: function model(params) {
      return this.store.findRecord('contact-method', params.contact_method_id);
    },
    willTransitionConfirm: function willTransitionConfirm() {
      return window.confirm('You have unsaved changes that will be lost. Do you want to continue?');
    }
  });

  _exports.default = _default;
});