define("accio-alert/pods/components/pdsp-tasks/location-select-features/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iBzRpuxm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[0,\"Review/Confirm street blocks for street sweeping\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Make sure to review the streets to see if you'd like to add any additional blocks\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-4\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\",\"models\"],[\"btn btn-danger\",\"main.locations.edit.select_features\",[28,\"array\",[[24,[\"model\",\"metadata\",\"location_id\"]],[24,[\"model\",\"metadata\",\"subscription_location_id\"]]],null]]],{\"statements\":[[0,\"Select Blocks >\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-tasks/location-select-features/template.hbs"
    }
  });

  _exports.default = _default;
});