define("accio-alert/pods/components/onboarding/complete-account-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/H36tHUn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[8],[7,\"strong\",true],[10,\"class\",\"text-custom\"],[8],[0,\"Account Information Incomplete\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"padding-bottom: 100px\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-10 col-lg-offset-1\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text-center\"],[10,\"style\",\"margin-top: 40px\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h4\",true],[8],[0,\"We are missing some patient information from your account - please follow the link below to complete your account information and continue to login.\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text-center\"],[10,\"style\",\"margin-top: 40px\"],[8],[0,\"\\n\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-lg btn-default\",\"main.workflows.initialize\",\"edit_user\"]],{\"statements\":[[0,\"Complete Information\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/onboarding/complete-account-information/template.hbs"
    }
  });

  _exports.default = _default;
});