define("accio-alert/pods/components/pdsp-unified-schedule/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // if location is null, set it to the first element in the list


      if (this.location === undefined) {
        this.set('location', this.get('locations.firstObject'));
      } // check if the selected location is still part of the List
      // if not, reset to first location

    }
  });

  _exports.default = _default;
});