define("accio-alert/services/socket-guru", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    socketClient: 'phoenix',
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    autoConnect: false,
    config: Ember.computed('session.isAuthenticated', function () {
      var guardian_token = this.get('ajax.headers').Authorization.replace('Bearer ', '');
      var result = {
        connectParams: {
          guardian_token: guardian_token
        },
        socketAddress: _environment.default.socketURL
      };
      return result;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.globalTopics = ['dataUpdate'];
      this.publicUserTopics = ['vehicleApproved'];
      this.internalUserTopics = ['vehicleSubmitted'];
    },
    observedChannels: Ember.computed('currentUser.user', function () {
      var namespace = '';
      var allTopics = [];
      var userTopics = [];
      allTopics.push.apply(allTopics, this.globalTopics);
      userTopics.push.apply(userTopics, this.globalTopics);

      if (this.get('currentUser.user.isPublicUser')) {
        namespace = 'public_users';
        userTopics.push.apply(userTopics, this.publicUserTopics);
      } else if (this.get('currentUser.user.isAnonymous')) {// nothing... just nothing.
      } else {
        namespace = 'internal_users';
        allTopics.push.apply(allTopics, this.internalUserTopics);
        userTopics.push.apply(userTopics, this.internalUserTopics);
      }

      var channels = {};
      channels[namespace + ":all"] = allTopics;
      channels[namespace + ":" + this.get('currentUser.user.id')] = userTopics;
      return channels;
    })
  });

  _exports.default = _default;
});