define("accio-alert/pods/main/verifications/show/controller", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    canComment: Ember.computed.not('currentUser.user.isPublicUser'),
    hasComment: Ember.computed.notEmpty('commentBox'),
    hasNoComment: Ember.computed.not('hasComment'),
    canRFI: Ember.computed.and('canMakeDetermination', 'someSelectedReasons'),
    rfi: Ember.computed.equal('model.state', 'requested_more_info'),
    fileComments: Ember.computed.filterBy('model.comments', 'isFile', true),
    nonFileComments: Ember.computed.filterBy('model.comments', 'isFile', false),
    allRegistrationFront: Ember.computed.filterBy('fileComments', 'docTypeID', '3'),
    registrationFront: Ember.computed.alias('allRegistrationFront.firstObject'),
    registrationFrontValid: Ember.computed.notEmpty('registrationFront.fileLocation'),
    allRegistrationBack: Ember.computed.filterBy('fileComments', 'docTypeID', '4'),
    registrationBack: Ember.computed.alias('allRegistrationBack.firstObject'),
    registrationBackValid: Ember.computed.notEmpty('registrationBack.fileLocation'),
    requiredFilesUploaded: Ember.computed.and('registrationFrontValid', 'registrationBackValid'),
    canResubmit: Ember.computed.and('rfi', 'requiredFilesUploaded'),
    licenseFront: Ember.computed.filterBy('fileComments', 'docTypeID', '1'),
    licenseBack: Ember.computed.filterBy('fileComments', 'docTypeID', '2'),
    nonClickstreamComments: Ember.computed('nonFileComments', function () {
      return this.get('nonFileComments').filter(function (c) {
        return c.get('commentType') !== 'opened' && c.get('commentType') !== 'closed';
      });
    }),
    actions: {
      cancel: function cancel() {
        if (this.get('hasComment') || this.get('someSelectedReasons')) {
          if (confirm('Are you sure you want to return? You have unsaved changes')) {
            this._reset();

            this.transitionToRoute('admin.verifications.index');
          }
        } else {
          this.transitionToRoute('admin.verifications.index');
        }
      },
      resubmit: function resubmit() {
        var _this = this;

        var url = _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/verifications/" + this.get('model.id') + "/submit";
        this.get('model.vehicle').then(function (vehicle) {
          return vehicle.save();
        }).then(function () {
          return _this.get('ajax').post(url, {});
        }).then(function () {
          return _this.get('model').reload();
        }).then(function () {
          if (_this.get('nativeApp.isNativeApp')) {
            _this.get('nativeApp').postMessage({
              action: 'vehicles.refresh'
            });

            _this.get('nativeApp').popNavigation();
          } else {
            _this.transitionToRoute('home');
          }
        }).then(function () {
          _this.get('notifications').success('Resubmitted your vehicle for review');

          _this._reset();
        }).catch(function (_reason) {});
      }
    },
    _reset: function _reset() {
      this.setProperties({
        commentBox: undefined
      });
    }
  });

  _exports.default = _default;
});