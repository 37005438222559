define("accio-alert/models/service-annotation", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    summary: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 35
    })],
    features: [(0, _emberCpValidations.validator)('number-of-features-selected', {
      min: 1,
      itemName: 'blocks'
    })],
    startAt: [(0, _emberCpValidations.validator)('start-today-or-tomorrow', {
      disabled: Ember.computed.not('model.canEditStart'),
      boundary: '1845'
    })],
    endAt: [(0, _emberCpValidations.validator)('null-or-after-start-date', {
      boundary: '1845'
    }), (0, _emberCpValidations.validator)('end-today-or-tomorrow', {
      disabled: Ember.computed.readOnly('model.endAtInThePast'),
      boundary: '1845'
    })]
  });

  var _default = _model.default.extend(Validations, {
    summary: (0, _model.attr)('string'),
    features: (0, _model.attr)(),
    serviceAnnotationType: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    internalReason: (0, _model.attr)('string'),
    startAt: (0, _model.attr)('date'),
    endAt: (0, _model.attr)('date'),
    actions: (0, _model.attr)(),
    updatedAt: (0, _model.attr)('date'),
    campaign: (0, _model.belongsTo)('campaign'),
    lastModifiedBy: (0, _model.belongsTo)('user'),
    startAtInThePast: Ember.computed('startAt', function () {
      var startAt = this.startAt;
      return startAt !== null && startAt < new Date();
    }),
    endAtInThePast: Ember.computed('endAt', function () {
      var endAt = this.endAt;
      return endAt !== null && endAt < new Date();
    }),
    isFuture: Ember.computed('startAt', function () {
      var startAt = this.startAt;
      return startAt !== null && startAt > new Date();
    }),
    canEditStart: Ember.computed('isNew', 'startAt', function () {
      return this.get('isNew') || !this.get('startAtInThePast');
    }),
    canEdit: Ember.computed('startAt', 'endAt', function () {
      return this.get('endAt') === null || this.get('endAt') > new Date();
    })
  });

  _exports.default = _default;
});