define("accio-alert/pods/components/pdsp-vehicle-form/component", ["exports", "ember-cp-validations", "accio-alert/mixins/make-model-cascade"], function (_exports, _emberCpValidations, _makeModelCascade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    selectedState: (0, _emberCpValidations.validator)('presence', true),
    selectedMake: (0, _emberCpValidations.validator)('presence', true),
    selectedModel: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(_makeModelCascade.default, Validations, {
    store: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    didValidate: false,
    isNotNativeApp: Ember.computed.not('nativeApp.isNativeApp'),
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        Ember.RSVP.all([model.validate(), this.validate()]).then(function (_ref) {
          var modelValidations = _ref[0].validations,
              controllerValidations = _ref[1].validations;

          _this.set('didValidate', true);

          if (modelValidations.get('isValid') && controllerValidations.get('isValid')) {
            return Ember.RSVP.resolve();
          } else {
            _this.onValidationFailed();

            return Ember.RSVP.reject();
          }
        }).then(function () {
          return _this.get('model').save();
        }).then(function (savedModel) {
          _this.onSave(savedModel);
        }).catch(function () {});
      },
      cancel: function cancel() {
        var model = this.get('model');
        model.rollbackAttributes();
        this.onCancel();
      }
    }
  });

  _exports.default = _default;
});