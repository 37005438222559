define("accio-alert/pods/components/pdsp-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AmSmsYjF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"btn btn-lg btn-toggle \",[28,\"if\",[[24,[\"value\"]],\"active\",\"\"],null]]]],[12,\"aria-pressed\",\"false\"],[12,\"autocomplete\",\"off\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"handle\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});