define("accio-alert/pods/main/vehicles/edit/finish/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    actions: {
      finish: function finish() {
        var _this = this;

        if (this.nativeApp.isNativeApp) {
          this.nativeApp.postMessage({
            action: 'vehicles.refresh'
          });
        }

        this.currentUser.load().then(function () {
          _this.transitionToRoute('home');
        });
      }
    }
  });

  _exports.default = _default;
});