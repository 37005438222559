define("accio-alert/pods/components/pdsp-my-tasks/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    realtime: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.once(this, this._refreshActiveTasks);

      if (!window.isNativeApp) {// this.get('realtime.userChannel').on('taskUpdated', () =>
        //   run.once(this, this._refreshActiveTasks)
        // );
      }
    },
    _refreshActiveTasks: function _refreshActiveTasks() {
      var _this = this;

      this.get('store').query('task', {
        status: 'active'
      }).then(function (tasks) {
        return _this.set('activeTasks', tasks);
      });
    }
  });

  _exports.default = _default;
});