define("accio-alert/pods/components/onboarding/terms-and-conditions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    reloadTasks: function reloadTasks() {
      return this.get('currentUser.user.onboardingTasks').reload();
    },
    reloadCurrentUser: function reloadCurrentUser() {
      return this.get('currentUser').load();
    },
    actions: {
      completeTask: function completeTask(event) {
        var _this = this;

        event.preventDefault();
        var input = document.getElementById('t-and-c');

        if (input.checked) {
          var tasks = this.get('currentUser.user.onboardingTasks');
          tasks.forEach(function (task) {
            if (task.get('taskType') == 'onboarding.terms_and_conditions') {
              var data = {
                data: {
                  id: task.get('id'),
                  workflow_action: 'complete'
                }
              };
              var url = _this.get('ajax').namespacedUrl + "/tasks/" + task.get('id') + "/process-action";

              _this.ajax.post(url, data).then(function (_result) {
                _this.reloadCurrentUser().then(_this.reloadTasks()).then(function () {
                  return _this.get('router').transitionTo('main.patient');
                });
              });
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});