define("accio-alert/pods/components/pdsp-my-alerts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSOegV8B",
    "block": "{\"symbols\":[\"alert\"],\"statements\":[[7,\"h3\",true],[10,\"class\",\"header-title m-t-0 m-b-20\"],[8],[0,\"Alerts\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"alerts\"]]],null,{\"statements\":[[4,\"unless\",[[23,1,[\"isArchived\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"pdsp-my-alerts/pdsp-alert\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},{\"statements\":[[0,\"\\t  No unread alerts.\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-alerts/template.hbs"
    }
  });

  _exports.default = _default;
});