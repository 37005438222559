define("accio-alert/pods/components/pdsp-subscription-vehicles/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'ul',
    classNames: 'list-unstyled',
    approvedVehicles: Ember.computed(function () {
      return this.get('store').query('vehicle', {
        verification_state: ['approved']
      });
    }),
    actions: {
      addVehicle: function addVehicle(vehicleId) {
        var vehicleIds = this.get('model.vehicleIds') || Ember.A();
        vehicleIds.pushObject(vehicleId);
        this.set('model.vehicleIds', vehicleIds);
      },
      deleteVehicle: function deleteVehicle(vehicleId) {
        this.get('model.vehicleIds').removeObject(vehicleId);
      }
    }
  });

  _exports.default = _default;
});