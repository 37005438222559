define("accio-alert/pods/public/reset-password/step2/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 100
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: '{description} do not match',
      description: 'Passwords'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notificationMessages: Ember.inject.service('notifications'),
    didValidate: false,
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          return validations.get('isValid') ? Ember.RSVP.resolve() : Ember.RSVP.reject();
        }).then(function () {
          var url = _this.get('ajax.namespacedUrl') + "/users/reset-password";
          return _this.get('ajax').post(url, {
            contentType: 'application/json',
            data: {
              resetToken: _this.get('model'),
              password: _this.get('password'),
              confirmPassword: _this.get('confirmPassword')
            }
          });
        }).then(function (data) {
          _this.notificationMessages.success('Password changed. Logging you in...');

          _this.get('session').authenticate('authenticator:jwt', {
            username: data.identification,
            password: _this.get('password'),
            secondFactor: data.secondFactor
          });
        }).catch(function (err) {
          _this.set('errorMessage', err.payload.message);
        });
      }
    }
  });

  _exports.default = _default;
});