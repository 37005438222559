define("accio-alert/pods/components/workflows/execute/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    componentName: Ember.computed('model.action', function () {
      if (this.get('model.action') === null || this.get('model.action.length') === 0) return null;

      if (this.get('model.action.type') === 'component') {
        return "workflows/components/" + this.get('model.action.web');
      }

      return "workflows/steps/" + this.get('model.action.type');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$(document).on('click', function (e) {
        if (e.target.dataset.polkaWorkflowJump) {
          Ember.run.once(_this, _this.actions.jump, e.target.dataset.polkaWorkflowJump);
          return false;
        }
      });
    },
    actions: {
      onConversationCompleted: function onConversationCompleted() {
        this.get('model').reload();
      },
      jump: function jump(stepApiName) {
        var _this2 = this;

        var url = this.get('ajax.autoNamespacedUrl') + "/workflows/" + this.model.id + "/jump";
        this.get('ajax').post(url, {
          data: {
            step_api_name: stepApiName
          }
        }).then(function (data) {
          return _this2.store.pushPayload(data);
        });
      },
      onResponse: function onResponse(context) {
        var _this3 = this;

        if (this.get('model.action.is_end_of_phase')) {
          if (this.get('currentUser.user.isAnonymous')) {
            this.onNavigate('logout');
          } else {
            this.onNavigate('home');
          }
        } else {
          var url = this.get('ajax.autoNamespacedUrl') + "/workflows/" + this.model.id + "/input";
          this.get('ajax').post(url, {
            data: {
              input: context
            }
          }).then(function (response) {
            _this3.store.pushPayload(response);

            if (['completed', 'cancelled'].includes(_this3.get('model.currentStepApiName'))) {
              if (_this3.get('currentUser.user.isAnonymous')) {
                _this3.onNavigate('logout');
              } else {
                if (_this3.model.parentWorkflowId) {
                  _this3.store.findRecord('workflow', _this3.model.parentWorkflowId).then(function (parentWorkflow) {
                    _this3.onNavigate('main.workflows.execute', parentWorkflow.id);
                  });
                } else {
                  _this3.onNavigate('home');
                }
              }
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});