define("accio-alert/models/conversation", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _model.attr)('string'),
    header: (0, _model.attr)('string'),
    footer: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    responses: (0, _model.attr)() // currentFragment: belongsTo('fragment')

  });

  _exports.default = _default;
});