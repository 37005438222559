define("accio-alert/pods/components/onboarding/pdsp-task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xxLG/ZZB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"componentName\"]]],[[\"model\",\"onSkip\",\"onNext\",\"nextTask\",\"prevTask\",\"hasNext\",\"hasPrev\"],[[24,[\"model\"]],[24,[\"onSkip\"]],[24,[\"onNext\"]],[24,[\"nextTask\"]],[24,[\"prevTask\"]],[24,[\"hasNext\"]],[24,[\"hasPrev\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/onboarding/pdsp-task/template.hbs"
    }
  });

  _exports.default = _default;
});