define("accio-alert/components/pdsp-validated-timepicker", ["exports", "common/components/pdsp-validated-timepicker"], function (_exports, _pdspValidatedTimepicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pdspValidatedTimepicker.default;
    }
  });
});