define("accio-alert/pods/main/patient/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notificationMessages: Ember.inject.service('notifications'),
    appointmentQueues: ['scheduled', 'scheduled_today', 'checked_in'],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('subscriptionOrdering', ['campaign.name', 'notification.name']);
    },
    flaggedVehicles: Ember.computed.filterBy('model.vehicles', 'flagged', true),
    flaggedVehiclesPresent: Ember.computed.notEmpty('flaggedVehicles'),
    flaggedContactMethods: Ember.computed.filterBy('model.contactMethods', 'flagged', true),
    flaggedContactMethodsPresent: Ember.computed.notEmpty('flaggedContactMethods'),
    anyFlaggedItems: Ember.computed.or('flaggedVehiclesPresent', 'flaggedContactMethodsPresent'),
    orderedSubscriptions: Ember.computed.sort('model.subscriptions', 'subscriptionOrdering'),
    leafCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'leaf'),
    streetSweepingCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'street_sweeping'),
    location: Ember.computed.alias('model.locations.firstObject'),
    campaigns: Ember.computed(function () {
      return this.store.findAll('campaign');
    }),
    holidayGreeneryCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'holiday_greenery'),
    withinHGSeason: Ember.computed(function () {
      var date = new Date();
      return date >= moment('2019-12-26').toDate() && date <= moment('2020-01-31').toDate();
    }),
    actions: {
      onAddLocation: function onAddLocation() {
        this.transitionToRoute('main.locations.new.home');
      },
      deleteItem: function deleteItem(item) {
        item.destroyRecord();
      },
      cancel: function cancel(issue) {
        var _this = this;

        // kick off cancellation workflow
        issue.get('workflows').then(function (workflows) {
          var nextStep = 'cancel';

          if (nextStep) {
            var url = _this.get('ajax.autoNamespacedUrl') + "/workflows/" + workflows.get('firstObject.id') + "/jump";
            console.log(url);

            _this.get('ajax').post(url, {
              data: {
                step_api_name: nextStep
              }
            }).then(function () {
              _this.get('store').unloadRecord(issue);

              _this.get('notificationMessages').success('Appointment Canceled');
            });
          }
        });
      },
      checkIn: function checkIn(issue) {
        var _this2 = this;

        // console.log('controller check in ', issue);
        issue.get('workflows').then(function (workflows) {
          var nextStep = workflows.get('firstObject.action.next_phase') || 'checkin';

          if (nextStep) {
            var url = _this2.get('ajax.autoNamespacedUrl') + "/workflows/" + workflows.get('firstObject.id') + "/jump";

            _this2.get('ajax').post(url, {
              data: {
                step_api_name: nextStep
              }
            }).then(function (data) {
              _this2.store.pushPayload(data);

              _this2.transitionToRoute('main.workflows.execute', workflows.get('firstObject'));
            });
          }
        });
      },
      configureLocation: function configureLocation(location, locationSubscription) {
        this.transitionToRoute('main.locations.edit.select_features', location, locationSubscription);
      }
    }
  });

  _exports.default = _default;
});