define("accio-alert/pods/components/pdsp-subscription-vehicle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C+QqjGIg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[0,\"\\n\\t\"],[7,\"input\",false],[12,\"checked\",[22,\"checked\"]],[12,\"type\",\"checkbox\"],[3,\"action\",[[23,0,[]],\"toggleField\"],[[\"on\"],[\"change\"]]],[8],[9],[0,\" \"],[1,[24,[\"vehicle\",\"make\"]],false],[0,\" \"],[1,[24,[\"vehicle\",\"model\"]],false],[0,\" (\"],[1,[24,[\"vehicle\",\"licensePlateState\"]],false],[0,\" \"],[1,[24,[\"vehicle\",\"licensePlate\"]],false],[0,\")\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-subscription-vehicle/template.hbs"
    }
  });

  _exports.default = _default;
});