define("accio-alert/pods/components/pdsp-enforcement-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['m-t-20', 'row'],
    buttonClasses: Ember.computed(function () {
      var classes = 'btn btn-default btn-lg btn-block ';
      return classes + this.get('model.buttonClass');
    })
  });

  _exports.default = _default;
});