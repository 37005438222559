define("accio-alert/pods/public/digests/show/controller", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service(),
    cdnURL: Ember.computed(function () {
      return _environment.default.cdnURL;
    }),
    totalNativeContactMethods: Ember.computed('model', function () {
      var cms = this.model.metrics.contact_methods;
      return cms.ios + cms.android;
    })
  });

  _exports.default = _default;
});