define("accio-alert/pods/mobile/update-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vnlzgS9H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\"],[7,\"form\",false],[12,\"class\",\"form-horizontal m-t-20\"],[3,\"action\",[[23,0,[]],\"updatePassword\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"pdsp-validated-input\",null,[[\"type\",\"placeholder\",\"model\",\"valuePath\",\"didValidate\"],[\"password\",\"Current Password\",[23,0,[]],\"currentPassword\",[24,[\"didValidate\"]]]]],false],[0,\"\\n\\t\\t\"],[1,[28,\"pdsp-validated-input\",null,[[\"type\",\"placeholder\",\"model\",\"valuePath\",\"didValidate\"],[\"password\",\"New Password\",[23,0,[]],\"newPassword\",[24,[\"didValidate\"]]]]],false],[0,\"\\n\\t\\t\"],[1,[28,\"pdsp-validated-input\",null,[[\"type\",\"placeholder\",\"model\",\"valuePath\",\"didValidate\"],[\"password\",\"Confirm New Password\",[23,0,[]],\"confirmNewPassword\",[24,[\"didValidate\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"text-danger\"],[8],[1,[22,\"errorMessage\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"form-group text-center m-t-20\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-info btn-block text-uppercase\"],[10,\"type\",\"submit\"],[8],[0,\"Update Password\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"m-t-10\"],[8],[0,\"\\n\\t\\t\\t\\t\\tor \"],[4,\"link-to\",null,[[\"route\"],[\"mobile.profile\"]],{\"statements\":[[0,\"cancel\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/update-password/template.hbs"
    }
  });

  _exports.default = _default;
});