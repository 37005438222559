define("accio-alert/pods/components/registration/patient/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    lastName: (0, _emberCpValidations.validator)('presence', true),
    phoneNumber: [(0, _emberCpValidations.validator)('format', {
      regex: /^[0-9]{10}$/,
      disabled: Ember.computed.equal('model.medium', 'email'),
      message: 'Invalid phone number. Please enter a 10-digit US phone number.'
    }), (0, _emberCpValidations.validator)('ds-error', {
      disabled: Ember.computed.equal('model.medium', 'email')
    })],
    sex: (0, _emberCpValidations.validator)('presence', true),
    race: (0, _emberCpValidations.validator)('presence', true),
    ethnicity: (0, _emberCpValidations.validator)('presence', true),
    address: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      value: function value(model, _attribute) {
        var address = model.get('address');

        if (address && Object.keys(address).length === 0) {
          return undefined;
        }

        return address;
      }
    })
  });

  var _default = Ember.Component.extend(Validations, {
    session: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    sexes: ['Male', 'Female', 'Other'],
    races: ['Asian', 'American Indian', 'Black\/African American', 'Islander', 'White', 'Undisclosed'],
    ethnicities: ['Hispanic', 'Non-Hispanic', 'Undisclosed'],
    didValidate: false,
    isEmailUsernameType: Ember.computed.equal('model.usernameType', 'email'),
    usernameTypePlaceholder: Ember.computed('model.usernameType', function () {
      return Ember.String.capitalize(this.model.usernameType);
    }),
    maxLength: Ember.computed('model.usernameType', function () {
      return this.model.usernameType === 'phone' ? 10 : 100;
    }),
    usernameFooterText: Ember.computed('model.usernameType', function () {
      if (this.model.usernameType === 'phone') {
        return 'US Numbers Only. Example: 2025551234';
      }

      return '';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.salutations = ['Dr.', 'Mr.', 'Mrs.', 'Ms.'];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.address === undefined) {
        this.set('address', {});
      }
    },
    actions: {
      setGroupType: function setGroupType(type) {
        this.set('groupType', type);
      },
      setUsernameType: function setUsernameType(type) {
        this.set('model.username', '');
        this.set('model.usernameType', type);
      },
      register: function register() {
        Ember.run.throttle(this, this._doRegister, 5000);
      }
    },
    _doRegister: function _doRegister() {
      var _this = this;

      var model = this.get('model');
      model.validate().then(function (_ref) {
        var validations = _ref.validations;

        _this.set('didValidate', true);

        if (validations.get('isValid')) {
          Ember.set(model, 'lastName', _this.get('lastName'));
          Ember.set(model, 'phoneNumber', _this.get('phoneNumber'));
          Ember.set(model, 'dateOfBirth', _this.get('dateOfBirth'));
          Ember.set(model, 'sex', _this.get('sex'));
          Ember.set(model, 'race', _this.get('race'));
          Ember.set(model, 'ethnicity', _this.get('ethnicity'));
          Ember.set(model, 'address', _this.get('address'));
          model.save().then(function () {
            _this.set('didValidate', false);

            _this.get('session').authenticate('authenticator:jwt', {
              username: _this.get('model.username'),
              password: _this.get('model.password')
            });
          });
        }
      });
    }
  });

  _exports.default = _default;
});