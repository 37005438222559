define("accio-alert/helpers/date-has-availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function dateHasAvailability(_ref) {
    var date = _ref[0],
        availability = _ref[1];
    return Boolean(availability.find(function (item) {
      return item.date === date && item.num_appointments > 0;
    }));
  });

  _exports.default = _default;
});