define("accio-alert/pods/components/pdsp-contact-method-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['glyphicons'],
    tagName: 'i',
    classNameBindings: ['medium'],
    medium: Ember.computed('contactMethod', function () {
      var medium = this.get('contactMethod.medium');
      var className = medium === 'email' ? 'envelope' : 'iphone';
      return "glyphicons-" + className;
    })
  });

  _exports.default = _default;
});