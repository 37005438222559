define("accio-alert/pods/components/pdsp-digest/row-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hr2rNTpF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"image\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[29,[[28,\"asset-map\",[[28,\"concat\",[\"assets/images/\",[24,[\"image\"]]],null]],null]]]],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\n  \"],[1,[24,[\"campaign\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"h4\",true],[10,\"class\",\"m-0 text-dark font-600\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-digest/row-header/template.hbs"
    }
  });

  _exports.default = _default;
});