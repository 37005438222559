define("accio-alert/pods/main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vyhG9Yd8",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"pdsp-top-nav-bar\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"top-bar-main-view\"],[8],[0,\"\\n\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"bottom: 0; padding: 10px;\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[8],[0,\"\\n\\t\\t\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\t\\tBuilt by \"],[7,\"a\",true],[10,\"href\",\"https://kznconsulting.com\"],[10,\"target\",\"_blank\"],[8],[0,\"KZN Consulting\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/template.hbs"
    }
  });

  _exports.default = _default;
});