define("accio-alert/pods/components/pdsp-subscription-contact-method/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'li',
    checked: Ember.computed('model', function () {
      var contactMethodIds = this.get('model.contactMethodIds');
      var contactMethodId = parseInt(this.get('contactMethod.id'));
      return Ember.isPresent(contactMethodIds) && contactMethodIds.indexOf(contactMethodId) > -1;
    }),
    actions: {
      toggleField: function toggleField() {
        this.toggleProperty('checked');

        if (this.get('checked')) {
          this.onContactMethodDeleted(parseInt(this.get('contactMethod.id')));
        } else {
          this.onContactMethodAdded(parseInt(this.get('contactMethod.id')));
        }
      }
    }
  });

  _exports.default = _default;
});