define("accio-alert/validators/number-of-features-selected", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MinimumFeaturesSelected = _base.default.extend({
    validate: function validate(value, options) {
      var min = options.min || 0;
      var max = options.max || 500;

      if (value.length >= min && value.length <= max) {
        return true;
      }

      if (options.min === undefined && options.max !== undefined) {
        return "Please select at most " + options.max + " " + options.itemName;
      }

      if (options.min !== undefined && options.max === undefined) {
        return "Please select at least " + options.min + " " + options.itemName;
      }
    }
  });

  MinimumFeaturesSelected.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor()
    /* attribute, options */
    {
      return ['features'];
    }
  });
  var _default = MinimumFeaturesSelected;
  _exports.default = _default;
});