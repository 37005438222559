define("accio-alert/pods/main/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Infpslj1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ex-page-content text-center\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[8],[0,\"Oops!\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"Looks like you may have logged in previously to view another secure page. Click LOGOUT and then try the link again.\"],[9],[0,\"\\n\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default waves-effect waves-light\",\"logout\"]],{\"statements\":[[0,\"Logout\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});