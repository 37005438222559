define("accio-alert/pods/mobile/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wjagwn6r",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"pdsp-my-account\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/profile/template.hbs"
    }
  });

  _exports.default = _default;
});