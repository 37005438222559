define("accio-alert/validators/address", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Address = _base.default.extend({
    validate: function validate(value, options, _model) {
      if (value === null || value === undefined || value === '') {
        return options.message || 'Please enter the Address';
      }

      var fields = {
        street: 'Street',
        city: 'City',
        province: 'State',
        postalCode: 'Zip Code'
      };
      var missingKeys = Object.keys(fields).filter(function (field) {
        return value[field] === undefined || value[field] === null || value[field] === '';
      });

      if (missingKeys.length > 0) {
        return "Please enter the " + missingKeys.map(function (key) {
          return fields[key];
        }).join(', ');
      } else {
        return true;
      }
    }
  });

  Address.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      return ["model." + attribute + ".street", "model." + attribute + ".city", "model." + attribute + ".province", "model." + attribute + ".postalCode"];
    }
  });
  var _default = Address;
  _exports.default = _default;
});