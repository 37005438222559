define("accio-alert/pods/components/pdsp-verification-vehicle-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q3V2/6m8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[10,\"class\",\"panel-title\"],[8],[0,\"Vehicle Information\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"style\",\"font-size: 150%\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"dl\",true],[10,\"class\",\"dl-horizontal\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dt\",true],[8],[0,\"Make\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dd\",true],[8],[1,[24,[\"model\",\"make\"]],false],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"dt\",true],[8],[0,\"Model\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dd\",true],[8],[1,[24,[\"model\",\"model\"]],false],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"dt\",true],[8],[0,\"Tag State\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dd\",true],[8],[1,[24,[\"model\",\"licensePlateState\"]],false],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"dt\",true],[8],[0,\"License Plate\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"dd\",true],[8],[1,[24,[\"model\",\"licensePlate\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-verification-vehicle-info/template.hbs"
    }
  });

  _exports.default = _default;
});