define("accio-alert/helpers/query-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    init: function init() {
      this.set('rules', []);
      this.order = null;
    },
    where: function where(key, operator, value, type) {
      this.rules.push({
        field: key,
        type: type,
        operator: operator,
        value: value
      });
      return this;
    },
    equals: function equals(key, value, type) {
      type = type || 'string';
      return this.where(key, 'equal', value, type);
    },
    notEqual: function notEqual(key, value, type) {
      type = type || 'string';
      return this.where(key, 'not_equal', value, type);
    },
    contains: function contains(key, value, type) {
      type = type || 'string';
      return this.where(key, 'contains', value, type);
    },
    doesNotContain: function doesNotContain(key, value, type) {
      type = type || 'string';
      return this.where(key, 'not_contains', value, type);
    },
    in: function _in(key, value, type) {
      type = type || 'string';
      return this.where(key, 'in', value, type);
    },
    search: function search(field, query) {
      return this.where(field, 'search', query, 'search');
    },
    remove: function remove(key) {
      this.rules = this.rules.filter(function (rule) {
        return rule.field !== key;
      });
      return this;
    },
    orderBy: function orderBy(field, direction) {
      this.order = {
        field: field,
        direction: direction
      };
    },
    toJSON: function toJSON() {
      var params = {
        condition: 'AND',
        rules: this.rules,
        valid: true
      };

      if (this.order) {
        params.order = this.order;
      }

      return JSON.stringify(params);
    }
  });

  _exports.default = _default;
});