define("accio-alert/pods/components/pdsp-vehicle-campaign-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gQbDHhFH",
    "block": "{\"symbols\":[\"campaign\"],\"statements\":[[4,\"each\",[[24,[\"campaigns\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"isVehicleCampaign\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"style\",\"display: flex; flex-direction: row\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"contains\",[[23,1,[\"id\"]],[24,[\"actives\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"label\",true],[8],[0,\"\\n          \"],[7,\"input\",true],[11,\"id\",[29,[\"campaign-\",[23,1,[\"id\"]]]]],[11,\"checked\",[28,\"contains\",[[23,1,[\"id\"]],[24,[\"selectedCampaigns\"]]],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleCampaign\",[23,1,[\"id\"]]],null]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"style\",\"display: inline-block; margin-left: 5px\"],[10,\"class\",\"h4 m-t-0 m-b-0\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"style\",\"margin-left: 20px\"],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"style\",\"display: inline-block;\"],[10,\"class\",\"h4 m-t-0 m-b-0\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[7,\"em\",true],[8],[0,\"This service isn't provided at this location\"],[9],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-vehicle-campaign-selector/template.hbs"
    }
  });

  _exports.default = _default;
});