define("accio-alert/pods/components/pdsp-service-annotations/actions-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    numAttrValues: Ember.computed('model', function () {
      return this.model.features.length;
    })
  });

  _exports.default = _default;
});