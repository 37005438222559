define("accio-alert/pods/main/contact-methods/form/controller", ["exports", "ember-cp-validations", "accio-alert/mixins/contact-limits"], function (_exports, _emberCpValidations, _contactLimits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    selectedMedium: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Controller.extend(_contactLimits.default, Validations, {
    store: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    didValidate: false,
    isSMS: Ember.computed.equal('model.medium', 'sms'),
    isMediumBlank: Ember.computed.empty('model.medium'),
    mediums: Ember.computed('numSmsContacts', 'numEmailContacts', function () {
      var result = [];

      if (!this.get('hasReachedEmailLimit')) {
        result.push({
          id: 'email',
          name: 'E-Mail'
        });
      }

      if (!this.get('hasReachedSmsLimit')) {
        result.push({
          id: 'sms',
          name: 'SMS'
        });
      }

      return result;
    }),
    actions: {
      updateMedium: function updateMedium(item) {
        this.set('selectedMedium', item);
        this.set('model.medium', item.id);
        this.set('model.email', null);
        this.set('model.phoneNumber', null);
      },
      save: function save() {
        var _this = this;

        var model = this.get('model');
        Ember.RSVP.all([model.validate(), this.validate()]).then(function (_ref) {
          var modelValidations = _ref[0].validations,
              controllerValidations = _ref[1].validations;

          _this.set('didValidate', true);

          if (modelValidations.get('isValid') && controllerValidations.get('isValid')) {
            return Ember.RSVP.resolve();
          } else {
            return Ember.RSVP.reject();
          }
        }).then(function () {
          return model.save();
        }).then(function () {
          _this.reset();

          _this.get('currentUser.user.contactMethods').reload();

          _this.gotoList();
        }).catch(function () {});
      },
      cancel: function cancel() {
        var model = this.get('model');
        this.reset();
        model.rollbackAttributes();
        this.gotoList();
      }
    },
    reset: function reset() {
      this.setProperties({
        selectedMedium: null,
        didValidate: false
      });
    },
    gotoList: function gotoList() {
      if (this.nativeApp.isNativeApp) {
        this.transitionToRoute('mobile.contact_methods');
      } else {
        this.transitionToRoute('home');
      }
    }
  });

  _exports.default = _default;
});