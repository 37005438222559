define("accio-alert/pods/mobile/notification-messages/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(params) {
      var url = this.ajax.namespacedUrl + "/notification-messages/" + params.notification_message_id + "/preview";
      return this.ajax.request(url);
    }
  });

  _exports.default = _default;
});