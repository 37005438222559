define("accio-alert/components/issues/appointment-public/list-item-mobile", ["exports", "common/components/issues/appointment-public/list-item-mobile"], function (_exports, _listItemMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _listItemMobile.default;
    }
  });
});