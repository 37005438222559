define("accio-alert/pods/public/register/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    registrationComponent: Ember.computed('currentAccount.account', function () {
      var regComponent = this.get('currentAccount.account.accountInitialRegistrationComponent') || 'generic';
      return "registration/" + regComponent;
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('username', 'password');
        var authenticator = 'authenticator:jwt';
        this.get('session').authenticate(authenticator, credentials).catch(function (reason) {
          _this.set('errorMessage', reason.json.message || reason);
        });
      }
    }
  });

  _exports.default = _default;
});