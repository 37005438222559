define("accio-alert/pods/public/reset-password/step1/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gi5sk7Z+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"Enter your username* and click the button and we'll send you a password reset link.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"form\",false],[12,\"class\",\"form-horizontal m-t-20\"],[3,\"action\",[[23,0,[]],\"sendResetCode\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\n\\t\"],[1,[28,\"pdsp-validated-input\",null,[[\"valuePath\",\"model\",\"placeholder\",\"didValidate\"],[\"identifier\",[23,0,[]],\"Email or Phone Number\",[24,[\"didValidate\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\",true],[10,\"class\",\"text-danger\"],[8],[1,[22,\"errorMessage\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"successMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\",true],[10,\"class\",\"text-success\"],[8],[1,[22,\"successMessage\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[1,[28,\"log\",[[24,[\"nativeApp\",\"isNativeApp\"]]],null],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-group text-center m-t-20\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-info btn-block text-uppercase\"],[10,\"type\",\"submit\"],[8],[0,\"Send Reset Link\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"m-t-10\"],[8],[0,\"\\n\\t\\t\\t\\tor \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"backToLogin\"]],[8],[0,\"Back to Login\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"p\",true],[8],[0,\"* Your username is the email address or phone number you used to create your account.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/reset-password/step1/template.hbs"
    }
  });

  _exports.default = _default;
});