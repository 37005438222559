define("accio-alert/pods/main/locations/edit/select-features/route", ["exports", "accio-alert/mixins/data-route"], function (_exports, _dataRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataRoute.default, {
    notifications: Ember.inject.service(),
    willTransitionConfirm: function willTransitionConfirm() {
      return window.confirm('Are you sure you want to revert? Any updated selections you’ve made won’t be saved.');
    }
  });

  _exports.default = _default;
});