define("accio-alert/pods/components/workflows/execute/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZICMHeFM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row workflow-execute\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"currentStepApiName\"]],\"completed\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"\\n            You've completed this workflow.\\n          \"],[9],[0,\"\\n\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default\",\"home\"]],{\"statements\":[[0,\"            Back to Home\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"componentName\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[24,[\"componentName\"]]],[[\"model\",\"currentStepApiName\",\"onCancel\",\"onConversationCompleted\",\"onResponse\"],[[24,[\"model\"]],[24,[\"model\",\"currentStepApiName\"]],[28,\"action\",[[23,0,[]],\"jump\",\"cancelled\"],null],[28,\"action\",[[23,0,[]],\"onConversationCompleted\"],null],[28,\"action\",[[23,0,[]],\"onResponse\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/workflows/execute/template.hbs"
    }
  });

  _exports.default = _default;
});