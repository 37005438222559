define("accio-alert/pods/mobile/location-new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.transitionTo('main.locations.new.home');
    }
  });

  _exports.default = _default;
});