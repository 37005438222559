define("accio-alert/services/current-account", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    datadog: Ember.inject.service(),
    load: function load() {
      var _this = this;

      if (Ember.isPresent(this.get('account'))) {
        return Ember.RSVP.resolve();
      }

      var url = _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/account-info";
      return this.get('ajax').request(url).then(function (data) {
        _this.get('datadog').addRumGlobalContext('account_id', data.id);

        _this.set('account', data);

        document.title = data.accountDisplayName;
      });
    }
  });

  _exports.default = _default;
});