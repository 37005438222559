define("accio-alert/pods/public/reset-password/step1/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    identifier: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Controller.extend(Validations, {
    ajax: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    didValidate: false,
    actions: {
      backToLogin: function backToLogin() {
        if (this.get('nativeApp.isNativeApp')) {
          this.nativeApp.popNavigation();
        } else {
          this.transitionToRoute('public.login');
        }
      },
      sendResetCode: function sendResetCode() {
        var _this = this;

        var url = this.get('ajax.namespacedUrl') + "/users/send-reset-code";
        var identifier = this.get('identifier');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          return validations.get('isValid') ? Ember.RSVP.resolve() : Ember.RSVP.reject({
            payload: {
              message: ''
            }
          });
        }).then(function () {
          return _this.get('ajax').post(url, {
            contentType: 'application/json',
            data: {
              identifier: identifier
            }
          });
        }).then(function (response) {
          _this.set('successMessage', response.message);

          if (_this.get('nativeApp.isNativeApp')) {
            alert('If this account exists in our system, we are sending a message to it with a reset link. Please check it.');

            _this.nativeApp.popNavigation();
          }

          _this.set('errorMessage', null);
        }).catch(function (response) {
          _this.set('errorMessage', response.payload.message);

          _this.set('successMessage', null);
        });
      }
    }
  });

  _exports.default = _default;
});