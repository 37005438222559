define("accio-alert/pods/main/update-password/controller", ["exports", "accio-alert/config/environment", "ember-cp-validations"], function (_exports, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    currentPassword: (0, _emberCpValidations.validator)('presence', true),
    newPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 50
    })],
    confirmNewPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: '{description} do not match',
      description: 'Passwords'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    notifications: Ember.inject.service(),
    actions: {
      updatePassword: function updatePassword() {
        var _this = this;

        var currentPassword = this.get('currentPassword');
        var newPassword = this.get('newPassword');
        var confirmNewPassword = this.get('confirmNewPassword');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          if (validations.get('isValid') === false) {
            return;
          }

          var url = _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/users/update-password";
          var data = {
            currentPassword: currentPassword,
            password: newPassword,
            confirmNewPassword: confirmNewPassword
          };
          return _this.get('ajax').post(url, {
            data: data
          }).then(function () {
            _this._reset();

            _this.get('notifications').success('Password Changed');

            if (_this.nativeApp.isNativeApp) {
              _this.transitionToRoute('mobile.profile');
            } else {
              _this.transitionToRoute(_this.get('currentUser.user.homeRoute'));
            }
          }).catch(function (data) {
            _this.set('errorMessage', data.payload.message);
          });
        });
      }
    },
    _reset: function _reset() {
      this.setProperties({
        didValidate: false,
        currentPassword: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined
      });
    }
  });

  _exports.default = _default;
});