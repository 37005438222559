define("accio-alert/pods/main/vehicles/new/onboarding/route", ["exports", "accio-alert/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    model: function model() {
      return this.modelFor('main.vehicles.new');
    }
  });

  _exports.default = _default;
});