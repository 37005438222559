define("accio-alert/pods/public/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentAccount: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var homeRoute = this.get('currentAccount.account.accountRoutesHome') || 'public.login';

      if (homeRoute.includes(';')) {
        var _homeRoute$split = homeRoute.split(';'),
            route = _homeRoute$split[0],
            param = _homeRoute$split[1];

        this.transitionTo(route, param);
      } else {
        this.transitionTo(homeRoute);
      }
    }
  });

  _exports.default = _default;
});