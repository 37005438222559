define("accio-alert/pods/main/onboarding/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentIndex: 0,
    hasNext: Ember.computed.notEmpty('nextTask'),
    hasPrev: Ember.computed.notEmpty('prevTask'),
    prevTask: Ember.computed('currentIndex', 'allTasks.[]', function () {
      var _this$getProperties = this.getProperties('allTasks', 'currentIndex'),
          allTasks = _this$getProperties.allTasks,
          currentIndex = _this$getProperties.currentIndex;

      if (currentIndex <= 0) {
        return null;
      }

      return allTasks.toArray()[currentIndex - 1];
    }),
    nextTask: Ember.computed('currentIndex', 'allTasks.[]', function () {
      var _this$getProperties2 = this.getProperties('allTasks', 'currentIndex'),
          allTasks = _this$getProperties2.allTasks,
          currentIndex = _this$getProperties2.currentIndex;

      if (currentIndex >= allTasks.length - 1) {
        return null;
      }

      return allTasks.toArray()[currentIndex + 1];
    }),
    actions: {
      skip: function skip() {
        var _this = this;

        var url = this.get('ajax.namespacedUrl') + "/tasks/" + this.model.id + "/cancel";
        return this.ajax.post(url, {
          data: {}
        }).then(function () {
          return Ember.run.once(_this, _this.actions.next);
        });
      },
      next: function next() {
        var _this2 = this;

        console.log(this.get('hasNext'));

        if (!this.get('hasNext')) {
          this.get('currentUser').load().then(function (user) {
            if (user.get('onboarded')) {
              _this2.transitionToRoute(_this2.get('currentUser.user.homeRoute'));
            }
          });
        } else {
          this.transitionToRoute('main.onboarding.index', this.get('currentIndex') + 1);
        }
      },
      prev: function prev() {
        this.transitionToRoute('main.onboarding.index', this.get('currentIndex') - 1);
      }
    }
  });

  _exports.default = _default;
});