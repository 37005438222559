define("accio-alert/pods/components/pdsp-unified-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zp8AYq+W",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-5\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"header-title m-t-0 m-b-20\"],[8],[0,\"\\n      \\t\"],[7,\"img\",true],[10,\"src\",\"\"],[10,\"class\",\"campaign-icon\"],[8],[9],[0,\"\\n      \\tUpcoming Residential Services\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n        \"],[5,\"power-select\",[],[[\"@options\",\"@searchEnabled\",\"@selected\",\"@onChange\"],[[22,\"locations\"],false,[22,\"location\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"location\"]]],null]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[\"nickname\"]],false],[0,\" – \"],[1,[23,1,[\"street\"]],false],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-4 text-right\"],[8],[0,\"\\n      \"],[2,\"\\n      <ul class=\\\"list-inline\\\">\\n        <li>List View</li>\\n        <li>Month View</li>\\n        <li>bubble Today</li>\\n      </ul>\\n    \"],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"location\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"pdsp-pipeline-view\",null,[[\"location\",\"isMobile\"],[[24,[\"location\"]],[24,[\"isMobile\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-unified-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});