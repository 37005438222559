define("accio-alert/models/subscription-location", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    locationAttributes: (0, _model.attr)('raw'),
    selectedFeatures: (0, _model.attr)('raw'),
    subscription: (0, _model.belongsTo)('subscription'),
    // had to call this parent_location instead of location since location
    // is a reserved word in ja_serializer
    parentLocation: (0, _model.belongsTo)('location')
  });

  _exports.default = _default;
});