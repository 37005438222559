define("accio-alert/pods/main/vehicles/edit/finish/route", ["exports", "accio-alert/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {});

  _exports.default = _default;
});