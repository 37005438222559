define("accio-alert/pods/components/workflows/steps/form-multi/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = Ember.Component.extend({
    defaultValidations: {
      date: {
        format: 'YYYY-MM-DD',
        errorFormat: 'l',
        message: 'Please enter a date in the M/D/YYYY format.'
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var currentStep = this.get('model.currentStepApiName');
      var currentContext = this.get('model.context')[this.model.action.save_to_context || currentStep] || {};
      this.set('currentContext', currentContext);
      var items = currentContext['items'] || [];
      this.set('items', Ember.A(items));
      this.set('item', {});
      this.reopen(this.buildDynamicValidations());
      this.set('didValidate', false);
      this.set('context', currentContext);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          if (validations.get('isValid')) {
            if (_this.item.id === undefined) {
              _this.item.id = _this._randomString(8, 'A#').toLowerCase();

              _this.get('items').pushObject(_this.item);
            }

            _this.set('didValidate', false);

            _this.set('item', {});
          } else {
            return Ember.RSVP.reject();
          }
        });
      },
      reset: function reset() {
        this.set('didValidate', false);
        this.set('item', {});
        this.set('cacheKey', undefined);
      },
      edit: function edit(item) {
        this.set('item', item);
        this.set('didValidate', false);
        this.set('cacheKey', item.id);
      },
      delete: function _delete(item) {
        var newItems = this.items.filter(function (i) {
          return i.id !== item.id;
        });
        this.set('items', newItems);
      },
      cancel: function cancel() {
        if (confirm('Are you sure you want to cancel?')) {
          this.onCancel();
        }
      },
      submit: function submit() {
        this.set('currentContext.items', this.get('items').toArray()); // console.log(this.get('currentContext'));

        console.log(this.get('context'));
        this.onResponse(this.get('context'));
      },
      updateSelection: function updateSelection(valuePath, newVal) {
        var rawValue = newVal.hasOwnProperty('value') ? newVal.value : newVal;
        this.set(valuePath, rawValue);
      }
    },
    _randomString: function _randomString(length, chars) {
      var mask = '';
      if (chars.indexOf('A') > -1) mask += 'ABCDEFGHJKLMNPRSTUVWXY';
      if (chars.indexOf('#') > -1) mask += '0123456789';
      var result = '';

      for (var i = length; i > 0; --i) {
        result += mask[Math.floor(Math.random() * mask.length)];
      }

      return result;
    },
    buildDynamicValidations: function buildDynamicValidations() {
      var _this2 = this;

      var validations = this.get('model.action.fields').reduce(function (obj, field) {
        var validations = field.validations || [];

        if (validations.length > 0) {
          var validatorConfig = validations.map(function (validation) {
            if (validation.type === 'required') {
              return (0, _emberCpValidations.validator)('presence', true);
            }

            if (validation.type === 'format') {
              return (0, _emberCpValidations.validator)('format', _extends({}, validation, {
                type: validation.formatType
              }));
            }

            if (field.type === 'address') {
              return (0, _emberCpValidations.validator)('address', _extends({}, validation));
            }

            var defaultValidation = _this2.defaultValidations[validation.type] || {};
            return (0, _emberCpValidations.validator)(validation.type, _extends({}, defaultValidation, validation));
          });
          obj["item." + field.save_to_context] = validatorConfig;
        }

        return obj;
      }, {});
      return (0, _emberCpValidations.buildValidations)(validations);
    }
  });

  _exports.default = _default;
});