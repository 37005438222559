define("accio-alert/pods/public/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+0XBp7ai",
    "block": "{\"symbols\":[],\"statements\":[[2,\" Begin page \"],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"wrapper\"],[8],[0,\"\\n\\t\"],[2,\" ============================================================== \"],[0,\"\\n\\t\"],[2,\" Start right Content here \"],[0,\"\\n\\t\"],[2,\" ============================================================== \"],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[2,\" Start content \"],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12  m-t-20\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"logo-small\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"currentAccount\",\"account\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[28,\"asset-map\",[[24,[\"currentAccount\",\"account\",\"themeLogoUrl\"]]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[0,\" Reset Password for \"],[7,\"strong\",true],[10,\"class\",\"text-custom\"],[8],[1,[24,[\"currentAccount\",\"account\",\"accountDisplayName\"]],false],[9],[0,\" \"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/reset-password/template.hbs"
    }
  });

  _exports.default = _default;
});