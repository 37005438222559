define("accio-alert/pods/components/pdsp-vehicle-campaign-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    autoSelectActive: true,
    campaigns: Ember.computed(function () {
      return this.get('store').findAll('campaign');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.selectedCampaigns = Ember.A();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.run.once(this, this._refreshStatusByCampaign);
    },
    actions: {
      toggleCampaign: function toggleCampaign(campaignId) {
        var id = campaignId;
        var idx = this.selectedCampaigns.indexOf(id);

        if (idx > -1) {
          this.selectedCampaigns.removeAt(idx);
        } else {
          this.selectedCampaigns.pushObject(id);
        }

        this.onSelectionUpdated(this.selectedCampaigns);
      }
    },
    _refreshStatusByCampaign: function _refreshStatusByCampaign() {
      var _this = this;

      var url = this.get('ajax').namespacedUrl + "/vehicles/" + this.get('vehicle.id') + "/campaigns";
      this.ajax.request(url).then(function (result) {
        _this.set('statusByCampaign', result);

        if (_this.autoSelectActive) {
          var actives = _this.statusByCampaign.filterBy('status', 'active').map(function (c) {
            return "" + c.id;
          });

          actives.forEach(function (cId) {
            return _this.selectedCampaigns.pushObject(cId);
          });

          _this.set('actives', actives);

          _this.onSelectionUpdated(actives);
        }
      });
    }
  });

  _exports.default = _default;
});