define("accio-alert/validators/conditional-required", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ConditionalRequired = _base.default.extend({
    validate: function validate(value, options, model) {
      var valueToEvaluate = model.get(options.value_path);
      var isRequired = options.expected_values.includes(valueToEvaluate); // console.log('conditional required', value, options, model.context.contact_preference, isRequired);

      if (isRequired) {
        if (value === undefined || value === '') {
          return options.message || 'This field must have a value';
        } else {
          return true;
        }
      } else {
        return true; // if it's not required, it should be valid
      }
    }
  });

  var _default = ConditionalRequired;
  _exports.default = _default;
});