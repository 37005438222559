define("accio-alert/models/service-area", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    apiName: (0, _model.attr)('string'),
    metadata: (0, _model.attr)('raw'),
    season: (0, _model.belongsTo)('season'),
    team: (0, _model.belongsTo)('team')
  });

  _exports.default = _default;
});