define("accio-alert/pods/components/onboarding/switch-registration-to-phone/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'phone'
    })],
    confirmUsername: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'username',
      message: '{description} do not match',
      description: 'Phone Numbers '
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    didValidate: false,
    actions: {
      switchToPhone: function switchToPhone() {
        var _this = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          if (validations.get('isValid')) {
            // let number = this.get('username');
            var url = _this.get('ajax.namespacedUrl') + "/users/switch-email-registration-to-phone";
            return _this.get('ajax').post(url, {
              data: {
                phone_number: _this.get('username')
              }
            });
          } else {
            return Ember.RSVP.reject({
              payload: {
                errors: [{
                  detail: ''
                }]
              }
            });
          }
        }).then(function () {
          return _this.get('currentUser').load();
        }).then(function () {
          _this.get('notifications').success('Another verification text message sent.');

          _this.onSwitchedToPhone();
        }).catch(function (error) {
          _this.set('errorMessage', error.payload.errors[0].detail);
        });
      },
      cancel: function cancel() {
        this.onCancel();
      },
      onSuccess: function onSuccess() {
        this.onSuccess();
      },
      resendCode: function resendCode() {
        var _this2 = this;

        var primaryContactMethodId = this.get('model.metadata.contact_method_id');
        var url = this.get('ajax.namespacedUrl') + "/contact-methods/" + primaryContactMethodId + "/resend_code";
        this.get('ajax').post(url, {}).then(function () {
          _this2.get('notifications').success('Another verification email sent.');

          _this2.onResentCode();
        }).catch(function (reason) {
          return _this2.get('notifications').error(reason);
        });
      }
    }
  });

  _exports.default = _default;
});