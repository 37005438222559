define("accio-alert/models/digest", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    sentAt: (0, _model.attr)('date'),
    queryFilters: (0, _model.attr)(),
    metrics: (0, _model.attr)()
  });

  _exports.default = _default;
});