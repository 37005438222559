define("accio-alert/models/task", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _model.attr)(),
    due_at: (0, _model.attr)('date'),
    metadata: (0, _model.attr)(),
    taskType: (0, _model.attr)(),
    taskTypeDesc: (0, _model.attr)(),
    isActive: Ember.computed.equal('status', 'active'),
    isCompleted: Ember.computed.equal('status', 'completed')
  });

  _exports.default = _default;
});