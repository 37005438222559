define("accio-alert/pods/main/onboarding/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hfvIVgF5",
    "block": "{\"symbols\":[\"task\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-6 col-lg-offset-3 col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3\"],[8],[0,\"\\n\\t\\t\"],[7,\"ul\",true],[10,\"class\",\"progress-indicator\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"li\",true],[11,\"class\",[29,[\"hidden-xs \",[28,\"if\",[[23,1,[\"isActive\"]],\"\",\"completed\"],null]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"bubble\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,1,[\"taskTypeDesc\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/onboarding/template.hbs"
    }
  });

  _exports.default = _default;
});