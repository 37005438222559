define("accio-alert/pods/public/patient-lookup/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    dateOfBirth: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      format: 'YYYY-MM-DD',
      message: 'Please enter a format of M/D/YYYY'
    })],
    phoneNumber: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'phone'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    actions: {
      lookup: function lookup() {
        var _this = this;

        this.set('errorMessage', undefined);
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this.set('didValidate', true);

          if (validations.get('isValid')) {
            var url = _this.get('ajax.namespacedUrl') + "/patient-lookup";
            return _this.get('ajax').post(url, {
              data: {
                firstName: _this.get('firstName'),
                lastName: _this.get('lastName'),
                dateOfBirth: _this.get('dateOfBirth'),
                phoneNumber: (_this.get('phoneNumber') || '').replaceAll('-', ''),
                secondFactor: _this.get('secondFactor')
              }
            });
          } else {
            return Ember.RSVP.reject({
              payload: {
                errors: [{
                  detail: ''
                }]
              }
            });
          }
        }).then(function (_ref2) {
          var status = _ref2.status,
              tempToken = _ref2.tempToken,
              requires_additional_factor = _ref2.requires_additional_factor,
              message = _ref2.message;

          if (status === 'found' && !Ember.isEmpty(tempToken)) {
            _this.transitionToRoute('main.patient', {
              queryParams: {
                token: tempToken
              }
            });
          } else if (requires_additional_factor && typeof message === 'string') {
            _this.setProperties({
              errorMessage: message,
              requiresSecondFactor: true
            });
          } else {
            _this.set('errorMessage', "We couldn't find any records that match the information you provided. <br/>Please try again.");
          }
        });
      }
    }
  });

  _exports.default = _default;
});