define("accio-alert/pods/main/onboarding/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P36B221f",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"onboarding/pdsp-task\",null,[[\"model\",\"onSkip\",\"onNext\",\"nextTask\",\"prevTask\",\"hasPrev\",\"hasNext\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"skip\"],null],[28,\"action\",[[23,0,[]],\"next\"],null],[24,[\"nextTask\"]],[24,[\"prevTask\"]],[24,[\"hasPrev\"]],[24,[\"hasNext\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"pull-left\"],[10,\"id\",\"onboarding-wizard-prev\"],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[10,\"id\",\"onboarding-wizard-next\"],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/onboarding/index/template.hbs"
    }
  });

  _exports.default = _default;
});