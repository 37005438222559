define("accio-alert/pods/mobile/vehicle-verify/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      return this.transitionTo('main.verifications.show', model.get('verification'));
    }
  });

  _exports.default = _default;
});