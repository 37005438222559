define("accio-alert/pods/components/pdsp-my-tasks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4lw+xF5",
    "block": "{\"symbols\":[\"task\"],\"statements\":[[4,\"if\",[[24,[\"activeTasks\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"panel panel-border panel-danger\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-heading text-center\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n\\t\\t\\tYou're almost done\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"activeTasks\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"pdsp-tasks/pdsp-task\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-tasks/template.hbs"
    }
  });

  _exports.default = _default;
});