define("accio-alert/pods/components/pdsp-tasks/vehicle-verification-submit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D8CCdjab",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[0,\"Verify ownership of your vehicle\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"You'll need to upload a picture of your vehicle's registration as proof of ownership, so that you can receive alerts of its whereabouts.\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-4\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-danger\",\"main.vehicles.edit.verify\",[24,[\"model\",\"metadata\",\"vehicle_id\"]]]],{\"statements\":[[0,\"Continue Enrollment >\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-tasks/vehicle-verification-submit/template.hbs"
    }
  });

  _exports.default = _default;
});