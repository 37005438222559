define("accio-alert/pods/components/pdsp-my-locations/pdsp-location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J3lZpmTB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-xs-10\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[10,\"class\",\"page-title m-t-10\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[24,[\"model\",\"nickname\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[10,\"id\",\"locationAddress\"],[8],[1,[24,[\"model\",\"street\"]],false],[0,\" \"],[1,[24,[\"model\",\"street2\"]],false],[0,\" \"],[1,[24,[\"model\",\"city\"]],false],[0,\" \"],[1,[24,[\"model\",\"province\"]],false],[0,\" \"],[1,[24,[\"model\",\"postalCode\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-4 col-xs-2 text-right\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\"],[4,\"if\",[[24,[\"canDelete\"]]],null,{\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[12,\"style\",\"font-size: 130%; padding-right: 20px;\"],[3,\"action\",[[23,0,[]],\"delete\",[24,[\"model\"]]]],[8],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-remove-sign\"],[8],[9],[9]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row hidden-xs hidden-md m-t-10\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-md-10\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"canEdit\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-default\",\"main.locations.edit.home\",[24,[\"model\"]]]],{\"statements\":[[0,\"Finish Enrollment\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-locations/pdsp-location/template.hbs"
    }
  });

  _exports.default = _default;
});