define("accio-alert/pods/main/anonymous/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gj5pNOin",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"name\",\"profile\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-4 col-lg-offset-4 m-b-20\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"card-box row text-center\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\t\\tThank you. Your appointment is scheduled.\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"m-t-20\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\",true],[10,\"class\",\"btn btn-default\"],[10,\"href\",\"/main/wf/i/schedule_appointment?team=mdh&service_areas[]=ann_state_house\"],[8],[0,\"+ New Appointment\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/anonymous/template.hbs"
    }
  });

  _exports.default = _default;
});