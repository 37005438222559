define("accio-alert/pods/main/locations/edit/select-features/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c75tBy5q",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pdsp-select-features\",null,[[\"cancelMode\",\"model\",\"onSave\"],[[24,[\"cancelMode\"]],[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"onSave\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/locations/edit/select-features/template.hbs"
    }
  });

  _exports.default = _default;
});