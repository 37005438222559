define("accio-alert/models/vehicle", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    nickname: (0, _emberCpValidations.validator)('presence', true),
    licensePlate: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[A-Za-z0-9]{2,8}$/,
      message: 'License Plates should only contain numbers and letters. If your plate has other characters like hyphens, please omit them.'
    }), (0, _emberCpValidations.validator)('ds-error', true)],
    licensePlateState: (0, _emberCpValidations.validator)('presence', true),
    make: (0, _emberCpValidations.validator)('presence', true),
    model: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    nickname: (0, _model.attr)(),
    licensePlate: (0, _model.attr)(),
    licensePlateState: (0, _model.attr)(),
    vin: (0, _model.attr)(),
    make: (0, _model.attr)(),
    model: (0, _model.attr)(),
    enabled: (0, _model.attr)('boolean'),
    verifiedAt: (0, _model.attr)('date'),
    deletedAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('utc'),
    user: (0, _model.belongsTo)('user'),
    verification: (0, _model.belongsTo)('verification'),
    licensePlateStateWithoutCountry: Ember.computed('licensePlateState', function () {
      return this.get('licensePlateState').replace('US-', '');
    }),
    flagged: Ember.computed('verification', 'verification.state', function () {
      var state = this.get('verification.state');
      return state == 'draft' || state === 'requested_more_info';
    })
  });

  _exports.default = _default;
});