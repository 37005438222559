define("accio-alert/pods/components/workflows/steps/form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = Ember.Component.extend({
    defaultValidations: {
      date: {
        format: 'YYYY-MM-DD',
        errorFormat: 'l',
        message: 'Please enter a date in the M/D/YYYY format.'
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var currentStep = this.get('model.currentStepApiName');
      var currentContext = this.get('model.context')[currentStep] || {};
      this.reopen(this.buildDynamicValidations());
      this.set('didValidate', false);
      this.set('context', currentContext);
    },
    actions: {
      cancel: function cancel() {
        if (confirm('Are you sure you want to cancel?')) {
          this.onCancel();
        }
      },
      submit: function submit() {
        Ember.run.debounce(this, this.update_submitted, 500);
      },
      updateSelection: function updateSelection(field, newVal) {
        var rawValue = newVal.hasOwnProperty('value') ? newVal.value : newVal;
        this.set("context." + field, rawValue);
      }
    },
    buildDynamicValidations: function buildDynamicValidations() {
      var _this = this;

      var validations = this.get('model.action.fields').reduce(function (obj, field) {
        var validations = field.validations || [];

        if (validations.length > 0) {
          var validatorConfig = validations.map(function (validation) {
            if (validation.type === 'required') {
              return (0, _emberCpValidations.validator)('presence', true);
            }

            if (validation.type === 'format') {
              if (validation.regex !== undefined) {
                validation.regex = new RegExp(validation.regex);
              }

              return (0, _emberCpValidations.validator)('format', _extends({}, validation, {
                type: validation.formatType
              }));
            }

            if (field.type === 'address') {
              return (0, _emberCpValidations.validator)('address', _extends({}, validation));
            }

            var defaultValidation = _this.defaultValidations[validation.type] || {};
            return (0, _emberCpValidations.validator)(validation.type, _extends({}, defaultValidation, validation));
          });
          obj["context." + field.save_to_context] = validatorConfig;
        }

        return obj;
      }, {});
      return (0, _emberCpValidations.buildValidations)(validations);
    },
    update_submitted: function update_submitted() {
      var _this2 = this;

      this.validate().then(function (_ref) {
        var validations = _ref.validations;

        _this2.set('didValidate', true);

        if (validations.get('isValid')) {
          _this2.onResponse(_this2.get('context'));
        } else {
          return Ember.RSVP.reject();
        }
      });
    }
  });

  _exports.default = _default;
});