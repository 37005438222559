define("accio-alert/pods/main/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('subscriptionOrdering', ['campaign.name', 'notification.name']);
    },
    flaggedVehicles: Ember.computed.filterBy('model.vehicles', 'flagged', true),
    flaggedVehiclesPresent: Ember.computed.notEmpty('flaggedVehicles'),
    flaggedContactMethods: Ember.computed.filterBy('model.contactMethods', 'flagged', true),
    flaggedContactMethodsPresent: Ember.computed.notEmpty('flaggedContactMethods'),
    anyFlaggedItems: Ember.computed.or('flaggedVehiclesPresent', 'flaggedContactMethodsPresent'),
    orderedSubscriptions: Ember.computed.sort('model.subscriptions', 'subscriptionOrdering'),
    leafCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'leaf'),
    streetSweepingCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'street_sweeping'),
    location: Ember.computed.alias('model.locations.firstObject'),
    campaigns: Ember.computed(function () {
      return this.store.findAll('campaign');
    }),
    holidayGreeneryCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'holiday_greenery'),
    withinHGSeason: Ember.computed(function () {
      var date = new Date();
      return date >= moment('2019-12-26').toDate() && date <= moment('2020-01-31').toDate();
    }),
    actions: {
      onAddLocation: function onAddLocation() {
        this.transitionToRoute('main.locations.new.home');
      },
      deleteItem: function deleteItem(item) {
        item.destroyRecord();
      },
      configureLocation: function configureLocation(location, locationSubscription) {
        this.transitionToRoute('main.locations.edit.select_features', location, locationSubscription);
      }
    }
  });

  _exports.default = _default;
});