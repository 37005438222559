define("accio-alert/pods/main/vehicles/edit/verify/controller", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.commentsSortDef = ['id'];
    },
    fileComments: Ember.computed.filterBy('model.comments', 'commentType', 'file'),
    orderedComments: Ember.computed.sort('fileComments', 'commentsSortDef'),
    registrationImagesNotUploaded: Ember.computed.not('registrationImagesUploaded'),
    registrationImagesUploaded: Ember.computed('model.comments.@each.fileLocation', function () {
      return !this.get('model.comments').isAny('fileLocation', null);
    }),
    actions: {
      cancel: function cancel() {
        if (this.nativeApp.isNativeApp) {
          this.nativeApp.postMessage({
            action: 'vehicles.refresh'
          });
          this.nativeApp.transitionToNativeRoute('vehicles');
        } else {
          this.transitionTo('home');
        }
      },
      submit: function submit() {
        Ember.run.throttle(this, this._doSubmit, 5000);
      }
    },
    _doSubmit: function _doSubmit() {
      var _this = this;

      var url = _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/verifications/" + this.get('model.id') + "/submit";
      this.get('ajax').post(url, {}).then(function () {
        return _this.get('model').reload();
      }).then(function () {
        return _this.transitionToRoute('main.vehicles.edit.finish');
      }).then(function () {
        if (_this.nativeApp.isNativeApp) {
          _this.nativeApp.postMessage({
            action: 'vehicles.refresh'
          });
        }
      }).catch(function (reason) {
        console.error(reason);
      });
    }
  });

  _exports.default = _default;
});