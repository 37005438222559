define("accio-alert/mixins/metrics-retrieval", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['selectedDuration'],
    ajax: Ember.inject.service(),
    url: _environment.default.apiURL + "/" + _environment.default.adminApiNamespace + "/reporting/metrics",
    selectedDuration: 'Today',
    refreshMetrics: function refreshMetrics() {
      var _this = this;

      this.get('ajax').request(this.url, {
        data: {
          metrics: this.metrics,
          start_date: this.get('startDate').toISOString(),
          end_date: this.get('endDate').toISOString()
        }
      }).then(function (data) {
        return _this.set('calculatedMetrics', data);
      });
    },
    actions: {
      onDurationChanged: function onDurationChanged(newDuration, newStartDate, newEndDate) {
        this.setProperties({
          startDate: newStartDate,
          endDate: newEndDate,
          selectedDuration: newDuration
        });
        Ember.run.once(this, this.refreshMetrics);
      }
    }
  });

  _exports.default = _default;
});