define("accio-alert/validators/end-today-or-tomorrow", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var EndTodayOrTomorrow = _base.default.extend({
    validate: function validate(value, options, model) {
      // console.log('EndTodayOrTomorrow', value, options, model.get('endAtInThePast'));
      if (model.get('endAtInThePast')) {
        return true;
      }

      if (value === undefined) {
        return true;
      }

      var currTime = moment().format('kkmm');
      var boundary = options.boundary || '1845';
      var dateValue = moment(value);
      var minTime = moment().endOf('day'); // console.log(currTime, boundary);

      if (currTime >= boundary) {
        minTime = minTime.add(1, 'day');
      } // console.log(dateValue, minTime, dateValue.isBefore(minTime, 'second'));


      if (dateValue.isBefore(minTime, 'second')) {
        return "Please select a date on or after " + minTime.format('ll');
      } else {
        return true;
      }
    }
  });

  EndTodayOrTomorrow.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor()
    /* attribute, options */
    {
      return ['actions'];
    }
  });
  var _default = EndTodayOrTomorrow;
  _exports.default = _default;
});