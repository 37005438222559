define("accio-alert/pods/mobile/faq/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    accountLevelFaqs: Ember.computed(function () {
      return this.get('store').query('frequently-asked-question', {
        campaignId: -1
      });
    })
  });

  _exports.default = _default;
});