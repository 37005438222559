define("accio-alert/pods/components/onboarding/location/add/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    actions: {
      skip: function skip() {
        this.onSkip();
      }
    }
  });

  _exports.default = _default;
});