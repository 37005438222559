define("accio-alert/pods/components/pdsp-my-contact-methods/pdsp-contact-method/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['row'],
    actions: {
      onVerified: function onVerified() {
        this.get('currentUser.user.subscriptions').reload();
      },
      delete: function _delete(model) {
        var _this = this;

        var medium = model.get('medium');
        var mediumCap = medium;

        if (medium === 'ios-apns2' || medium === 'android-fcm') {
          medium = 'device';
          mediumCap = 'Device';
        }

        if (!confirm("Are you sure you want to delete this " + medium + "?")) {
          return;
        }

        this.get('model').destroyRecord().then(function () {
          _this.get('notifications').success(mediumCap + " successfully deleted", {
            cssClasses: 'medium-deleted-notification'
          });

          _this.get('currentUser.user.subscriptions').reload();
        });
      }
    }
  });

  _exports.default = _default;
});