define("accio-alert/pods/components/pdsp-service-annotations/date-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    startExists: Ember.computed.notEmpty('model.startAt'),
    startDoesNotExist: Ember.computed.empty('model.startAt'),
    endExists: Ember.computed.notEmpty('model.endAt'),
    endDoesNotExist: Ember.computed.empty('model.endAt'),
    startOnly: Ember.computed.and('startExists', 'endDoesNotExist'),
    endOnly: Ember.computed.and('Exists', 'startDoesNotExist'),
    bothExist: Ember.computed.and('startExists', 'endExists'),
    neitherExist: Ember.computed.and('startDoesNotExist', 'endDoesNotExist')
  });

  _exports.default = _default;
});