define("accio-alert/pods/main/subscriptions/location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HqfpLKeu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-6 col-lg-offset-3\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"pdsp-select-features\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/subscriptions/location/template.hbs"
    }
  });

  _exports.default = _default;
});