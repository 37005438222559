define("accio-alert/pods/components/pdsp-subscription-location/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'li',
    checked: Ember.computed('subscription.locations', function () {
      var locationIds = this.get('subscription.locations').map(function (l) {
        return l.get('id');
      });
      var location = this.get('location');
      return Ember.isPresent(locationIds) && locationIds.indexOf(location.get('id')) > -1;
    }),
    actions: {
      toggleField: function toggleField() {
        this.toggleProperty('checked');

        if (this.get('checked')) {
          this.get('subscription.locations').pushObject(this.get('location'));
        } else {
          this.get('subscription.locations').removeObject(this.get('location'));
        }
      }
    }
  });

  _exports.default = _default;
});