define("accio-alert/serializers/reporting", ["exports", "accio-alert/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    normalizeResponse: function normalizeResponse(_store, primaryModelClass, payload) {
      var camelizedColumns = payload.columns.map(Ember.String.camelize);
      var data = payload.data.map(function (row) {
        var attrs = row.reduce(function (model, value, index) {
          var fieldName = camelizedColumns[index];
          model[fieldName] = value;
          return model;
        }, {});
        return {
          id: attrs.id,
          type: primaryModelClass.modelName,
          attributes: attrs
        };
      });
      return {
        data: data,
        meta: {
          total: payload.recordsTotal
        }
      };
    }
  });

  _exports.default = _default;
});