define("accio-alert/services/ajax", ["exports", "ember-ajax/services/ajax", "accio-alert/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    contentType: 'application/json; charset=utf-8',
    init: function init() {
      this._super.apply(this, arguments);

      this.trustedHosts = [_environment.default.apiHost];
    },
    headers: Ember.computed('session.isAuthenticated', function () {
      var _this$get = this.get('session.data.authenticated'),
          token = _this$get.token;

      var headers = {};

      if (Ember.isPresent(token)) {
        headers.Authorization = "Bearer " + token;
      }

      if (Ember.isPresent(_environment.default.subdomain)) {
        headers['X-Account-Subdomain'] = _environment.default.subdomain;
      }

      return headers;
    }),
    autoNamespacedUrl: Ember.computed('session.isAuthenticated', 'currentUser.user', function () {
      var user = this.get('currentUser.user');

      if (!this.get('session.isAuthenticated') || user.get('isPublicUser') || user.get('isPatient') || user.get('isNoLogin')) {
        return _environment.default.apiURL + "/" + _environment.default.apiNamespace;
      } else {
        return _environment.default.apiURL + "/" + _environment.default.adminApiNamespace;
      }
    }),
    namespacedUrl: Ember.computed(function () {
      return _environment.default.apiURL + "/" + _environment.default.apiNamespace;
    }),
    namespacedAdminUrl: Ember.computed(function () {
      return _environment.default.apiURL + "/" + _environment.default.adminApiNamespace;
    })
  });

  _exports.default = _default;
});