define("accio-alert/services/issue", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    processAction: function processAction(issue, action) {
      var _this = this;

      var url = _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/issues/" + issue.get('id') + "/process-action";
      return this.get('ajax').post(url, {
        data: {
          workflow_action: action
        }
      }).then(function (data) {
        return _this.store.pushPayload(data);
      });
    }
  });

  _exports.default = _default;
});