define("accio-alert/pods/components/pdsp-my-contact-methods/component", ["exports", "accio-alert/mixins/contact-limits"], function (_exports, _contactLimits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_contactLimits.default, {
    nativeApp: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    classNames: ['card-box']
  });

  _exports.default = _default;
});