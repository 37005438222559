define("accio-alert/pods/public/faq/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgAFZkn4",
    "block": "{\"symbols\":[\"campaign\",\"faq\"],\"statements\":[[4,\"each\",[[24,[\"accountLevelFaqs\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"row faq\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[8],[1,[23,2,[\"question\"]],true],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[1,[23,2,[\"answer\"]],true],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"unless\",[[28,\"eq\",[[23,1,[\"apiName\"]],\"account\"],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"li\",true],[8],[7,\"h4\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"public.faq.campaign\",[23,1,[]]]],{\"statements\":[[1,[23,1,[\"name\"]],false],[0,\" FAQs\"]],\"parameters\":[]},null],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/faq/index/template.hbs"
    }
  });

  _exports.default = _default;
});