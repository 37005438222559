define("accio-alert/pods/components/pdsp-comment-file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ld+UFlPL",
    "block": "{\"symbols\":[\"dropzone\",\"queue\"],\"statements\":[[7,\"h5\",true],[8],[1,[24,[\"comment\",\"verificationDocumentType\",\"name\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"comment\",\"fileLocation\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"comment\",\"preSignedUrl\"]]]]],[10,\"style\",\"max-width: 95%;\"],[10,\"class\",\"m-b-10\"],[8],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"clearPhoto\"]],[8],[0,\"Change Uploaded Photo\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"file-dropzone\",null,[[\"name\"],[[24,[\"dropZoneName\"]]]],{\"statements\":[[4,\"if\",[[23,1,[\"active\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"valid\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"\"],[10,\"style\",\"height: 60px; border: 3px dashed #ccc\"],[8],[0,\"\\n        Drop to upload\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Invalid\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"style\",\"height: 60px\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"uploading\"]]],null,{\"statements\":[[0,\"          Uploading...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"file-upload\",null,[[\"name\",\"accept\",\"capture\",\"onfileadd\"],[[24,[\"dropZoneName\"]],\"image/*\",\"camera\",[28,\"action\",[[23,0,[]],\"uploadImage\",[23,2,[]]],null]]],{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"id\",\"upload-image\"],[10,\"tabindex\",\"0\"],[10,\"class\",\"btn btn-default\"],[8],[1,[22,\"buttonCaption\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-comment-file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});