define("accio-alert/models/contact-method", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    medium: (0, _emberCpValidations.validator)('presence', true),
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.equal('model.medium', 'sms')
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      disabled: Ember.computed.equal('model.medium', 'sms')
    }), (0, _emberCpValidations.validator)('ds-error', {
      disabled: Ember.computed.equal('model.medium', 'sms')
    })],
    confirmEmail: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.equal('model.medium', 'sms')
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'email',
      message: '{description} do not match',
      description: 'Email addresses',
      disabled: Ember.computed.equal('model.medium', 'sms')
    })],
    phoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.equal('model.medium', 'email')
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[0-9]{10}$/,
      disabled: Ember.computed.equal('model.medium', 'email'),
      message: 'Invalid phone number. See instructions below.'
    }), (0, _emberCpValidations.validator)('ds-error', {
      disabled: Ember.computed.equal('model.medium', 'email')
    })],
    confirmPhoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.equal('model.medium', 'email')
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'phoneNumber',
      message: '{description} do not match',
      description: 'Phone Numbers',
      disabled: Ember.computed.equal('model.medium', 'email')
    })]
  });

  var _default = _model.default.extend(Validations, {
    state: (0, _model.attr)(),
    medium: (0, _model.attr)(),
    email: (0, _model.attr)(),
    phoneNumber: (0, _model.attr)(),
    deviceName: (0, _model.attr)(),
    enabled: (0, _model.attr)('boolean'),
    verifiedAt: (0, _model.attr)('date'),
    insertedAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    isPrimary: (0, _model.attr)(),
    verified: Ember.computed.equal('state', 'verified'),
    flagged: Ember.computed.not('verified'),
    display: Ember.computed('medium', 'email', 'phoneNumber', function () {
      switch (this.medium) {
        case 'sms':
          return this.phoneNumber;

        case 'email':
          return this.email;

        case 'ios-apns2':
        case 'android-fcm':
          return this.deviceName;
      }
    }),
    address: Ember.computed('medium', 'email', 'phoneNumber', function () {
      return this.get('medium') === 'email' ? this.get('email') : this.get('phoneNumber');
    })
  });

  _exports.default = _default;
});