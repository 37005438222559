define("accio-alert/mixins/sorted-filtered-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sortProperties: ['name'],
    searchKey: 'name',
    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    filter: null,
    sortedFilteredModel: Ember.computed('filter', 'sortedModel', function () {
      var filter = this.get('filter');
      var sortedModel = this.get('sortedModel');

      if (Ember.isEmpty(this.get('filter'))) {
        return sortedModel;
      }

      var searchKey = this.get('searchKey');
      return sortedModel.filter(function (item) {
        return item.get(searchKey).toLowerCase().indexOf(filter.toLowerCase()) > -1;
      });
    })
  });

  _exports.default = _default;
});