define("accio-alert/pods/mobile/conversation/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XTeXLRcu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container m-t-10\"],[10,\"style\",\"font-size: 125%;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-lg-6 col-lg-offset-3\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"text-center p-t-10 p-b-10\"],[10,\"style\",\"background-color: white\"],[8],[0,\"\\n                \"],[7,\"img\",true],[11,\"src\",[29,[[28,\"asset-map\",[[24,[\"currentAccount\",\"account\",\"themeLogoUrl\"]]],null]]]],[10,\"style\",\"max-width: 150px\"],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-lg-6 col-lg-offset-3\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"portlet\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"portlet-heading bg-inverse text-center\"],[8],[0,\"\\n                    \"],[7,\"h3\",true],[10,\"class\",\"portlet-title\"],[10,\"style\",\"float:none\"],[8],[0,\"\\n                        Tell us how are you're feeling\\n                    \"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"id\",\"bg-inverse\"],[10,\"class\",\"clearfix m-b-20\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"portlet-body\"],[8],[0,\"\\n                        \"],[5,\"conversations/fragment\",[],[[\"@onResponse\",\"@model\"],[[28,\"action\",[[23,0,[]],\"onResponse\"],null],[24,[\"model\",\"currentFragment\"]]]]],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/conversation/show/template.hbs"
    }
  });

  _exports.default = _default;
});