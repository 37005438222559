define("accio-alert/pods/components/pdsp-verification-vehicle-info/component", ["exports", "accio-alert/mixins/make-model-cascade"], function (_exports, _makeModelCascade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_makeModelCascade.default, {
    classNames: ['panel', 'panel-default']
  });

  _exports.default = _default;
});