define("accio-alert/pods/components/pdsp-my-alerts/pdsp-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/YMHQ9h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-lg-3\"],[8],[0,\"\\n\\t\"],[1,[28,\"moment-format\",[[24,[\"model\",\"sendAfter\"]],\"lll\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-lg-7\"],[8],[0,\"\\n\\t\"],[1,[24,[\"model\",\"subject\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-lg-2\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",\"btn-xs btn-default\"],[3,\"action\",[[23,0,[]],\"dismiss\"]],[8],[0,\"Dismiss\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-alerts/pdsp-alert/template.hbs"
    }
  });

  _exports.default = _default;
});