define("accio-alert/pods/token-auth/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var qp = transition.to.queryParams;
      var token = qp.token || qp.t;
      var authenticator = 'authenticator:jwt';
      console.log('original attempted', this.get('session.attemptedTransition'));
      console.log('token', token);
      return this.get('session').authenticate(authenticator, {
        token: token
      });
    }
  });

  _exports.default = _default;
});