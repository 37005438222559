define("accio-alert/models/season", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    apiName: (0, _model.attr)('string'),
    startAt: (0, _model.attr)('date'),
    endAt: (0, _model.attr)('date'),
    campaign: (0, _model.belongsTo)('campaign', {
      inverse: 'seasons'
    }),
    serviceAreas: (0, _model.hasMany)('service-area')
  });

  _exports.default = _default;
});