define("accio-alert/pods/mobile/subscriptions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rXr05R9y",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pdsp-my-subscriptions\",null,[[\"model\"],[[24,[\"currentUser\",\"user\",\"subscriptions\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/subscriptions/template.hbs"
    }
  });

  _exports.default = _default;
});