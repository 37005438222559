define("accio-alert/pods/components/pdsp-my-account/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    classNames: ['card-box']
  });

  _exports.default = _default;
});