define("accio-alert/pods/components/pdsp-subscription-vehicles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htakRjNy",
    "block": "{\"symbols\":[\"vehicle\"],\"statements\":[[4,\"each\",[[24,[\"approvedVehicles\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"pdsp-subscription-vehicle\",null,[[\"vehicle\",\"model\",\"onVehicleAdded\",\"onVehicleDeleted\"],[[23,1,[]],[24,[\"model\"]],\"addVehicle\",\"deleteVehicle\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-subscription-vehicles/template.hbs"
    }
  });

  _exports.default = _default;
});