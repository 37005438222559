define("accio-alert/pods/components/pdsp-customer-profile-welcome/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    welcomeVisible: true,
    classNameBindings: ['welcomeVisible:row'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isPresent(localStorage.welcomeVisible)) {
        this.set('welcomeVisible', localStorage.welcomeVisible === 'true');
      }
    },
    actions: {
      setWelcomeVisible: function setWelcomeVisible(val) {
        this.set('welcomeVisible', val);
        localStorage.welcomeVisible = val;
      }
    }
  });

  _exports.default = _default;
});