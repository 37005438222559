define("accio-alert/pods/main/workflows/initialize/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    queryParams: {
      team: {
        refreshModel: true
      },
      service_areas: {
        refreshModel: true
      },
      parent_workflow_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      this._super.apply(this, arguments);

      return this.store.createRecord('workflow', {
        apiName: params.api_name,
        context: params
      }).save().then(function (record) {
        return _this.transitionTo('main.workflows.execute', record);
      });
    }
  });

  _exports.default = _default;
});