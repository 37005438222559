define("accio-alert/pods/public/link/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var qp = transition.to.queryParams;
      var token = qp.token || qp.t;
      localStorage.put('attemptedLoginToken', token);

      if (this.get('session.isAuthenticated')) {
        this.get('session.invalidate');
      }

      this.set('session.attemptedTransition', transition);
      console.log('link beforeModel');
      console.log(transition);
      console.log(token);
      console.log('isAuthenticated = ', this.get('session.isAuthenticated'));
      console.log('original attempted', this.get('session.attemptedTransition'));
      var authenticator = 'authenticator:jwt';
      return this.get('session').authenticate(authenticator, {
        token: token
      }); // .then(() => {
      // 	const attemptedTransition = this.get('session.attemptedTransition');
      // 	console.log(attemptedTransition);
      // 	if (attemptedTransition) {
      // 		attemptedTransition.retry();
      // 	} else {
      // 		this.transitionTo('mobile');
      // 	}
      // })
    }
  });

  _exports.default = _default;
});