define("accio-alert/pods/main/vehicles/form/home/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    actions: {
      onValidationFailed: function onValidationFailed() {
        window.scrollTo(0, 0);
        this.get('notifications').error('Please fix validation errors first');
      },
      onSave: function onSave(model) {
        if (this.get('nativeApp.isNativeApp')) {
          this.get('nativeApp').postMessage({
            action: 'vehicles.refresh'
          });
        }

        this.transitionToRoute('main.vehicles.edit.select_programs', model);
      },
      onCancel: function onCancel() {
        if (this.get('nativeApp.isNativeApp') && this.currentUser.user.onboarded) {
          this.transitionToRoute('mobile.vehicles');
        } else {
          this.transitionToRoute('home');
        }
      }
    }
  });

  _exports.default = _default;
});