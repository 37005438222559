define("accio-alert/pods/main/locations/edit/finish/route", ["exports", "accio-alert/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    model: function model() {
      return this.store.query('subscription-location', {
        location_id: this.modelFor('main.locations.edit').get('id')
      });
    }
  });

  _exports.default = _default;
});