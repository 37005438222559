define("accio-alert/pods/public/register/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    queryParams: {
      team: {
        refreshModel: true
      },
      sa: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        // if they're a patient, add the registered team to their profile
        if (this.get('currentUser.user.isPatient')) {
          sessionStorage.setItem('registerToTeam', transition.to.queryParams.team);
        }

        this.get('session').invalidate(); // this.get('notifications').error('Please logout before trying to register another user');
      }

      this._super.apply(this, arguments);
    },
    model: function model(params) {
      // eslint-disable-next-line no-unused-vars
      var randomNumber = Math.floor(Math.random(3) * 1000 + 1);
      return this.store.createRecord('user', {
        groupType: 'ind',
        usernameType: 'email',
        // this.nativeApp.isNativeApp ? 'phone' : 'email',
        team: params.team || sessionStorage.getItem('registerToTeam')
      });
    }
  });

  _exports.default = _default;
});