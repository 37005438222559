define("accio-alert/models/location", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    nickname: (0, _emberCpValidations.validator)('presence', true),
    address: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    nickname: (0, _model.attr)(),
    locationType: (0, _model.attr)(),
    isPrimary: (0, _model.attr)('boolean'),
    street: (0, _model.attr)(),
    city: (0, _model.attr)(),
    province: (0, _model.attr)(),
    postalCode: (0, _model.attr)(),
    formattedAddress: (0, _model.attr)(),
    address: (0, _model.attr)(),
    longitude: (0, _model.attr)('number'),
    latitude: (0, _model.attr)('number'),
    xCoord: (0, _model.attr)('number'),
    yCoord: (0, _model.attr)('number'),
    attributes: (0, _model.attr)(),
    user: (0, _model.belongsTo)('user')
  });

  _exports.default = _default;
});