define("accio-alert/pods/components/workflows/steps/conversation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var conversation_id = this.model.get("context." + this.model.get('currentStepApiName') + ".conversation_id");
      this.store.findRecord('conversation', conversation_id).then(function (c) {
        if (c.status === 'completed') {
          _this.model.reload();
        } else {
          _this.set('conversation', c);
        }
      });
    },
    actions: {
      triggerResponse: function triggerResponse(input) {
        var _this2 = this;

        var url = this.get('ajax.autoNamespacedUrl') + "/conversations/" + this.conversation.id + "/input";
        var data = {
          input: input
        };
        this.get('ajax').post(url, {
          data: data
        }).then(function (response) {
          _this2.store.pushPayload(response);

          if (_this2.get('conversation.status') === 'completed') {
            _this2.onConversationCompleted();
          }
        });
      },
      submit: function submit() {
        this.onResponse(this.get('context'));
      },
      updateSelection: function updateSelection(field, newVal) {
        this.set("context." + field, newVal);
      }
    }
  });

  _exports.default = _default;
});