define("accio-alert/pods/public/widgets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AqReV0N9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"m-t-20 text-center\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"style\",\"width: 120px\"],[11,\"src\",[29,[[28,\"asset-map\",[[24,[\"currentAccount\",\"account\",\"themeLogoUrl\"]]],null]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container m-t-20\"],[8],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/widgets/template.hbs"
    }
  });

  _exports.default = _default;
});