define("accio-alert/pods/components/pdsp-tasks/vehicle-verification-respond/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tgGOTrkC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[0,\"Respond to our Request for More Information\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"We've requested additional information from you to process your vehicle.\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-4\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-danger\",\"main.verifications.show\",[24,[\"model\",\"metadata\",\"verification_id\"]]]],{\"statements\":[[0,\"Respond to Request >\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-tasks/vehicle-verification-respond/template.hbs"
    }
  });

  _exports.default = _default;
});