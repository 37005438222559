define("accio-alert/pods/main/onboarding/first/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var firstIncompleteIndex = this.modelFor('main.onboarding').sortBy('due_at').findIndex(function (t) {
        return t.get('isActive');
      });

      if (firstIncompleteIndex !== -1) {
        this.transitionTo('main.onboarding.index', firstIncompleteIndex);
      } else {
        this.transitionTo('home');
      }
    }
  });

  _exports.default = _default;
});