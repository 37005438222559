define("accio-alert/pods/components/pdsp-subscription-contact-methods/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'ul',
    classNames: 'list-unstyled',
    contactMethods: Ember.computed(function () {
      return this.get('store').findAll('contact-method');
    }),
    actions: {
      addContactMethod: function addContactMethod(contactMethodId) {
        var contactMethodIds = this.get('model.contactMethodIds') || Ember.A();
        contactMethodIds.pushObject(contactMethodId);
        this.set('model.contactMethodIds', contactMethodIds);
      },
      deleteContactMethod: function deleteContactMethod(contactMethodId) {
        this.get('model.contactMethodIds').removeObject(contactMethodId);
      }
    }
  });

  _exports.default = _default;
});