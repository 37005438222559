define("accio-alert/transforms/date-only", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    serialize: function serialize(value) {
      return value ? value.toJSON() : null;
    },
    deserialize: function deserialize(value) {
      return moment(value, 'YYYY-MM-DD').tz('America/New_York').toDate();
    }
  });

  _exports.default = _default;
});