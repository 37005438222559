define("accio-alert/validators/null-or-after-start-date", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var NullOrAfterStartDate = _base.default.extend({
    validate: function validate(value, options, model) {
      if (value === null) {
        return true;
      }

      if (value < model.get('startAt')) {
        return 'Please select an end date on or after the start date';
      } else {
        return true;
      }
    }
  });

  NullOrAfterStartDate.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor()
    /* attribute, options */
    {
      return ['actions'];
    }
  });
  var _default = NullOrAfterStartDate;
  _exports.default = _default;
});