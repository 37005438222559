define("accio-alert/mixins/make-model-cascade", ["exports", "accio-alert/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notListed: Ember.computed.equal('model.make', 'Not Listed'),
    notListedMake: {
      id: 'not_listed',
      name: 'Not Listed'
    },
    notListedModel: {
      id: 'not_listed',
      name: 'Not Listed'
    },
    statesData: [],
    selectedState: Ember.computed('statesData', 'model', 'model.licensePlateState', function () {
      if (Ember.isEmpty(this.get('statesData')) || Ember.isEmpty(this.get('model')) || Ember.isEmpty(this.get('model.licensePlateState'))) {
        return null;
      }

      var licensePlateState = this.get('model.licensePlateState');
      var states = this.get('statesData');
      var matched = states.filter(function (item) {
        return item.id === licensePlateState;
      });
      return matched[0];
    }),
    selectedMake: Ember.computed('model', 'model.make', 'makes', function () {
      if (Ember.isEmpty(this.get('makes')) || Ember.isEmpty(this.get('model')) || Ember.isEmpty(this.get('model.make'))) {
        return null;
      }

      var make = this.get('model.make');
      var makes = this.get('makes');
      return makes.filter(function (item) {
        return item.name === make;
      })[0];
    }),
    selectedModel: Ember.computed('model', 'matchedModels', 'selectedMake', 'model.model', function () {
      if (Ember.isEmpty(this.get('model')) || Ember.isEmpty(this.get('selectedMake')) || Ember.isEmpty(this.get('matchedModels')) || Ember.isEmpty(this.get('model.model'))) {
        return null;
      }

      var localModel = this.get('model.model');
      var models = this.get('matchedModels');
      return models.filter(function (item) {
        return item.name === localModel;
      })[0];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.RSVP.all([this._getEdmundsData(), this._getStatesData()]).then(function (_ref) {
        var edmundsData = _ref[0],
            statesData = _ref[1];

        _this.set('edmundsData', edmundsData);

        _this.set('statesData', statesData);
      });
    },
    reset: function reset() {
      this.setProperties({
        didValidate: false
      });
    },
    makes: Ember.computed('edmundsData', function () {
      if (Ember.isEmpty(this.get('edmundsData'))) {
        return [];
      }

      return this.get('edmundsData').makes.map(function (item) {
        return {
          id: item.niceName,
          name: item.name
        };
      });
    }),
    matchedModels: Ember.computed('selectedMake', function () {
      var selectedMake = this.get('selectedMake');

      if (Ember.isEmpty(selectedMake)) {
        return [];
      }

      var matchedMakes = this.get('edmundsData').makes.filter(function (item) {
        return item.niceName === selectedMake.id;
      });
      return matchedMakes[0].models.map(function (item) {
        return {
          id: item.id,
          name: item.name
        };
      });
    }),
    _getEdmundsData: function _getEdmundsData() {
      return _jquery.default.get(_environment.default.cdnURL + "/assets/data/make_model.json");
    },
    _getStatesData: function _getStatesData() {
      return _jquery.default.get(_environment.default.cdnURL + "/assets/data/states.json");
    },
    actions: {
      updateNotListed: function updateNotListed() {
        this.toggleProperty('notListed');

        if (this.get('notListed') === true) {
          this.set('selectedMake', this.get('notListedMake'));
          this.set('model.make', 'Not Listed');
          this.set('selectedModel', this.get('notListedModel'));
          this.set('model.model', 'Not Listed');
        }
      },
      updateMake: function updateMake(item) {
        this.set('selectedMake', item);
        this.set('model.make', item.name);
        this.set('model.model', null);
        this.set('selectedModel', null);
      },
      updateModel: function updateModel(item) {
        this.set('selectedModel', item);
        this.set('model.model', item.name);
      },
      updateState: function updateState(item) {
        this.set('selectedState', item);
        this.set('model.licensePlateState', item.id);
      }
    }
  });

  _exports.default = _default;
});