define("accio-alert/validators/start-today-or-tomorrow", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var StartTodayOrTomorrow = _base.default.extend({
    validate: function validate(value, options) {
      if (value === undefined) {
        return true;
      }

      var currTime = moment().format('kkmm');
      var boundary = options.boundary || '1845';
      var dateValue = moment(value);
      var minTime = moment().startOf('day').add(1, 'day');

      if (currTime >= boundary) {
        minTime = minTime.add(1, 'day');
      }

      if (dateValue < minTime) {
        return "Please select a date on or after " + minTime.format('ll');
      } else {
        return true;
      }
    }
  });

  StartTodayOrTomorrow.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor()
    /* attribute, options */
    {
      return ['actions'];
    }
  });
  var _default = StartTodayOrTomorrow;
  _exports.default = _default;
});