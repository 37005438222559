define("accio-alert/pods/mobile/schedules/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    nativeApp: Ember.inject.service(),
    store: Ember.inject.service(),
    leafCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'leaf'),
    streetSweepingCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'street_sweeping'),
    campaigns: Ember.computed(function () {
      return this.store.findAll('campaign');
    }),
    holidayGreeneryCampaigns: Ember.computed.filterBy('campaigns', 'apiName', 'holiday_greenery'),
    withinHGSeason: Ember.computed(function () {
      var date = new Date();
      return date >= moment('2019-12-26').toDate() && date <= moment('2020-01-31').toDate();
    }),
    actions: {
      onAddLocation: function onAddLocation() {
        this.nativeApp.transitionToNativeRoute('locations');
      }
    }
  });

  _exports.default = _default;
});