define("accio-alert/pods/enforcement/message/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      this.set('topic', params.topic);
      this.set('messageType', params.message_type);
    },
    setupController: function setupController(controller) {
      controller.set('topic', this.get('topic'));
      controller.set('messageType', this.get('messageType'));
    }
  });

  _exports.default = _default;
});