define("accio-alert/pods/main/issues/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c6gL41jO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-10 col-lg-offset-1\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"m-t-10\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default\",\"main.patient\"]],{\"statements\":[[0,\"        < Back\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[1,[28,\"issues/issue-detail\",null,[[\"componentName\",\"model\"],[\"issues/test-result-delivery-public/detail\",[24,[\"model\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/issues/show/template.hbs"
    }
  });

  _exports.default = _default;
});