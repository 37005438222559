define("accio-alert/pods/components/onboarding/vehicle/add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4JpmM3uI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"text-center text-custom\"],[8],[0,\"Vehicles\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"intro-slide-image\"],[8],[0,\"\\n\"],[7,\"img\",true],[10,\"src\",\"/assets/images/vehicle.png\"],[10,\"class\",\"intro-slide-image\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\"],[7,\"h3\",true],[8],[0,\"Add vehicles to get information personalized to your vehicle, like to get an Alert if your car is towed by \"],[1,[24,[\"currentAccount\",\"account\",\"accountDisplayName\"]],false],[0,\".\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-center m-t-20\"],[8],[0,\"\\n\"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"skip\"]],[8],[0,\"skip\"],[9],[0,\"\\n\\tor \\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default btn-lg\",\"main.vehicles.new.home\"]],{\"statements\":[[0,\"Continue\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/onboarding/vehicle/add/template.hbs"
    }
  });

  _exports.default = _default;
});