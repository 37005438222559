define("accio-alert/adapters/scheduled-alert", ["exports", "accio-alert/pods/application/adapter", "accio-alert/config/environment"], function (_exports, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    session: Ember.inject.service(),
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return _environment.default.apiURL + "/" + _environment.default.adminApiNamespace + "/campaigns/" + snapshot.record.campaign.get('id') + "/scheduled-alerts";
    }
  });

  _exports.default = _default;
});