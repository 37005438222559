define("accio-alert/pods/main/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return this.get('currentUser.user');
    }
  });

  _exports.default = _default;
});