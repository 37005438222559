define("accio-alert/models/issue", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    dueAt: (0, _model.attr)('date'),
    insertedAt: (0, _model.attr)('utc'),
    updatedAt: (0, _model.attr)('utc'),
    metadata: (0, _model.attr)(),
    displayData: (0, _model.attr)(),
    issueType: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    comments: (0, _model.hasMany)('issue-comment'),
    events: (0, _model.hasMany)('issue-event'),
    queue: (0, _model.belongsTo)('issue-queue'),
    person: (0, _model.belongsTo)('user', {
      inverse: 'issues'
    }),
    assignee: (0, _model.belongsTo)('user'),
    serviceArea: (0, _model.belongsTo)('service-area'),
    parent: (0, _model.belongsTo)('issue'),
    children: (0, _model.hasMany)('issue', {
      inverse: 'parent'
    }),
    workflows: (0, _model.hasMany)('workflow')
  });

  _exports.default = _default;
});