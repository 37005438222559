define("accio-alert/pods/mobile/messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQ4pGgDw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"native-app-poster\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[10,\"style\",\"background-color: #21457A; padding: 15px; margin-top: -10px\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"style\",\"color: white\"],[8],[1,[24,[\"model\",\"subject\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-12\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"htmlBody\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"card-box\"],[10,\"style\",\"font-size: 120%\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-center m-b-20\"],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[29,[[28,\"asset-map\",[[24,[\"currentAccount\",\"account\",\"themeLogoUrl\"]]],null]]]],[10,\"style\",\"width: 150px;\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[1,[24,[\"model\",\"htmlBody\"]],true],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"card-box\"],[10,\"style\",\"font-size: 120%\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-center m-b-20\"],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[29,[[28,\"asset-map\",[[24,[\"currentAccount\",\"account\",\"themeLogoUrl\"]]],null]]]],[10,\"style\",\"width: 150px;\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[1,[22,\"formattedPlainText\"],true],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"script\",true],[10,\"type\",\"text/javascript\"],[8],[0,\"\\ndocument\\n  .querySelectorAll('.remove-mobile-native')\\n  .forEach((n) => n.remove())\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/messages/template.hbs"
    }
  });

  _exports.default = _default;
});