define("accio-alert/pods/components/pdsp-enforcement-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HvjwGrwE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"models\"],[[24,[\"buttonClasses\"]],\"enforcement.message\",[28,\"array\",[[24,[\"model\",\"notification\",\"apiName\"]],[24,[\"model\",\"apiName\"]]],null]]],{\"statements\":[[1,[24,[\"model\",\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-enforcement-button/template.hbs"
    }
  });

  _exports.default = _default;
});