define("accio-alert/pods/components/pdsp-verify-contact-method/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    showResendCodeLink: true,
    classNames: ['pdsp-verify-contact-method'],
    actions: {
      verify: function verify() {
        var _this = this;

        var verificationCode = this.get('verificationCode');

        if (verificationCode === '' || verificationCode === undefined) {
          alert('Please enter a verification Code');
          return;
        }

        var url = this.get('ajax.namespacedUrl') + "/contact-methods/" + this.get('contactMethod.id') + "/verify";
        var data = {
          code: verificationCode
        };
        this.get('ajax').post(url, {
          data: data
        }).then(function (result) {
          _this.get('notifications').success('Contact Method Verified');

          _this.get('store').pushPayload(result);

          _this.onVerified(_this.get('contactMethod'));
        }).catch(function (response) {
          console.log(response);

          _this.get('notifications').error(response.payload.reason);
        });
      },
      resendCode: function resendCode() {
        var _this2 = this;

        var email = this.get('contactMethod.email') || '';
        var isMicrosoftEmail = email.endsWith('live.com') || email.endsWith('hotmail.com') || email.endsWith('msn.com');

        if (!isMicrosoftEmail || confirm("Are you sure? We are currently experiencing delays delivering to live.com, hotmail.com, and msn.com.")) {
          var url = this.get('ajax.namespacedUrl') + "/contact-methods/" + this.get('contactMethod.id') + "/resend_code";
          this.get('ajax').post(url, {}).then(function () {
            _this2.get('notifications').success('Resent Code');
          }).catch(function () {// console.error(reason);
          });
        }
      }
    }
  });

  _exports.default = _default;
});