define("accio-alert/pods/components/workflows/steps/output/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KpQUUOcs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-10 col-lg-offset-1\"],[8],[0,\"\\n        \"],[1,[24,[\"model\",\"message\"]],true],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"action\",\"next_button_label\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center m-t-10\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[3,\"action\",[[23,0,[]],\"respond\"]],[8],[1,[24,[\"model\",\"action\",\"next_button_label\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/workflows/steps/output/template.hbs"
    }
  });

  _exports.default = _default;
});