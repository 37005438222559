define("accio-alert/pods/components/pdsp-subscription-contact-methods/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BL1vs3ZP",
    "block": "{\"symbols\":[\"contactMethod\"],\"statements\":[[4,\"each\",[[24,[\"contactMethods\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"pdsp-subscription-contact-method\",null,[[\"contactMethod\",\"model\",\"onContactMethodAdded\",\"onContactMethodDeleted\"],[[23,1,[]],[24,[\"model\"]],\"addContactMethod\",\"deleteContactMethod\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-subscription-contact-methods/template.hbs"
    }
  });

  _exports.default = _default;
});