define("accio-alert/pods/components/pdsp-vehicle-flag/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    showFlag: Ember.computed.oneWay('vehicle.flagged'),
    tooltip: Ember.computed('showFlag', function () {
      var state = this.get('vehicle.verification.state');

      if (this.get('showFlag')) {
        if (state === 'draft') return 'Please submit your vehicle for verification by uploading a copy of your registration card';
      } else if (state === 'awaiting_customer_response') {
        return 'We have requested additional information from you. Go into the vehicle details to see what we need.';
      }
    })
  });

  _exports.default = _default;
});