define("accio-alert/pods/components/pdsp-subscription-contact-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8AV0AnDE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[0,\"\\n\\t\"],[7,\"input\",false],[12,\"checked\",[22,\"checked\"]],[12,\"type\",\"checkbox\"],[3,\"action\",[[23,0,[]],\"toggleField\"],[[\"on\"],[\"change\"]]],[8],[9],[0,\" \"],[1,[24,[\"contactMethod\",\"medium\"]],false],[0,\" – \"],[1,[24,[\"contactMethod\",\"phoneNumber\"]],false],[0,\" \"],[1,[24,[\"contactMethod\",\"email\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-subscription-contact-method/template.hbs"
    }
  });

  _exports.default = _default;
});