define("accio-alert/pods/components/onboarding/vehicle/add/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentAccount: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    unsubmittedVehicleId: Ember.computed.oneWay('model.metadata.vehicle_id'),
    userAddedVehicle: Ember.computed.notEmpty('unsubmittedVehicleId'),
    actions: {
      skip: function skip() {
        this.onSkip();
      }
    }
  });

  _exports.default = _default;
});