define("accio-alert/pods/public/link-expired/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wyGnteUa",
    "block": "{\"symbols\":[],\"statements\":[[0,\"The link you're trying to access has expired. Please call support.\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/link-expired/template.hbs"
    }
  });

  _exports.default = _default;
});