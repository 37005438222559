define("accio-alert/pods/components/pdsp-my-schedule/component", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line
  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    realtime: Ember.inject.service(),
    classNames: ['card-box', 'my-schedule'],
    unit: 'week',
    startDate: null,
    endDate: null,
    alerts: Ember.computed(function () {
      return this.get('store').query('message', {
        isArchived: false
      });
    }),
    placeholder: Ember.computed('locations', function () {
      if (this.get('locations.length') === 0) {
        return 'Please add places with street sweeping';
      }

      return 'Select a Location';
    }),
    campaignHasNotStarted: Ember.computed('campaignStartDate', function () {
      return new Date() < this.get('campaignStartDate');
    }),
    showPreCampaignMessage: Ember.computed('startDate', function () {
      return this.get('endDate') < this.get('campaignStartDate');
    }),
    showPostCampaignMessage: Ember.computed('startDate', function () {
      return this.get('startDate') > this.get('campaignEndDate');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('campaignStartDate', moment('2024-03-01').toDate());
      this.set('campaignEndDate', moment('2024-10-31').toDate());
      Ember.run.once(this, this._refreshLocations); // this.get('realtime.lobby').on('service_annotation.update', (_) =>
      //  run.next(this, this._setScheduledEvents)
      // );
      // this.get('realtime.userChannel').on('dataUpdate', (payload) => {
      //   if (
      //     payload.type === 'subscription-location' &&
      //     ['create', 'delete'].indexOf(payload.action) > -1
      //   ) {
      //     run.once(this, this._refreshLocations);
      //   }
      // });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isEmpty(this.get('startDate'))) {
        this._resetDates();
      }
    },
    actions: {
      addLocation: function addLocation() {
        this.onAddLocation();
      },
      nextUnit: function nextUnit() {
        this._manipulateDates(this.get('unit') + "s", 1);
      },
      prevUnit: function prevUnit() {
        this._manipulateDates(this.get('unit') + "s", -1);
      },
      updateSelectedLocation: function updateSelectedLocation(newLocation) {
        this.set('selectedLocation', newLocation);

        if (newLocation) {
          Ember.run.once(this, this._setScheduledEvents);
        }
      }
    },
    _refreshLocations: function _refreshLocations() {
      var _this = this;

      this.get('store').query('location', {
        filters: {
          campaign_id: this.campaignId
        }
      }).then(function (locations) {
        _this.set('locations', locations);

        if (Ember.isPresent(locations)) {
          Ember.run.once(_this, _this.actions.updateSelectedLocation, _this.locations.get('firstObject'));
        } else {
          Ember.run.once(_this, _this.actions.updateSelectedLocation, null);
        }
      });
    },
    _resetDates: function _resetDates() {
      var date = new Date(); // if we decide later to start on the first date of the campaign, uncomment this
      // const date = now < this.get('campaignStartDate') ? this.get('campaignStartDate') : now;

      var unit = this.get('unit');

      switch (unit) {
        default:
          this.set('startDate', moment(date).startOf(unit));
          this.set('endDate', moment(date).endOf(unit));
          return;
      }
    },
    _setScheduledEvents: function _setScheduledEvents() {
      var _this2 = this;

      var selectedLocation = this.get('selectedLocation');

      if (Ember.isEmpty(selectedLocation)) {
        this.set('scheduledEvents', null);
        return;
      }

      var url = this.get('ajax.namespacedUrl') + "/locations/" + selectedLocation.get('id') + "/service-schedules";
      this.get('ajax').request(url, {
        data: {
          start_date: this.get('startDate').toISOString(),
          end_date: this.get('endDate').toISOString(),
          campaign_id: this.get('campaignId')
        }
      }).then(function (data) {
        return _this2.set('scheduledEvents', data);
      });
    },
    _manipulateDates: function _manipulateDates(unit, num) {
      this.set('startDate', moment(this.get('startDate')).add(num, unit));
      this.set('endDate', moment(this.get('endDate')).add(num, unit));
      Ember.run.next(this, this._setScheduledEvents);
    }
  });

  _exports.default = _default;
});