define("accio-alert/pods/components/registration/generic/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    didValidate: false,
    isEmailUsernameType: Ember.computed.equal('model.usernameType', 'email'),
    usernameTypePlaceholder: Ember.computed('model.usernameType', function () {
      return Ember.String.capitalize(this.model.usernameType);
    }),
    maxLength: Ember.computed('model.usernameType', function () {
      return this.model.usernameType === 'phone' ? 10 : 100;
    }),
    usernameFooterText: Ember.computed('model.usernameType', function () {
      if (this.model.usernameType === 'phone') {
        return 'US Numbers Only. Example: 2025551234';
      }

      return '';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.salutations = ['Dr.', 'Mr.', 'Mrs.', 'Ms.'];
    },
    actions: {
      setGroupType: function setGroupType(type) {
        this.set('groupType', type);
      },
      setUsernameType: function setUsernameType(type) {
        this.set('model.username', '');
        this.set('model.usernameType', type);
      },
      register: function register() {
        Ember.run.throttle(this, this._doRegister, 5000);
      }
    },
    _doRegister: function _doRegister() {
      var _this = this;

      var model = this.get('model');
      model.validate().then(function (_ref) {
        var validations = _ref.validations;

        _this.set('didValidate', true);

        if (validations.get('isValid')) {
          model.save().then(function () {
            _this.set('didValidate', false);

            _this.get('session').authenticate('authenticator:jwt', {
              username: _this.get('model.username'),
              password: _this.get('model.password')
            });
          });
        }
      });
    }
  });

  _exports.default = _default;
});