define("accio-alert/pods/components/pdsp-digest/row-header/component", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row-header'],
    cdnURL: Ember.computed(function () {
      return _environment.default.cdnURL;
    }),
    campaign: Ember.computed('metrics.campaign_id', function () {
      return this.store.findRecord('campaign', this.metrics.campaign_id);
    })
  });

  _exports.default = _default;
});