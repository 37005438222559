define("accio-alert/models/user", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      disabled: Ember.computed.equal('model.usernameType', 'phone')
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[0-9]{10}$/,
      disabled: Ember.computed.equal('model.usernameType', 'email'),
      message: 'Invalid phone number. See instructions below.'
    }), (0, _emberCpValidations.validator)('ds-error')],
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 100
    }), (0, _emberCpValidations.validator)('ds-error')],
    confirmPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: '{description} do not match',
      description: 'Passwords'
    })]
  });

  var _default = _model.default.extend(Validations, {
    salutation: (0, _model.attr)(),
    firstName: (0, _model.attr)(),
    lastName: (0, _model.attr)(),
    businessName: (0, _model.attr)(),
    middleInitial: (0, _model.attr)(),
    suffix: (0, _model.attr)(),
    biologicalSex: (0, _model.attr)(),
    address: (0, _model.attr)(),
    dateOfBirth: (0, _model.attr)(),
    username: (0, _model.attr)(),
    usernameType: (0, _model.attr)(),
    groupType: (0, _model.attr)('string', {
      defaultValue: 'ind'
    }),
    password: (0, _model.attr)(),
    team: (0, _model.attr)(),
    onboarded: (0, _model.attr)(),
    sex: (0, _model.attr)(),
    race: (0, _model.attr)(),
    ethnicity: (0, _model.attr)(),
    phoneNumber: (0, _model.attr)(),
    roles: (0, _model.attr)(),
    homeRoute: (0, _model.attr)(),
    extraAttributes: (0, _model.attr)(),
    tempToken: (0, _model.attr)(),
    insertedAt: (0, _model.attr)('date'),
    smsDeliveryPhoneNumber: (0, _model.attr)(),
    teams: (0, _model.hasMany)('team'),
    // this exists for user registration so we can assign the user a team
    verification: (0, _model.belongsTo)('verification'),
    onboardingTasks: (0, _model.hasMany)('task'),
    contactMethods: (0, _model.hasMany)('contact-method'),
    vehicles: (0, _model.hasMany)('vehicle'),
    subscriptions: (0, _model.hasMany)('subscription'),
    locations: (0, _model.hasMany)('location'),
    issues: (0, _model.hasMany)('issue', {
      inverse: 'person'
    }),
    isBusinessUser: Ember.computed.equal('groupType', 'biz'),
    smsContactMethods: Ember.computed.filterBy('contactMethods', 'medium', 'sms'),
    emailContactMethods: Ember.computed.filterBy('contactMethods', 'medium', 'email'),
    isAdmin: Ember.computed('roles.[]', function () {
      return this.roles.includes('admin');
    }),
    isSiteAdmin: Ember.computed('roles.[]', function () {
      return this.roles.includes('site_admin');
    }),
    isExecutive: Ember.computed('roles.[]', function () {
      return this.roles.includes('executive');
    }),
    isProgramManager: Ember.computed('roles.[]', function () {
      return this.roles.includes('program_manager');
    }),
    isCustomerManager: Ember.computed('roles.[]', function () {
      return this.roles.includes('customer_manager');
    }),
    isCustomerVerifier: Ember.computed('roles.[]', function () {
      return this.roles.includes('customer_verifier');
    }),
    isSupportAgent: Ember.computed('roles.[]', function () {
      return this.roles.includes('support_agent');
    }),
    isPublicUser: Ember.computed('roles.[]', function () {
      return this.roles.includes('public_user');
    }),
    isDataManager: Ember.computed('roles.[]', function () {
      return this.roles.includes('data_manager');
    }),
    isPatient: Ember.computed('roles.[]', function () {
      return this.roles.includes('patient');
    }),
    isAnonymous: Ember.computed('roles.[]', function () {
      return this.roles.includes('anonymous');
    }),
    isNoLogin: Ember.computed('roles.[]', function () {
      return this.roles.includes('no_login');
    }),
    isIntakeTeam: Ember.computed('roles.[]', function () {
      return this.roles.includes('intake_team');
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + " " + (this.get('lastName') || '');
    }),
    lastLogin: Ember.computed(function () {
      return new Date();
    }),
    fullAddress: Ember.computed(function () {
      return '123 Main St NW, Washington, DC 20015';
    })
  });

  _exports.default = _default;
});