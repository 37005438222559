define("accio-alert/pods/components/workflows/components/scan-sample/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bq6hCkIp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"tw-flex-row tw-items-center\"],[3,\"did-insert\",[[23,0,[\"loadScanner\"]]]],[8],[0,\"\\nasdf\\n  \"],[7,\"div\",true],[10,\"class\",\"tw-text-4xl tw-text-center tw-mb-8\"],[8],[0,\"Scan Sample\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"Scan the sample using your device's camera. If the sample scans successfully, you will see a \\\"Submit Sample\\\" button. If the sample has not yet been scanned, you have the option of clicking \\\"Patient Declined\\\" if the patient declines the sample.\"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"id\",\"scan-viewport\"],[10,\"class\",\"tw-rounded-xl tw-overflow-hidden tw-max-h-[200px] tw-max-w-1/2\"],[10,\"style\",\"max-height: 200px\"],[8],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"scannedSampleID\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"tw-text-4xl tw-text-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"Scanned\"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[1,[23,0,[\"scannedSampleID\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tw-flex tw-mt-4 tw-flex-col tw-justify-center tw-items-center\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"scannedSampleID\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[3,\"on\",[\"click\",[23,0,[\"submitSample\"]]]],[8],[0,\"Submit Sample\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"tw-mt-8\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm btn-danger\"],[3,\"on\",[\"click\",[23,0,[\"declineSample\"]]]],[8],[0,\"Patient Declined\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/workflows/components/scan-sample/template.hbs"
    }
  });

  _exports.default = _default;
});