define("accio-alert/pods/main/workflows/jump/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);

      var params = transition.to.params;
      var url = this.get('ajax.autoNamespacedUrl') + "/workflows/" + params.workflow_id + "/jump";
      this.get('ajax').post(url, {
        data: {
          step_api_name: params.api_name
        }
      }).then(function (data) {
        return _this.store.pushPayload(data);
      }).then(function () {
        return _this.store.findRecord('workflow', params.workflow_id);
      }).then(function (record) {
        return _this.transitionTo('main.workflows.execute', record);
      });
    }
  });

  _exports.default = _default;
});