define("accio-alert/pods/main/vehicles/edit/select-programs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qzqIdan3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-1 col-xs-2\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-map\"],[10,\"style\",\"font-size: 24pt\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-11 col-xs-10\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"m-t-0 m-b-0\"],[8],[7,\"strong\",true],[10,\"class\",\"text-custom\"],[8],[0,\"Personalize\"],[9],[0,\" for your vehicle\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12 m-t-10\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h4\",true],[8],[0,\"Available Programs\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Give me personalized schedules, alerts, and service updates for these DC myDPW programs:\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[28,\"pdsp-vehicle-campaign-selector\",null,[[\"vehicle\",\"onSelectionUpdated\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedCampaigns\"]]],null]],null]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row m-t-10\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-md\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"Continue\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/vehicles/edit/select-programs/template.hbs"
    }
  });

  _exports.default = _default;
});