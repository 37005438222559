define("accio-alert/pods/home/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('nativeApp.isNativeApp')) {
        this.nativeApp.postMessage({
          action: 'login',
          token: this.session.data.authenticated.token
        });
      } else {
        this.transitionTo(this.get('currentUser.user.homeRoute'));
      }
    }
  });

  _exports.default = _default;
});