define("accio-alert/pods/components/pdsp-my-vehicles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qAqZ3Ut0",
    "block": "{\"symbols\":[\"vehicle\"],\"statements\":[[7,\"h3\",true],[10,\"class\",\"header-title m-t-0 m-b-5\"],[8],[0,\"My Vehicles\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default btn-sm pull-right\",\"main.vehicles.new.home\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-plus-sign\"],[8],[9],[0,\" Add\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\nEnroll your vehicles by clicking the +Add button and providing the requested informaiton. Vehicles you submit move to Pending Review status while they are in line for review and approval. A flag is displayed next to an action button if we need something from you. To permanently delete a vehicle from your account, click the X.\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"vehicles\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"hr\",true],[10,\"class\",\"m-t-10 m-b-10\"],[8],[9],[0,\"\\n\\t\"],[1,[28,\"pdsp-my-vehicles/pdsp-vehicle\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-vehicles/template.hbs"
    }
  });

  _exports.default = _default;
});