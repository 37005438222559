define("accio-alert/pods/main/locations/edit/show-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Uyn/QBt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-1 col-xs-2\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-map\"],[10,\"style\",\"font-size: 24pt\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-11 col-xs-10\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"m-t-0 m-b-0\"],[8],[7,\"strong\",true],[10,\"class\",\"text-custom\"],[8],[0,\"Review interruptions in service this week\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-lg-offset-2 m-t-10\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[8],[0,\"There are some interruptions in service for your selected blocks this week.\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[10,\"class\",\"text-danger\"],[8],[0,\"During this period ONLY, you do not need to move your vehicle\"],[7,\"br\",true],[8],[9],[0,\"for regularly scheduled street sweeping on the blocks marked as \"],[7,\"strong\",true],[8],[0,\"suspended\"],[9],[0,\".\"],[9],[0,\"\\n\\n    \"],[1,[28,\"pdsp-my-schedule\",null,[[\"showSingleLocation\",\"selectedLocation\",\"campaignId\",\"campaignName\",\"campaignIconSrc\"],[true,[24,[\"model\"]],3,\"Street Sweeping (2020)\",\"/assets/images/campaigns/originals/street_sweeping.png\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row m-t-10\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default btn-md\",\"main.locations.edit.finish\"]],{\"statements\":[[0,\"Continue\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/locations/edit/show-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});