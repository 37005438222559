define("accio-alert/pods/components/conversations/fragment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bkak0gXt",
    "block": "{\"symbols\":[\"r\"],\"statements\":[[7,\"p\",true],[8],[1,[24,[\"model\",\"message\"]],true],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"responses\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-danger\"],[12,\"style\",\"background-color: #b82024 !important; border: #b82024 !important;\"],[3,\"action\",[[23,0,[]],\"triggerResponse\",[23,1,[]]]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/conversations/fragment/template.hbs"
    }
  });

  _exports.default = _default;
});