define("accio-alert/mixins/track-verification-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    postClickstream: function postClickstream(status) {
      var verification = this.controller.get('model');
      this.store.createRecord('verificationComment', {
        verification: verification,
        commentType: status
      }).save();
    },
    postOpened: Ember.on('activate', function () {
      Ember.run.once(this, this.postClickstream, 'opened');
    }),
    postClosed: Ember.on('deactivate', function () {
      Ember.run.once(this, this.postClickstream, 'closed');
    })
  });

  _exports.default = _default;
});