define("accio-alert/pods/enforcement/message/controller", ["exports", "accio-alert/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    licensePlateState: (0, _emberCpValidations.validator)('presence', true),
    licensePlate: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[A-Za-z0-9]{2,8}$/,
      message: 'License Plates should only contain numbers and letters. If your plate has other characters like hyphens, please omit them.'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    didValidate: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._getStatesData().then(function (statesData) {
        _this.set('statesData', statesData);
      });
    },
    showTCN: Ember.computed('topic', function () {
      return this.get('topic').toLowerCase().indexOf('tow') > -1;
    }),
    actions: {
      updateState: function updateState(item) {
        this.set('licensePlateState', item);
      },
      sendAlert: function sendAlert() {
        var _this2 = this;

        var state = this.get('licensePlateState.id');
        var tag = this.get('licensePlate');
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          _this2.set('didValidate', true);

          if (validations.get('isValid') === false) {
            return;
          }

          var data = {
            topic: _this2.get('topic'),
            message_type: _this2.get('messageType'),
            match: {
              type: 'license_plate',
              state: state,
              tag: tag
            },
            transaction_time: new Date().toISOString(),
            variables: {
              location: _this2.get('location'),
              fine_amount: _this2.get('fineAmount'),
              location_coords: _this2.get('locationCoords')
            }
          };

          _this2.get('ajax').post(_environment.default.apiURL + "/" + _environment.default.adminApiNamespace + "/messages/ui", {
            data: data
          }).then(function () {
            _this2.get('notifications').success('Alert Sent');

            _this2._reset();

            _this2.transitionToRoute('enforcement.index');
          });
        });
      },
      cancel: function cancel() {
        this._reset();

        this.transitionToRoute('enforcement');
      }
    },
    _getStatesData: function _getStatesData() {
      return _jquery.default.get(_environment.default.cdnURL + "/assets/data/states.json");
    },
    _reset: function _reset() {
      this.setProperties({
        licensePlate: null,
        licensePlateState: null,
        fineAmount: null,
        location: null,
        externalSystemID: null,
        locationCoords: null,
        didValidate: false
      });
    }
  });

  _exports.default = _default;
});