define("accio-alert/pods/public/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo(this.get('currentUser.user.homeRoute'));
      }

      var qp = transition.to.queryParams;
      var token = qp.token || qp.t;

      if (token !== undefined) {
        var authenticator = 'authenticator:jwt';
        console.log('original attempted', this.get('session.attemptedTransition'));
        this.get('session').authenticate(authenticator, {
          token: token
        });
        transition.abort();
      }

      var loginRoute = this.get('currentAccount.account.accountRoutesLogin');

      if (loginRoute !== 'public.login') {
        this.transitionTo(loginRoute);
      }
    },
    actions: {
      willTransition: function willTransition() {
        console.log('willTransition');
        this.controller.clearAuthState();
      }
    }
  });

  _exports.default = _default;
});