define("accio-alert/pods/components/pdsp-select-features/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cancelMode: 'none',
    actions: {
      save: function save() {
        var _this = this;

        var selectedFeatures = this.get('model.selectedFeatures');

        if (selectedFeatures.global_ids && selectedFeatures.global_ids.length > 0) {
          this.get('model').save().then(function (record) {
            return _this.onSave(record);
          });
        } else {
          alert('Please select at least one block');
        }
      }
    }
  });

  _exports.default = _default;
});