define("accio-alert/components/issues/appointment-public/detail", ["exports", "common/components/issues/appointment-public/detail"], function (_exports, _detail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _detail.default;
    }
  });
});