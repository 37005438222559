define("accio-alert/pods/mobile/conversation/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    actions: {
      onResponse: function onResponse(input) {
        var _this = this;

        var url = this.ajax.namespacedUrl + "/conversations/" + this.model.id + "/input";
        var data = {
          input: input
        };
        this.ajax.post(url, {
          data: data
        }).then(function (response) {
          _this.store.pushPayload(response); // console.log(response);

        });
      }
    }
  });

  _exports.default = _default;
});