define("accio-alert/pods/components/pdsp-tasks/location-add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XLpH9XSc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hr\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8\"],[8],[0,\"\\n\\t\\t\"],[7,\"h5\",true],[8],[0,\"Add your first location\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Add a location to see all the services DPW offers in that location.\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-4\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-danger\",\"main.locations.new.home\"]],{\"statements\":[[0,\"Add Location >\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-tasks/location-add/template.hbs"
    }
  });

  _exports.default = _default;
});