define("accio-alert/pods/components/pdsp-digest/campaign-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.findRecord('campaign', this.metrics.campaign_id).then(function (campaign) {
        _this.set('campaign', campaign);

        _this.set('campaignImage', "campaigns/originals/" + _this.get('campaign.apiName') + ".png");

        console.log(_this.campaignImage);
      });
    }
  });

  _exports.default = _default;
});