define("accio-alert/components/pdsp-select-features-map-mapbox", ["exports", "common/components/pdsp-select-features-map-mapbox"], function (_exports, _pdspSelectFeaturesMapMapbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pdspSelectFeaturesMapMapbox.default;
    }
  });
});