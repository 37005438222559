define("accio-alert/pods/components/onboarding/demographics-entry/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = Ember.Component.extend({
    currentAccount: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    task: Ember.inject.service(),
    sexes: ['Male', 'Female'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('address', {});
    },
    actions: {
      save: function save() {
        var _this = this;

        var address = this.get('address');
        this.get('currentUser.user').save().then(function (user) {
          var attrs = _extends({
            user: user
          }, address, {
            nickname: 'Home'
          });

          console.log(attrs);

          var record = _this.store.createRecord('location', attrs);

          return record.save();
        }).then(function () {
          return _this.get('task').completeTask(_this.model);
        }).then(function () {
          return _this.onNext();
        });
      }
    }
  });

  _exports.default = _default;
});