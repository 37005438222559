define("accio-alert/pods/main/locations/form/home/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    enabledSubscriptions: Ember.computed.filterBy('currentUser.user.subscriptions', 'enabled', true),
    actions: {
      setAddress: function setAddress(address) {
        this.get('model').setProperties(address);
      },
      cancel: function cancel() {
        var model = this.get('model');
        this.reset();
        model.rollbackAttributes();

        if (this.nativeApp.isNativeApp) {
          this.nativeApp.postMessage({
            action: 'navigation.pop'
          });
        } else {
          this.transitionToRoute('main');
        }
      },
      skipToVehicle: function skipToVehicle() {
        var _this = this;

        this.model.destroyRecord();
        this.cancelLocationAddTask().then(function () {
          _this.transitionToRoute('main.vehicles.new.intro');
        });
      },
      save: function save() {
        var _this2 = this;

        this.set('selectedCampaigns', this.get('locationCampaigns'));
        this.validate().then(function () {
          return _this2.get('model').save();
        }).then(function (location) {
          return _this2.transitionToRoute('main.locations.edit.select_programs', location);
        });
      }
    },
    reset: function reset() {
      this.setProperties({
        didValidate: false,
        address: undefined
      });
    },
    validate: function validate() {
      var _this3 = this;

      return this.get('model').validate().then(function (_ref) {
        var validations = _ref.validations;

        _this3.set('didValidate', true);

        return validations.get('isValid') ? Ember.RSVP.resolve() : Ember.RSVP.reject();
      });
    },
    cancelLocationAddTask: function cancelLocationAddTask() {
      var url = this.get('ajax.namespacedUrl') + "/tasks/cancel";
      return this.ajax.post(url, {
        data: {
          type: 'onboarding.location.add'
        }
      });
    }
  });

  _exports.default = _default;
});