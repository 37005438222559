define("accio-alert/pods/main/workflows/execute/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      this._super.apply(this, arguments);

      return this.store.find('workflow', params.workflow_id);
    }
  });

  _exports.default = _default;
});