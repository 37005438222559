define("accio-alert/pods/main/vehicles/form/home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fPnAZvQ8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card-box\"],[8],[0,\"\\n\\t\"],[1,[28,\"log\",[[24,[\"nativeApp\"]]],null],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[7,\"strong\",true],[8],[0,\"At this time you can no longer get vehicle alerts from the MyDPW app.\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/vehicles/form/home/template.hbs"
    }
  });

  _exports.default = _default;
});