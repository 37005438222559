define("accio-alert/pods/main/locations/edit/home/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'main/locations/form/home',
    controllerName: 'main/locations/form/home'
  });

  _exports.default = _default;
});