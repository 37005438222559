define("accio-alert/pods/main/workflows/execute/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    actions: {
      onConversationCompleted: function onConversationCompleted() {
        this.get('model').reload();
      },
      onNavigate: function onNavigate() {
        this.transitionToRoute.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});