define("accio-alert/pods/components/pdsp-my-subscriptions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPBV3QyT",
    "block": "{\"symbols\":[\"subscription\"],\"statements\":[[7,\"p\",true],[8],[0,\"Use this section to add or remove contact methods, locations, or vehicles to/from your alert subscriptions.  You’ll receive alerts for programs that are active (toggle is on), have at least one contact method that is verified (see Ways To Contact Me in the myProfile section), and has at least one location or fully enrolled vehicle listed. Add an item to a subscription by selecting from the Add menu on any panel. Click X to delete an item from a subscription.\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"subscriptions-container\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"pdsp-my-subscriptions/pdsp-subscription\",null,[[\"model\",\"onLocationConfigNeeded\"],[[23,1,[]],[24,[\"onLocationConfigNeeded\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-subscriptions/template.hbs"
    }
  });

  _exports.default = _default;
});