define("accio-alert/adapters/user", ["exports", "accio-alert/pods/application/adapter", "accio-alert/config/environment"], function (_exports, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    session: Ember.inject.service(),
    urlForQueryRecord: function urlForQueryRecord(query) {
      var roles = this.get('session.data.authenticated.user.roles');

      if (query.me) {
        delete query.me;

        if (roles.includes('public_user') || roles.includes('patient') || roles.includes('no_login')) {
          return _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/users/me";
        } else {
          return _environment.default.apiURL + "/" + _environment.default.adminApiNamespace + "/users/me";
        }
      }

      return this._super.apply(this, arguments);
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      if (snapshot.record.roles === undefined || snapshot.record.roles.includes('public_user')) {
        return _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/users";
      } else {
        return _environment.default.apiURL + "/" + _environment.default.adminApiNamespace + "/users";
      }
    }
  });

  _exports.default = _default;
});