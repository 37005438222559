define("accio-alert/pods/public/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authentication: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('username', 'password', 'secondFactor');
        return this.get('authentication').authenticate(credentials).then(function (result) {
          if (result === undefined) {
            return;
          }

          var requiresSecondFactor = result.requiresSecondFactor,
              hasErrors = result.hasErrors;

          if (requiresSecondFactor) {
            var secondFactorMessage = result.secondFactorMessage;

            _this.setProperties({
              secondFactor: null,
              requiresSecondFactor: requiresSecondFactor,
              secondFactorMessage: secondFactorMessage
            });
          } else if (hasErrors) {
            var errorMessage = result.errorMessage;

            _this.set('errorMessage', errorMessage);
          }
        });
      }
    },
    clearAuthState: function clearAuthState() {
      this.setProperties({
        username: null,
        password: null,
        secondFactor: null
      });
    }
  });

  _exports.default = _default;
});