define("accio-alert/pods/main/vehicles/new/home/route", ["exports", "accio-alert/mixins/data-route", "accio-alert/mixins/reset-scroll"], function (_exports, _dataRoute, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataRoute.default, _resetScroll.default, {
    controllerName: 'main/vehicles/form/home',
    templateName: 'main/vehicles/form/home',
    model: function model() {
      return this.store.createRecord('vehicle', {
        enabled: true
      });
    },
    willTransitionConfirm: function willTransitionConfirm() {
      return window.confirm('You have unsaved changes that will be lost. Do you want to continue?');
    }
  });

  _exports.default = _default;
});