define("accio-alert/models/verification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    state: (0, _model.attr)(),
    lockStatus: (0, _model.attr)(),
    comments: (0, _model.hasMany)('verification-comment'),
    vehicle: (0, _model.belongsTo)('vehicle'),
    dateOrderedComments: Ember.computed.sort('comments', 'commentSort'),
    isPendingReview: Ember.computed.equal('state', 'pending_review'),
    isRFI: Ember.computed.equal('state', 'requested_more_info'),
    init: function init() {
      this._super.apply(this, arguments);

      this.commentSort = ['updatedAt'];
    }
  });

  _exports.default = _default;
});