define("accio-alert/pods/mobile/messages/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service(),
    formattedPlainText: Ember.computed('model.plaintextBody', function () {
      return '<p>' + this.model.plaintextBody.replace(/\n/, '</p><p>') + '</p>';
    })
  });

  _exports.default = _default;
});