define("accio-alert/pods/components/pdsp-verification-status-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    pendingReview: Ember.computed.equal('model.state', 'pending_review'),
    approved: Ember.computed.equal('model.state', 'approved'),
    rfi: Ember.computed.equal('model.state', 'requested_more_info'),
    denied: Ember.computed.equal('model.state', 'denied'),
    mostRecentDeterminationComment: Ember.computed('model.comments', function () {
      var _this = this;

      var rfis = this.get('model.comments').filter(function (c) {
        return _this.determinationTypes.indexOf(c.get('commentType')) > -1;
      });
      return rfis.length === 0 ? null : rfis[rfis.length - 1];
    }),
    cleanStatus: Ember.computed('model.state', function () {
      return this.get('model.state').replace(/_/g, ' ');
    }),
    statusHighlightColor: Ember.computed('model.state', function () {
      var result = '';

      switch (this.get('model.state')) {
        case 'pending_review':
          result = 'panel-info';
          break;

        case 'denied':
          result = 'panel-color panel-danger';
          break;

        case 'requested_more_info':
          result = 'panel-color panel-warning';
          break;

        case 'approved':
          result = 'panel-color panel-success';
          break;
      }

      return result;
    }),
    reasons: Ember.computed('mostRecentDeterminationComment', function () {
      if (this.get('rfi') || this.get('denied')) {
        var reasons = this.get('mostRecentDeterminationComment.reasons');

        if (Ember.isEmpty(reasons)) {
          return;
        }

        return this.get('store').query('verification-reason', {
          ids: reasons
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.determinationTypes = ['request_more_info', 'denial'];
    },
    actions: {
      resubmit: function resubmit() {
        this.onResubmit();
      }
    }
  });

  _exports.default = _default;
});