define("accio-alert/pods/mobile/contact-methods/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nArk569l",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pdsp-my-contact-methods\",null,[[\"model\"],[[24,[\"currentUser\",\"user\",\"contactMethods\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/contact-methods/template.hbs"
    }
  });

  _exports.default = _default;
});