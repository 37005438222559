define("accio-alert/pods/components/pdsp-subscription-vehicle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'li',
    checked: Ember.computed('model', function () {
      var vehicleIds = this.get('model.vehicleIds');
      var vehicleId = parseInt(this.get('vehicle.id'));
      return Ember.isPresent(vehicleIds) && vehicleIds.indexOf(vehicleId) > -1;
    }),
    actions: {
      toggleField: function toggleField() {
        this.toggleProperty('checked');

        if (this.get('checked')) {
          this.onVehicleAdded(parseInt(this.get('vehicle.id')));
        } else {
          this.onVehicleDeleted(parseInt(this.get('vehicle.id')));
        }
      }
    }
  });

  _exports.default = _default;
});