define("accio-alert/pods/components/native-app-poster/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    nativeApp: Ember.inject.service(),
    sendEvent: function sendEvent(action, meta) {
      this.nativeApp.postMessage({
        action: action,
        meta: meta
      });
    },
    click: function click(event) {
      var _event$target;

      var url = event == null ? void 0 : (_event$target = event.target) == null ? void 0 : _event$target.href;
      this.sendEvent('click', {
        url: url
      });
    }
  });

  _exports.default = _default;
});