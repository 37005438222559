define("accio-alert/pods/components/pdsp-validated-address/component", ["exports", "common/mixins/validated-input"], function (_exports, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    maxLength: 255,
    structured: true,
    inputClass: Ember.computed('showErrorMessage', function () {
      var str = 'form-control place-autocomplete--input';

      if (this.get('showErrorMessage')) {
        str += 'form-control-danger';
      }

      return str;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.placesRestrictions = {
        country: 'US'
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.value === undefined) {
        this.set('value', {});
      }
    },
    actions: {
      rawAddressUpdated: function rawAddressUpdated(e) {
        this.set('rawValue', e.target.value);
      },
      resetAddress: function resetAddress() {
        this.set('value', {});
        this.set('rawValue', undefined);
        this.set('street2', undefined);
        this.set('showValidations', false);
        this.set('structured', true);
      },
      switchToFreeFormAddress: function switchToFreeFormAddress() {
        this.set('structured', false);
      },
      done: function done() {
        return false;
      },
      placeChanged: function placeChanged(address) {
        var streetNumber = this.getShortNameForType(address, 'street_number');
        var street = this.getShortNameForType(address, 'route');
        var location = address.geometry.location;
        var locality = this.getShortNameForType(address, 'locality');
        var neighborhood = this.getShortNameForType(address, 'neighborhood');
        var city = 'CITY NOT FOUND: Click "Address Not Found? to enter it"';

        if (locality === '' && neighborhood !== '') {
          city = neighborhood;
        } else {
          city = locality;
        }

        var obj = {
          street: streetNumber + " " + street,
          street2: this.get('street2'),
          formattedAddress: address.formatted_address,
          city: city,
          source: 'google',
          province: this.getShortNameForType(address, 'administrative_area_level_1'),
          county: this.getShortNameForType(address, 'administrative_area_level_2'),
          provinceLongName: this.getLongNameForType(address, 'administrative_area_level_1'),
          postalCode: this.getShortNameForType(address, 'postal_code'),
          latitude: location.lat(),
          longitude: location.lng()
        };
        this.set('value', obj);
        this.set('rawValue', undefined);

        if (this.onAddressPicked) {
          this.onAddressPicked(obj);
        }
      }
    },
    keyUp: function keyUp() {
      this._super.apply(this, arguments);

      if (this.get('value') !== undefined && this.get('value') !== {}) {
        this.set('value.street2', this.street2);
      }
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      this.set('showValidations', true);
    },
    getShortNameForType: function getShortNameForType(address, type) {
      // sometimes (especially with new addresses)
      // google places will return no street number
      // we need to account for that
      var results = address.address_components.filter(function (item) {
        return item.types.indexOf(type) > -1;
      });

      if (results.length > 0) {
        return results[0].short_name;
      } else {
        if (type === 'street_number' && this.rawValue) {
          return this.rawValue.split(' ')[0];
        }

        return '';
      }
    },
    getLongNameForType: function getLongNameForType(address, type) {
      return address.address_components.filter(function (item) {
        return item.types.indexOf(type) > -1;
      })[0].long_name;
    }
  });

  _exports.default = _default;
});