define("accio-alert/models/verification-comment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    comment: (0, _model.attr)(),
    commentType: (0, _model.attr)(),
    fileLocation: (0, _model.attr)(),
    uploadedFile: (0, _model.attr)(),
    uploadedFileName: (0, _model.attr)(),
    uploadedFileType: (0, _model.attr)(),
    updatedAt: (0, _model.attr)('utc'),
    insertedAt: (0, _model.attr)('utc'),
    preSignedUrl: (0, _model.attr)(),
    reasons: (0, _model.attr)(),
    verification: (0, _model.belongsTo)('verification'),
    verificationDocumentType: (0, _model.belongsTo)('verification-document-type'),
    modifiedBy: (0, _model.belongsTo)('user'),
    isFile: Ember.computed.equal('commentType', 'file'),
    docTypeID: Ember.computed.oneWay('verificationDocumentType.id'),
    isPDF: Ember.computed('preSignedUrl', function () {
      var url = this.get('preSignedUrl').split('?')[0];
      var extension = url.slice(url.lastIndexOf('.') + 1);
      return extension === 'pdf';
    }),
    clearUploadedFile: function clearUploadedFile() {
      this.setProperties({
        uploadedFile: undefined,
        uploadedFileName: undefined,
        uploadedFileType: undefined
      });
    }
  });

  _exports.default = _default;
});