define("accio-alert/models/test-result", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    issueId: (0, _model.attr)('number'),
    sampleDate: (0, _model.attr)('date'),
    lastName: (0, _model.attr)('string'),
    firstName: (0, _model.attr)('string'),
    dateOfBirth: (0, _model.attr)('date'),
    testPanel: (0, _model.attr)('string'),
    diagnosis: (0, _model.attr)('string'),
    deliveryStatus: (0, _model.attr)('string'),
    facility: (0, _model.attr)('string'),
    teamName: (0, _model.attr)('string'),
    testingSite: (0, _model.attr)('string')
  });

  _exports.default = _default;
});