define("accio-alert/pods/main/vehicles/new/onboarding/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      skip: function skip() {
        var _this = this;

        this.cancelVehicleAddTask().then(function () {
          _this.currentUser.load().then(function () {
            _this.transitionToRoute('home');
          });
        });
      }
    },
    cancelVehicleAddTask: function cancelVehicleAddTask() {
      var url = this.get('ajax.namespacedUrl') + "/tasks/cancel";
      return this.ajax.post(url, {
        data: {
          type: 'onboarding.vehicle.add'
        }
      });
    }
  });

  _exports.default = _default;
});