define("accio-alert/pods/main/subscriptions/location/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        this.get('model').save().then(function () {
          var text = 'Selected blocks saved.';

          _this.get('notifications').success(text);

          _this.transitionToRoute('main');
        });
      }
    }
  });

  _exports.default = _default;
});