define("accio-alert/pods/mobile/patient/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1B43vTEb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"name\",\"profile\"],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-lg-offset-2 m-b-20\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-clock\"],[10,\"style\",\"margin-top: 2px\"],[8],[9],[0,\"\\n\\t\\tMy Appointments\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-lg-offset-2\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"pdsp-health/my-appointments\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-lg-offset-2 m-b-20\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-clock\"],[10,\"style\",\"margin-top: 2px\"],[8],[9],[0,\"\\n\\t\\tMy Test Results\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-8 col-lg-offset-2\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"pdsp-health/my-tests\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/patient/template.hbs"
    }
  });

  _exports.default = _default;
});