define("accio-alert/pods/components/pdsp-my-subscriptions/subscription-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nuSqTjdb",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-2 col-xs-2 p-t-10 p-b-10\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"glyphicons glyphicons-\",[22,\"icon\"]]]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"sub-label col-lg-8 col-xs-8 p-t-10 p-b-10\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"label\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-2 col-xs-2 text-center p-t-10 p-b-10\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"style\",\"padding-right: 20px;\"],[3,\"action\",[[23,0,[]],\"delete\"]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-remove-sign\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/pdsp-my-subscriptions/subscription-row/template.hbs"
    }
  });

  _exports.default = _default;
});