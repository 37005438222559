define("accio-alert/pods/main/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    realtime: Ember.inject.service(),
    authenticationRoute: 'public.login',
    // doing socketguru setup after model because the session isn't
    // fully authenticated when you autoconnect
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.get('realtime').autoJoinChannels();
    }
  });

  _exports.default = _default;
});