define("accio-alert/models/subscription", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    vehicleIds: (0, _model.attr)(),
    contactMethodIds: (0, _model.attr)(),
    vehicleNames: (0, _model.attr)(),
    contactMethodNames: (0, _model.attr)(),
    enabled: (0, _model.attr)('boolean'),
    user: (0, _model.belongsTo)('user'),
    notification: (0, _model.belongsTo)('notification'),
    campaign: (0, _model.belongsTo)('campaign'),
    locations: (0, _model.hasMany)('location'),
    subscriptionLocations: (0, _model.hasMany)('subscription-location')
  });

  _exports.default = _default;
});