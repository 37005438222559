define("accio-alert/mixins/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dateValidation: {
      format: 'MM/DD/YYYY'
    },
    dateOfBirthFormatter: function dateOfBirthFormatter(data) {
      if (data === null) {
        return '<span class="text-muted">none</span>';
      }

      return moment.utc(data).format('ll');
    },
    dateFormatter: function dateFormatter(data) {
      if (data === null) {
        return '<span class="text-muted">none</span>';
      }

      return moment.utc(data).local().format('ll');
    },
    dateTimeFormatter: function dateTimeFormatter(data) {
      if (data === null) {
        return '<span class="text-muted">none</span>';
      }

      return moment.utc(data).local().format('lll');
    },
    jsonFormatter: function jsonFormatter(data) {
      return JSON.stringify(data);
    },
    groupTypeFormatter: function groupTypeFormatter(data) {
      return data === 'biz' ? 'B' : 'I';
    },
    datePluginConfig: {
      format: 'mm/dd/yyyy',
      todayBtn: 'linked',
      todayHighlight: true,
      autoclose: true
    },
    stringOperators: ['equal', 'not_equal', 'contains', 'not_contains', 'is_null', 'is_not_null'],
    groupTypeOperators: ['equal'],
    dateOperators: ['between', 'greater_or_equal', 'less_or_equal', 'equal'],
    identityOperators: ['equal'],
    integerOperators: ['equal', 'greater_or_equal', 'less_or_equal', 'between'],
    ajax: Ember.inject.service(),
    exportCSV: function exportCSV(query) {
      var url = this.get('ajaxUrl');
      var tempTokenUrl = this.get('ajax.namespacedAdminUrl') + "/auth/token/temp";
      var columnStr = this.selectedColumns().map(function (column) {
        return "columns[]=" + column;
      }).join('&');
      this.get('ajax').post(tempTokenUrl).then(function (_ref) {
        var token = _ref.token;
        location.href = url + ".csv?token=" + token + "&filters=" + JSON.stringify(query) + "&" + columnStr;
      });
    },
    selectedColumns: function selectedColumns() {
      return this.get('columns').filter(function (column) {
        return column.selected;
      }).map(function (column) {
        return column.id;
      });
    },
    actions: {
      exportCSV: function exportCSV(query) {
        var url = this.get('ajaxUrl');
        var tempTokenUrl = this.get('ajax.namespacedAdminUrl') + "/auth/token/temp";
        var columnStr = this.selectedColumns().map(function (column) {
          return "columns[]=" + column;
        }).join('&');
        this.get('ajax').post(tempTokenUrl).then(function (_ref2) {
          var token = _ref2.token;
          location.href = url + ".csv?token=" + token + "&filters=" + JSON.stringify(query) + "&" + columnStr;
        });
      }
    }
  });

  _exports.default = _default;
});