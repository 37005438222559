define("accio-alert/pods/components/pdsp-pipeline-view/component", ["exports", "d3-selection", "d3-time", "d3-time-format", "d3-scale", "d3-axis", "accio-alert/config/environment", "d3-transition", "d3-textwrap"], function (_exports, _d3Selection, _d3Time, _d3TimeFormat, _d3Scale, _d3Axis, _environment, _d3Transition, _d3Textwrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    assetMap: Ember.inject.service('asset-map'),
    isMobile: false,
    classNames: ['pipeline-view-container'],
    init: function init() {
      this._super.apply(this, arguments);

      this.activeCampaigns = [];
      this.shouldUpdateGraph = false;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this._refreshLocationCampaigns().then(this._refreshSchedules.bind(this)).then(function () {
        if (_this.pipeline && _this.pipeline.initialized) {
          _this._updateGraph();
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var startDate = moment().isoWeekday(1).startOf('day');
      this.set('startDate', startDate.toDate());
      this.set('endDate', startDate.add(15, 'days').endOf('day').toDate());
      Ember.run.scheduleOnce('afterRender', this, this._initGraph);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    _refreshLocationCampaigns: function _refreshLocationCampaigns() {
      var _this2 = this;

      var url = this.get('ajax').namespacedUrl + "/locations/" + this.get('location.id') + "/campaigns";
      return this.ajax.request(url).then(function (result) {
        _this2.set('activeCampaigns', result.filterBy('status', 'active'));

        return result;
      });
    },
    _refreshSchedules: function _refreshSchedules() {
      var _this3 = this;

      var location = this.get('location');
      var url = this.get('ajax.namespacedUrl') + "/locations/" + location.get('id') + "/service-schedules";
      return this.get('ajax').request(url, {
        data: {
          start_date: this.get('startDate').toISOString(),
          end_date: this.get('endDate').toISOString(),
          campaign_id: this.get('campaignId')
        }
      }).then(function (data) {
        var transformedData = _this3._transformResponse(data);

        _this3.set('data', transformedData);
      });
    },
    _transformResponse: function _transformResponse(data) {
      Object.keys(data).forEach(function (campaignId) {
        data[campaignId].schedules = data[campaignId].schedules.map(function (s) {
          var service_start_at = s.service_start_at === null ? null : moment(s.service_start_at).toDate();
          var service_end_at = s.service_end_at === null ? null : moment(s.service_end_at).toDate();
          var original_start_at = s.metadata.service_type === 'slide' ? moment(s.original_start_at).toDate() : undefined;
          return Object.assign(s, {
            service_start_at: service_start_at,
            service_end_at: service_end_at,
            original_start_at: original_start_at
          });
        });
      }); // finally flatten the data

      return Object.values(data).map(function (v) {
        return v.schedules || [];
      }).flat();
    },
    _initGraph: function _initGraph() {
      var svg = (0, _d3Selection.select)(this.element.querySelector('svg')),
          xAxisPadding = 120,
          yAxisPadding = 40,
          container = this.element,
          xPadding = 50,
          yPadding = 20,
          svgWidth = Math.max(container.clientWidth, 800) - xPadding,
          // 50 = account for padding
      svgHeight = container.clientHeight - yPadding,
          graphWidth = svgWidth - xAxisPadding,
          graphHeight = svgHeight - yAxisPadding - 20,
          // account for the axes
      // main x-scale of dates
      xScale = (0, _d3Scale.scaleTime)().domain([this.startDate, this.endDate]).rangeRound([0, graphWidth]).nice(_d3Time.timeMonday, _d3Time.timeDay.every(1)),
          xScaleOrdinal = (0, _d3Scale.scalePoint)().domain(xScale.ticks(15)).rangeRound([0, graphWidth]),
          yScale = (0, _d3Scale.scaleBand)().rangeRound([0, graphHeight]).padding(0.2),
          minorSegmentWidth = xScaleOrdinal.step(),
          xAxisOffset = minorSegmentWidth * 0.333,
          graphLayout = svg.append('g').attr('class', 'graph-layout').attr('transform', "translate(" + (this.isMobile ? 80 : 100) + ", " + yAxisPadding + ")");
      svg.attr('width', svgWidth).attr('height', svgHeight);
      var pipeline = {
        svg: svg,
        xScale: xScale,
        xScaleOrdinal: xScaleOrdinal,
        yScale: yScale,
        svgWidth: svgWidth,
        svgHeight: svgHeight,
        graphWidth: graphWidth,
        graphHeight: graphHeight,
        xAxisPadding: xAxisPadding,
        xAxisOffset: xAxisOffset,
        minorSegmentWidth: minorSegmentWidth,
        graphLayout: graphLayout
      };
      this.set('pipeline', pipeline);

      this._initXAxis(pipeline);

      this._initYAxis(pipeline);

      pipeline.initialized = true;
    },
    _updateGraph: function _updateGraph() {
      var yScale = this.pipeline.yScale;
      yScale.domain(this.activeCampaigns.map(function (c) {
        return c.api_name;
      }));
      this.pipeline.rowHeight = yScale.bandwidth();
      this.pipeline.yAxisOffset = this.pipeline.rowHeight * 0.333;

      this._updateXAxis();

      this._updateYAxis();

      this._placePoints();

      this.set('shouldUpdateGraph', false);
    },
    _placePoints: function _placePoints() {
      var _this$pipeline = this.pipeline,
          xScale = _this$pipeline.xScale,
          yScale = _this$pipeline.yScale,
          minorSegmentWidth = _this$pipeline.minorSegmentWidth,
          graphLayout = _this$pipeline.graphLayout;
      var duration = 250;
      var dataPoint = graphLayout.selectAll('.dot').data(this.data, function (d) {
        return d.key;
      }),
          symbolWidth = Math.min(minorSegmentWidth * 0.8, 30),
          symbolHeight = symbolWidth,
          xAlignmentOffset = (minorSegmentWidth - symbolWidth) * 0.5;
      dataPoint.exit().transition().duration(duration).style('opacity', 0).remove();
      var currentDot = dataPoint.enter().append('g');
      currentDot.attr('class', function (d) {
        return "dot " + d.metadata.service + " " + Ember.String.dasherize(d.metadata.service_type);
      }).attr('transform', "translate(0, 0)"); // actual dot

      currentDot.append('rect').attr('transform', function (d) {
        return "translate(" + (xScale(d.service_start_at) + xAlignmentOffset) + "," + yScale(d.metadata.service) + ")";
      }).attr('rx', 5).style('opacity', 0).attr('width', symbolWidth).attr('height', symbolHeight).transition().duration(duration).style('opacity', 1);
      graphLayout.selectAll('.dot.skip text').remove();
      graphLayout.selectAll('.dot.slide path').remove();
      graphLayout.selectAll('.dot.skip').append('text').attr('transform', function (d) {
        return "translate(" + (xScale(d.service_start_at) + xAlignmentOffset + 15) + "," + (yScale(d.metadata.service) + 19) + ")";
      }).attr('width', symbolWidth).attr('height', symbolHeight).text('N').attr('text-anchor', 'middle');
      graphLayout.selectAll('.dot.slide').append('path').attr('class', 'arrow-head').attr('transform', function (d) {
        return "translate(" + (xScale(d.original_start_at) + minorSegmentWidth * 0.22) + "," + (yScale(d.metadata.service) + 16) + ") scale(2)";
      }).attr('d', 'M0,-2L7,-2L7,-7L14,0L7,7L7,2L0,2L0,-2');
    },
    _initXAxis: function _initXAxis(pipeline) {
      var xScale = pipeline.xScale,
          graphWidth = pipeline.graphWidth,
          graphHeight = pipeline.graphHeight,
          minorSegmentWidth = pipeline.minorSegmentWidth,
          graphLayout = pipeline.graphLayout; // draw an axis based on ticks every week

      pipeline.weekAxis = (0, _d3Axis.axisTop)().scale(xScale).ticks(_d3Time.timeMonday, 1).tickSize(0).tickFormat((0, _d3TimeFormat.timeFormat)('%-m/%-e'));
      pipeline.dayOfWeekAxis = (0, _d3Axis.axisTop)().scale(xScale).ticks(_d3Time.timeDay, 1).tickSize(0).tickFormat(function (d) {
        return (0, _d3TimeFormat.timeFormat)('%a')(d).substring(0, 2);
      });
      pipeline.minorGrid = (0, _d3Axis.axisTop)().scale(xScale).ticks(_d3Time.timeDay, 1).tickSize(-graphHeight, 0, 0).tickFormat('');
      pipeline.majorGrid = (0, _d3Axis.axisTop)().scale(xScale).ticks(_d3Time.timeMonday, 1).tickSize(-graphHeight, 0, 0).tickFormat('');
      graphLayout.append('g').attr('class', 'background-rect').append('rect').attr('width', graphWidth).attr('height', graphHeight);
      pipeline.graphLayout = graphLayout;
      var halfwayWidth = minorSegmentWidth * 0.5;
      graphLayout.append('g').attr('class', 'x axis week').attr('transform', "translate(" + halfwayWidth + ", -18)").call(pipeline.weekAxis).call(function (g) {
        return g.select('.domain').remove();
      });
      graphLayout.append('g').attr('class', 'x axis day-of-week').attr('transform', "translate(" + (halfwayWidth - 1) + ", -5)").call(pipeline.dayOfWeekAxis).call(function (g) {
        return g.select('.domain').remove();
      });
      graphLayout.append('g').attr('class', 'x grid minor').call(pipeline.minorGrid).call(function (g) {
        return g.select('.domain').remove();
      });
      graphLayout.append('g').attr('class', 'x grid major').call(pipeline.majorGrid).call(function (g) {
        return g.select('.domain').remove();
      });

      this._initProgress(pipeline);
    },
    _updateXAxis: function _updateXAxis() {
      var svg = this.pipeline.svg;
      svg.selectAll('.x.axis.week').call(this.pipeline.weekAxis);
      svg.selectAll('.x.axis.day-of-week').call(this.pipeline.dayOfWeekAxis);
      svg.selectAll('.x.grid.minor').call(this.pipeline.minorGrid);
      svg.selectAll('.x.grid.major').call(this.pipeline.majorGrid);
    },
    _initYAxis: function _initYAxis(pipeline) {
      var yScale = pipeline.yScale,
          graphWidth = pipeline.graphWidth;
      pipeline.yGrid = (0, _d3Axis.axisRight)().scale(yScale).tickSize(graphWidth - 1).tickFormat('');
      pipeline.yAxisGroup = pipeline.graphLayout.append('g').attr('class', 'y axis');
      pipeline.graphLayout.append('g').attr('class', 'y grid');
    },
    _updateYAxis: function _updateYAxis() {
      var _this$pipeline2 = this.pipeline,
          svg = _this$pipeline2.svg,
          yGrid = _this$pipeline2.yGrid,
          yScale = _this$pipeline2.yScale; // y offset by half the bandwidth + half the inner padding

      var yOffset = yScale.bandwidth() * 0.5 + yScale.paddingInner();
      svg.selectAll('.y.grid').attr('transform', "translate(1, " + (yOffset + 2) + ")") // .attr("transform", `translate(1, ${yScale.bandwidth() / 2})`)
      .call(yGrid);
      svg.selectAll('.y.grid .domain').remove();

      this._updateVisualYAxis(this.pipeline);
    },
    _updateVisualYAxis: function _updateVisualYAxis(pipeline) {
      var _this4 = this;

      var yAxisGroup = pipeline.yAxisGroup,
          yScale = pipeline.yScale;
      var yAxisData = yAxisGroup.selectAll('.y.axis .tick').data(this.activeCampaigns, function (d) {
        return d.api_name;
      });
      yAxisData.exit().remove();
      yAxisData.attr('x', -60).attr('y', function (d) {
        return yScale(d.api_name);
      });
      var tick = yAxisData.enter().append('g');
      var wrap = (0, _d3Textwrap.textwrap)().bounds({
        height: 80,
        width: 150
      });
      var imageWidth = 50,
          imageHeight = 50;
      tick.attr('class', function (d) {
        return "tick visual " + d.api_name;
      }).append('rect').attr('fill', this.debug ? 'rgba(0,0,0,.1)' : 'rgba(255,255,255,1)').attr('width', imageWidth).attr('height', yScale.bandwidth() - 1).attr('x', -60).attr('y', function (d) {
        return yScale(d.api_name);
      });
      tick.append('svg:image').attr('xlink:href', function (d) {
        return _this4.get('assetMap').resolve("assets/images/campaigns/originals/" + d.api_name + ".png");
      }).attr('width', imageWidth).attr('height', imageHeight).attr('x', -60).attr('y', function (d) {
        return yScale(d.api_name);
      });

      if (!this.isMobile) {
        tick.append('text').text(function (d) {
          return d.name.replace(' Collection', '');
        }).attr('width', 70).attr('height', 80).attr('x', -95).attr('y', function (d) {
          return yScale(d.api_name);
        }).call(wrap);
        tick.select('foreignObject').attr('x', -165).attr('y', function (d) {
          return yScale(d.api_name);
        });
      }
    },
    _initProgress: function _initProgress(pipeline) {
      var graphLayout = pipeline.graphLayout,
          xScale = pipeline.xScale,
          graphHeight = pipeline.graphHeight;
      var progressLayout = graphLayout.selectAll('.progress-background').data([new Date()]).enter().append('g').attr('class', 'progress-background');
      var today = moment().startOf('day'),
          tomorrow = moment().startOf('day').add(1, 'days'),
          abbreviatedDayOfWeek = (0, _d3TimeFormat.timeFormat)('%a')(today).substring(0, 2),
          xLeft = xScale(today),
          xRight = xScale(tomorrow),
          width = xRight - xLeft - 1;
      var yOffset = 25;
      progressLayout.append('rect').attr('height', graphHeight + yOffset).attr('width', width).attr('transform', "translate(" + (xLeft + 1) + ", -" + yOffset + ")");
      progressLayout.append('text').text(abbreviatedDayOfWeek).style('text-anchor', 'middle').attr('transform', "translate(" + (xLeft + width * 0.5) + ", -8)");
    },
    debug: _environment.default.environment === 'development'
  });

  _exports.default = _default;
});