define("accio-alert/pods/components/onboarding/location/add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rnYLP9y1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"text-center text-custom\"],[8],[0,\"Places\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"intro-slide-image\"],[8],[0,\"\\n\"],[7,\"img\",true],[10,\"src\",\"/assets/images/place.png\"],[10,\"class\",\"intro-slide-image\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\"],[7,\"h3\",true],[8],[0,\"Add places to get information personalized to locations you care about, like work or home.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-center m-t-20\"],[8],[0,\"\\n\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"skip\"]],[8],[0,\"skip\"],[9],[0,\"\\n\\tor \\n\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-default btn-lg\",\"main.locations.new.home\"]],{\"statements\":[[0,\"Continue\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/components/onboarding/location/add/template.hbs"
    }
  });

  _exports.default = _default;
});