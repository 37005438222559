define("accio-alert/services/authentication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var authenticator = 'authenticator:jwt';

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    authenticate: function authenticate(credentials) {
      return this.get('session').authenticate(authenticator, credentials).catch(function (reason) {
        var _reason$json, _ref2, _reason$errors;

        var _ref = (_reason$json = reason == null ? void 0 : reason.json) != null ? _reason$json : {},
            requires_additional_factor = _ref.requires_additional_factor,
            message = _ref.message;

        if (requires_additional_factor) {
          return {
            requiresSecondFactor: true,
            secondFactorMessage: message
          };
        }

        return {
          hasErrors: true,
          errorMessage: (_ref2 = message != null ? message : reason == null ? void 0 : (_reason$errors = reason.errors) == null ? void 0 : _reason$errors.detail) != null ? _ref2 : reason
        };
      });
    }
  });

  _exports.default = _default;
});