define("accio-alert/pods/components/workflows/components/schedule/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    didValidate: false,
    unit: 'day',
    startDate: null,
    endDate: null,
    isEmailUsernameType: Ember.computed.equal('model.usernameType', 'email'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isEmpty(this.get('startDate'))) {
        this._resetDates();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('selectedCoordinates', {
        latitude: 39.1438387,
        longitude: -77.3481772
      });
      Ember.run.once(this, this._setScheduledEvents);
    },
    actions: {
      nextUnit: function nextUnit() {
        this._manipulateDates(this.get('unit') + "s", 1);
      },
      prevUnit: function prevUnit() {
        this._manipulateDates(this.get('unit') + "s", -1);
      },
      bookAppointment: function bookAppointment(appointment) {
        var context = this.get('model.context');
        this.onResponse(_extends({}, context[this.model.currentStepApiName], {
          testing_site_id: appointment.feature,
          testing_site_description: appointment.description,
          appointment_slot: appointment.service_start_at,
          appointment_slot_start: appointment.service_start_at,
          appointment_slot_end: appointment.service_end_at,
          appointment_slot_expires_at: moment().startOf('minute').add('second', appointment.unconfirmed_lifetime),
          confirmation_code: this._randomString(10, 'A#').toLowerCase()
        }));
      }
    },
    _resetDates: function _resetDates() {
      var date = new Date(); // if we decide later to start on the first date of the campaign, uncomment this
      // const date = now < this.get('campaignStartDate') ? this.get('campaignStartDate') : now;

      var unit = this.get('unit');

      switch (unit) {
        default:
          this.set('startDate', moment(date).startOf(unit));
          this.set('endDate', moment(date).endOf(unit));
          return;
      }
    },
    _setScheduledEvents: function _setScheduledEvents() {
      var _this = this;

      var context = this.get('model.context');
      var filters = (context[this.model.currentStepApiName] || {}).filters;
      var selectedCoordinates = this.get('selectedCoordinates');

      if (Ember.isEmpty(selectedCoordinates)) {
        return;
      }

      var url = this.get('ajax.namespacedUrl') + "/locations/by_campaign/" + (this.model.context.campaign || 'cian_tests') + "/service-schedules";
      this.get('ajax').request(url, {
        data: {
          start_date: moment(this.startDate).toISOString(),
          end_date: moment(this.endDate).toISOString(),
          filters: filters
        }
      }).then(function (data) {
        return _this.set('scheduledEvents', data);
      });
    },
    _randomString: function _randomString(length, chars) {
      var mask = '';
      if (chars.indexOf('A') > -1) mask += 'ABCDEFGHJKLMNPRSTUVWXY';
      if (chars.indexOf('#') > -1) mask += '0123456789';
      var result = '';

      for (var i = length; i > 0; --i) {
        result += mask[Math.floor(Math.random() * mask.length)];
      }

      return result;
    },
    _manipulateDates: function _manipulateDates(unit, num) {
      var newStartDate = moment(this.get('startDate')).add(num, unit).toDate();

      if (newStartDate >= new Date()) {
        this.set('startDate', newStartDate);
        this.set('endDate', moment(this.get('endDate')).add(num, unit).toDate());
        Ember.run.next(this, this._setScheduledEvents);
      }
    }
  });

  _exports.default = _default;
});