define("accio-alert/pods/mobile/update-password/controller", ["exports", "accio-alert/pods/main/update-password/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend();

  _exports.default = _default;
});