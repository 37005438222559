define("accio-alert/pods/mobile/select-features/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    nativeApp: Ember.inject.service(),
    queryParams: ['cancelMode'],
    actions: {
      onSave: function onSave() {
        this.nativeApp.popNavigation();
      }
    }
  });

  _exports.default = _default;
});