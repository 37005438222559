define("accio-alert/mixins/contact-limits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    account: Ember.computed.oneWay('currentAccount.account'),
    user: Ember.computed.oneWay('currentUser.user'),
    maxEmailsPerCustomer: Ember.computed.oneWay('account.accountLimitsMaxEmailsPerCustomer'),
    maxSmsPerCustomer: Ember.computed.oneWay('account.accountLimitsMaxSmsPerCustomer'),
    numSmsContacts: Ember.computed.oneWay('user.smsContactMethods.length'),
    numEmailContacts: Ember.computed.oneWay('user.emailContactMethods.length'),
    hasReachedEmailLimit: Ember.computed('numEmailContacts', 'maxEmailsPerCustomer', function () {
      return this.get('numEmailContacts') >= this.get('maxEmailsPerCustomer');
    }),
    hasReachedSmsLimit: Ember.computed('numSmsContacts', 'maxSmsPerCustomer', function () {
      return this.get('numSmsContacts') >= this.get('maxSmsPerCustomer');
    }),
    hasReachedContactMethodLimit: Ember.computed.and('hasReachedEmailLimit', 'hasReachedSmsLimit')
  });

  _exports.default = _default;
});