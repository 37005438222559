define("accio-alert/pods/public/faq/campaign/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ughVoYVX",
    "block": "{\"symbols\":[\"faq\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"faq-breadcrumb\"]],{\"statements\":[[0,\"\\t\"],[4,\"link-to\",null,[[\"route\"],[\"public.faq.index\"]],{\"statements\":[[0,\"> FAQs\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"h3\",true],[10,\"class\",\"page-title m-t-20 text-center\"],[8],[1,[24,[\"model\",\"name\"]],false],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"frequentlyAskedQuestions\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"row faq\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12\"],[8],[0,\"\\n\\t\\t\"],[7,\"h4\",true],[8],[1,[23,1,[\"question\"]],true],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[1,[23,1,[\"answer\"]],true],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"h5\",true],[10,\"class\",\"text-center\"],[8],[0,\"We're busy adding helpful information to this section. Come back soon.\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/public/faq/campaign/template.hbs"
    }
  });

  _exports.default = _default;
});