define("accio-alert/adapters/workflow", ["exports", "accio-alert/pods/application/adapter", "accio-alert/config/environment"], function (_exports, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    session: Ember.inject.service(),
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      // console.log('workflow.urlForCreateRecord');
      if (this.get('session.isAuthenticated') === false) {
        // console.log(`returning ${config.apiURL}/${config.apiNamespace}/workflows`);
        return _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/workflows/anonymous";
      }

      return this._super(modelName, snapshot);
    }
  });

  _exports.default = _default;
});