define("accio-alert/services/task", ["exports", "accio-alert/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    completeTask: function completeTask(task) {
      var url = _environment.default.apiURL + "/" + _environment.default.apiNamespace + "/tasks/" + task.get('id') + "/process-action";
      return this.get('ajax').post(url, {
        data: {
          workflow_action: 'complete'
        }
      });
    }
  });

  _exports.default = _default;
});