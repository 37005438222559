define("accio-alert/pods/main/locations/edit/select-features/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    queryParams: ['cancelMode'],
    actions: {
      onSave: function onSave() {
        var _this = this;

        this._checkForServiceAnnotations().then(function (hasInterruptions) {
          if (hasInterruptions) {
            _this.transitionToRoute('main.locations.edit.show_schedule', _this.get('model.parentLocation'));
          } else {
            _this.transitionToRoute('main.locations.edit.finish', _this.get('model.parentLocation'));
          }
        });
      }
    },
    _checkForServiceAnnotations: function _checkForServiceAnnotations() {
      var location = this.get('model.parentLocation');
      var url = this.get('ajax.namespacedUrl') + "/locations/" + location.get('id') + "/service-schedules";
      var startDate = moment().startOf('week');
      var endDate = moment().endOf('week');
      var hasServiceAnnotations = false;
      return this.get('ajax').request(url, {
        data: {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          campaign_id: this.get('campaignId')
        }
      }).then(function (data) {
        for (var property in data) {
          if (data.hasOwnProperty(property)) {
            hasServiceAnnotations = hasServiceAnnotations || data[property].schedules.reduce(function (acc, scheduleItem) {
              return acc || scheduleItem.metadata.service_type === 'suspended_service';
            }, false);
          }
        }

        return hasServiceAnnotations;
      });
    }
  });

  _exports.default = _default;
});