define("accio-alert/pods/components/pdsp-tasks/pdsp-task/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    componentName: Ember.computed('model.taskType', function () {
      var taskType = this.get('model.taskType') || 'pdsp-loading';
      return "pdsp-tasks/" + Ember.String.dasherize(taskType).replace(/\./g, '-');
    })
  });

  _exports.default = _default;
});