define("accio-alert/pods/main/vehicles/edit/verify/route", ["exports", "accio-alert/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    nativeApp: Ember.inject.service(),
    model: function model() {
      return this.modelFor('main.vehicles.edit').get('verification');
    },
    afterModel: function afterModel() {
      this.nativeApp.postMessage({
        action: 'permissions.camera.request'
      });
      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});